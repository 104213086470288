import React,{useState,useEffect,useContext} from "react";
import NavigationHeader from "./NavigationHeader";
import GeneralContent from "./GeneralContent";
import StatisticsContent  from "./StatisticsContent";
import ViolationsContent from "./ViolationsContent";
import MetricsContent from "./MetricsContent";
import { CSContext } from "../../App";
import "./navigationtab.css";

const Navigationtab = props =>
{
    const {apiPath} = useContext(CSContext)
    const [navcontent, setnavcontent] = useState("violations");
    const [beekData,setBeekData] = useState(null);

    const deleteAlerts = async () =>
    {
        const payload = {type:"device",deviceid:props.beekid,projectid:props.projectid};
        const response = await fetch (apiPath+"deleteAlerts.php",{
            method:"POST",
            body:JSON.stringify(payload)
        })
        const deleteStatus = await response.json();
        //console.log(deleteStatus);
        if(deleteStatus.status == "ok")
        {
            alert("Se han eliminado todas las alertas");
        }
        else
        {
            alert("Una o mas alertas no han podido ser eliminadas");
        }
    }

    const changeContent = (newContent) =>
    {
        setnavcontent(newContent);
    }

    useEffect(
        async()=>
        {
            const payload ={projectid:props.projectid,beekid:props.beekid};
            //console.log(payload);
            const response = await fetch(apiPath+"getBeekInfo.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const beekInfo = await response.json();
            //console.log(beekInfo);
            setBeekData(beekInfo);      
        },[]
    );
    


    var NavigationContent;
    switch (navcontent) {
        case "general":
            NavigationContent = <GeneralContent beekInfo={beekData}/>;
            break;
        case "statistics":
            NavigationContent = <StatisticsContent beekInfo={beekData} projectid={props.projectid} beekid={props.beekid}/>;
            break
        case "violations":
            NavigationContent = <ViolationsContent projectid={props.projectid} devicetype={"beacon"} deviceid={props.beekid} delete ={deleteAlerts}/>;
            break;
        case "metrics":
            NavigationContent = <MetricsContent projectid={props.projectid} deviceid={props.beekid} devicename ={props.devicename}/>
            break;
        default:
            NavigationContent = "default"
            break;
    }
    return(
        <div className = "nav-container">
            <NavigationHeader change = {changeContent}/>
            <div className = "nav-content-container">
                {NavigationContent}
            </div>
        </div>
    );
}
export default Navigationtab;