import { useEffect,useState } from "react";
import SubTitle from "../../../subTitle/SubTitle";
import ReactTooltip from "react-tooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 *                 <div>
                    <label className = "text-option">Notificar a los siguientes correos:</label>
                    <div><textarea className="styled-input custom-ta" id ="email" value = {emails} onChange={e=>setmails(e.target.value)}></textarea></div>
                </div>
*/
const Vibration = props =>
{
    const [deviceCheck,setDeviceCheck] = useState(true);
    const [emails,setmails] = useState(null);
    const [phones,setPhones] = useState(null);
    const [threshold,setThreshold] = useState(0);
    useEffect(()=>
        {
            props.setBtn(true);
            if(props.policy)
            {
                if(props.policy["scope"]=="GLOBAL"){setDeviceCheck(false)}
                setmails(props.emails);
                setPhones(props.phones);
                setThreshold(props.policy["threshold"]);
            }
        },[]
    );
    return(
        <div>
            <SubTitle section = "Configuración de la Política" size = "small" />
            <div>
                <label className = "text-option">Métrica de vibración</label>
                <select className ="styled-input custom-select" id ="vibrationMetric">
                    <option value = "VELOCITY_RMS">Velocidad RMS</option>
                    <option value = "VELOCITY_PEAK_TO_PEAK">Velocidad Pico Pico</option>
                    <option value = "ACCELARATION_RMS">Aceleración RMS</option>
                    <option value = "ACCELERATION_PEAK_TO_PEAK">Aceleración Pico Pico</option>
                </select>
            </div>
            <div>
                <label className = "text-option">Umbral</label>
                <input type = "number" className = "styled-input" id = "thresholdValue" value={threshold} onChange={e=>setThreshold(e.target.value)}></input>
            </div>
            <div>
                <label className = "text-option">Tiempo</label>
                <select className = "styled-input custom-select" id = "timeWindow">
                    <option value ={600}>10 minutos</option>
                    <option value ={1200}>20 minutos</option>
                    <option value ={1800}>30 minutos</option>
                    <option value ={3600}>60 minutos</option>
                </select>
            </div>
            <div><label className = "text-option">Aplicar política:</label></div>
            <div>
                <label className="text-option">Todos los dispositivos</label>
                <input type="radio" id = "radioGlobal" name = "radioscope" defaultChecked={!deviceCheck}></input>
                <label className="text-option" >Dispositivos especificos</label>
                <input type="radio" id = "radioIndividual" name = "radioscope" defaultChecked={deviceCheck}></input>
            </div>
            <div>
                <label className = "text-option">Notificar a los siguientes números:</label>
                <HelpOutlineIcon 
                        className = "inline-icon"
                        data-tip={"Ingresa números telefónicos con el código de país separados por ',' Ejemplo: 52XXXXXXXX83,52XXXXXXXX90"} 
                />
                <ReactTooltip/>
                <div><textarea className="styled-input custom-ta" id = "sms_whatsapp" defaultValue = {phones} onChange={e=>setPhones(e.target.value)}></textarea></div>
            </div>
        </div>
    );
}
export default Vibration;