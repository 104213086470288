import React,{useState,useEffect,useContext} from "react";
import BeeksTable from "../../tables/BeeksTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../toast/Toast";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../../App";

const ChangeBeekName = props =>
{
    const [beekName,setSelectedBeekName] = useState([]);
    const [beekId,setSelectedBeekId] = useState([]);
    const [inputValue,setInputValue] = useState("");
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const [beekTable, setBeekTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const { apiPath } = useContext(CSContext) 


    const select = (uselessvar,beekid,beekname) =>
    {
        const useless = uselessvar;
        setSelectedBeekId(beekid);
        setSelectedBeekName(beekname);
    }

    const NewName = () =>
    {
        return(
            <div>
                <label className = "text-option">Nuevo nombre del Beacon:</label>
                <div><input type="text" className="styled-input" autoFocus value ={inputValue} onChange={e => setInputValue(e.target.value)}></input></div>
            </div>
        );
    }

    const rename = async ()=>
    {
        const payload ={devicetype:"beacon",
                        devicename:inputValue,
                        projectid:props.projectid,
                        deviceid:beekId
                        }
        const response = await fetch(apiPath+"renameBeek.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === 200)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Nuevo nombre actualizado");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedBeekName([])
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Nuevo nombre NO actualizado");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedBeekName([])
            },3000);
        }
        //console.log(action);
    }


    useEffect(
        //after version 2.0.1 when the subprojects was added the data must be load in this part of the code
        async () =>
        {
            const payload = {projectid:props.projectid,policyid:0};
            const response = await fetch(apiPath+"beeksTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            var result = resultResponse["data"];
            var rows = [];
            result.forEach(element =>{
                var projectRow = {"beekname":element["beekname"],"beekid":element["beekid"]};
                rows.push(projectRow);
            });
            setBeekTable(<BeeksTable data={rows} select={select} userinfo={props.user}/>)
        },[]
    ) 


    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>(beekName.length==0)?props.changeView("options"):setSelectedBeekName([])} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                {(beekName.length!=0)?<label className = "center-title">{beekName}</label>:<label className = "center-title">Cambiar nombre de Beacon</label>}    
            </div>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <p className = "opt-info">
                {(beekName.length==0)?"Selecciona un Beacon para cambiar su nombre.":"Escribe el nuevo nombre para este Beacon."}   
            </p>
            <div>
                {(beekName.length == 0)?beekTable:<NewName />}
            </div>
            <div>
                {(inputValue.length!=0)?<button className="center-btn" onClick={rename}>Cambiar nombre</button>:null}
            </div>
        </div>
    );

}
export default ChangeBeekName;