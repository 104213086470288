import "./toast.css";


const Toast = props =>
{
    return(
        <div className={props.toastClass}>
            {props.toastContent}
        </div>);
}
export default Toast;