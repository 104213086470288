import React,{useState} from "react";
import MultipleBeeksTemplates from "../../tables/MultipleBeeksTemplates";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../toast/Toast";

const DeleteBeekTemplate = props =>
{
    var showBlufis = false;
    if(props.blufi)
    {
        showBlufis = true;
    }
    const [selectedTemplates,setSelectedTemplates] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const update = rows =>
    {
        var templateids = [];
        rows.forEach(element => {
            templateids.push(element["ID de la plantilla"]);
        });
        setSelectedTemplates(templateids);
    }

    const deleteTemplate = async ()=>
    {
        var blufiQuery = false;
        if(showBlufis)
        {
            blufiQuery = true;
        }  
        const payload = {
            projectid:props.projectid,
            templates:selectedTemplates,
            blufi:blufiQuery
        };
        const response = await fetch("/dashboard/dashboard/cs/csback/deleteTemplate.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === 204)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Plantillas eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Plantillas NO eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
        }
        //console.log(action);
    }
    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>props.changeView("options")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Eliminar Plantillas</label>
                   
            </div>
            <p className = "opt-info">Selecciona una o más plantillas para eliminarlas</p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            {(selectedTemplates.length==0)?null:<button className = "center-btn" onClick={deleteTemplate}>Eliminar</button>} 
            <MultipleBeeksTemplates projectid={props.projectid} update={update} blufi={showBlufis}/>
        </div>
    );
}
export default DeleteBeekTemplate;