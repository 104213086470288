import React,{useState,useRef,useContext} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import AlertChart from "../../components/barCharts/AlertChart";
import Monitor from "../../components/alerts/Monitor";
import Toast from "../../components/toast/Toast";

import { CSContext } from "../../App";

import "./alerts.css";

const Alerts = props =>
{
    const tableRef = useRef();
    const [selectedAlert,setSelectedAlert] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const {apiPath} = useContext(CSContext)
    const update = rows =>
    {
        var eventsids = [];
        rows.forEach(element => {
            eventsids.push(element["eventid"]);
        });
        setSelectedAlert(eventsids);
    }

    const eliminate = async () =>
    {
        const payload = {
            projectid:props.projectid,
            alerts:selectedAlert
        };
        const response = await fetch(apiPath+"deleteSpecialAlerts.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        console.log(action);
        if(action.status == "ok")
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Alertas eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedAlert([]);
                tableRef.current.onQueryChange();
            },3000);
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Las alertas no han podido ser eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedAlert([]);
            },3000);
        }
    }
    //console.log(selectedAlert);
    return(
        <div class = "alert-container">
            <SubTitle section = {"Alertas"}/>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <AlertChart projectid={props.projectid}/>
            <SubTitle section = {"Sensores con alertas"} />
            <Monitor projectid = {props.projectid}/>
        </div>
    );
}
export default Alerts;
