import React,{useState,useEffect,useContext} from "react";
import BeeksTable from "../../components/tables/BeeksTable";
import BlufisTable from "../../components/tables/BlufisTable";
import SubTitle from "../../components/subTitle/SubTitle";
import { Skeleton } from "@mui/material";
import './devices.css';
import { CSContext } from "../../App";



const Devices = props =>
{
    const [beekTable, setBeekTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const [blufiTable, setBlufiTable] =useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const {apiPath} = useContext(CSContext)
    useEffect(
        //after version 2.0.1 when the subprojects was added the data must be load in this part of the code
        async () =>
        {
            const payload = {projectid:props.projectid,policyid:0};
            const response = await fetch(apiPath+"beeksTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            var result = resultResponse["data"];
            var rows = [];
            result.forEach(element =>{
                var projectRow = {"beekname":element["beekname"],"beekid":element["beekid"]};
                rows.push(projectRow);
            });
            setBeekTable(<BeeksTable data={rows} select={props.selectDevice} userinfo={props.user}/>)
        },[]
    )    

    useEffect(
        async () =>
        {
            const payload = {projectid:props.projectid,policyid:0};
            const response = await fetch(apiPath+"blufisTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            var result = resultResponse["data"];
            var rows = [];
            result.forEach(element =>{
                var projectRow = {"blufiname":element["blufiname"],"blufiid":element["blufiid"]};
                rows.push(projectRow);
            });
            setBlufiTable(<BlufisTable data={rows} select={props.selectDevice} userinfo={props.user}/>)
        },[]
    )

    return(
        <div className = "devices-container">
            <SubTitle section={"Dispositivos"}/>
            {beekTable}
            {blufiTable}
        </div>
    );
}
export default Devices;