import React from "react";
import './subtitle.css';


const SubTitle = props =>
{
    var sizeTitle;
    if(props.size === "small")
    {
        sizeTitle = "section-name small";
    }
    else
    {
        sizeTitle = "section-name normal";
    }
    
    return(
        <div>
            <label className = {sizeTitle}>
                {props.section}
            </label>
            <hr className = "title-separator"></hr>
        </div>
    );
}
export default SubTitle;