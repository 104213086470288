
import ConfigMenu from "../sidemenu/content/ConfigMenu";
import ReportMenu from "../sidemenu/content/ReportMenu";
import DashboardIcon from '@material-ui/icons/Speed';
import AlertIcon from '@material-ui/icons/NotificationImportant';
import ReportIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SensorsIcon from '@material-ui/icons/OfflineBolt';
import ChartIcon from '@material-ui/icons/Timeline';
import './sidebar.css';

function Sidebar (props)
{
    return(
        <div className = "side-bar">
            <div className = "side-bar-opt" onClick = {()=>props.changePage("dashboard")}>
                <DashboardIcon className = "inline-icon"></DashboardIcon>
                Dashboard
            </div>
            <div className = "side-bar-opt" onClick = {()=>props.changePage("alert")}>
                <AlertIcon className = "inline-icon"></AlertIcon>
                Alertas
            </div>
            <div className = "side-bar-opt" onClick ={()=>props.changePage("graphs")}>
                <ChartIcon className = "inline-icon"></ChartIcon>
                Gráficas
            </div>
            <div className = "side-bar-opt" onClick = {()=>props.changePage("devices")}>
                <SensorsIcon className = "inline-icon"></SensorsIcon>
                Dispositivos
            </div>
            <div className = "side-bar-menu" onClick={()=>props.showMenu(true,<ReportMenu change={props.changePage} show ={props.showMenu} modal={props.modals} projectid={props.projectid}/>)}>
                <ReportIcon className = "inline-icon"></ReportIcon>
                Reportes
            </div>
            <div className = "side-bar-menu last" onClick={()=>props.showMenu(true,<ConfigMenu change={props.changePage} show ={props.showMenu}/>)}>
                <SettingsIcon className = "inline-icon"></SettingsIcon>
                Configurar
            </div>
        </div>
    );
}
export default Sidebar;
