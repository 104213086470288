import React,{useState,useContext} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import MultipleBeeksTable from "../../components/tables/MultipleBeeksTable";
import { CSContext } from "../../App";
import "./reports.css";


const MetricReport = props =>
{
    const [selectedBeeks,setSelectedBeeks] = useState(null);
    const {apiPath} = useContext(CSContext)

    const createMetricReport = async () =>
    {
        alert("Tu reporte está siendo generado.");
        const promTemp = document.getElementById("promTemp").checked;
        const maxTemp = document.getElementById("maxTemp").checked;
        const minTemp = document.getElementById("minTemp").checked;
        const peak = document.getElementById("peak").checked;
        const peakpeak = document.getElementById("peakpeak").checked;
        const rms = document.getElementById("RMS").checked;
        const date1 = document.getElementById("date1").value;
        const date2 = document.getElementById("date2").value;
        const payload = {
            projectid:props.projectid,
            devices:selectedBeeks,
            tempProm:promTemp,
            tempMax:maxTemp,
            tempMin:minTemp,
            peakProm:peak,
            peakpeakProm:peakpeak,
            rmsProm:rms,
            firstDate:date1,
            secondDate:date2
        };
        const response = await fetch(apiPath+"createMetricReport.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const report = await response.json();
        if(report.status == "ok")
        {
            var download = document.createElement("a");
            download.href = apiPath+"downloadReport.php";
            download.style.display = "none";
            document.body.appendChild(download);
            download.click();
            document.body.removeChild(download);
        } 
    }
    const updateSelection = beeks =>
    {
      setSelectedBeeks(beeks);
    }
    return(
        <div className = "reports-container">
            <div className = "report-info-container">
                <div className = "widget-half">
                    <SubTitle section = {"Información del reporte"}/>
                    <div>
                        <input type = "checkbox" id = "promTemp"></input>
                        <label className = "cs-font-label">Temperatura promedio</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "maxTemp"></input>
                        <label className = "cs-font-label">Temperatura máxima</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "minTemp"></input>
                        <label className = "cs-font-label">Temperatura mínima</label>
                    </div>
                    <div>
                        <input type = "checkbox" id="peak"></input>
                        <label className = "cs-font-label">Vibraciones pico promedio</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "peakpeak"></input>
                        <label className = "cs-font-label">Vibraciones pico pico promedio</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "RMS"></input>
                        <label className = "cs-font-label">Vibraciones RMS promedio</label>
                    </div>
                </div>
                <div className = "widget-half">
                    <SubTitle section = {"Crear reporte"}/>
                    <div>
                        <label className = "cs-font-label">Desde la fecha:</label>
                        <input type = "date" id = "date1" name = "date1" className = "cs-dateInput"></input>
                    </div>
                    <div>
                        <label className = "cs-font-label">Hasta la fecha:</label>
                        <input type = "date" id = "date2" name = "date1" className = "cs-dateInput"></input>
                    </div>
                    <div>
                        <label className = "cs-font-label">Dispositivos:</label>
                        <select className = "cs-select-reports">
                            <option>Todos los dispositivos</option>
                            <option disabled>Agrupar por zonas</option>
                        </select>
                    </div>
                    <div className = "export center" onClick={createMetricReport}>Crear reporte</div>
                </div>
            </div>
            <div>
               <MultipleBeeksTable device ={props.device} projectid ={props.projectid} update={updateSelection}/> 
            </div>
        </div>
    );
}
export default MetricReport;