import React,{useState} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import GraphDetails from "../../components/graphs/views/GraphDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Switch from "../../components/switch/Switch";
import CmPeriod from "../../components/selects/CmPeriod ";
import "./details.css";
//<GraphDetails id={props.deviceid} projectid ={props.projectid} view={view} period={period}/>

const Details = props =>
{
    const [view,setView] = useState(false);
    const [period,setPeriod] = useState("-5 hour");
    console.log("details:project:"+props.projectid);
    console.log("details:date:"+props.date);
    return(
        <div className = "details">
            <SubTitle section = {props.devicename} />
            <div>
                Alerta generada el dia: {props.date}
            </div>
            <ArrowBackIcon className ="inline-icon-opt back-opt-btn" onClick={()=>props.changePage("alert")}></ArrowBackIcon>
            <div className = "actions view-action-3">
                <select onChange={e=> setPeriod(e.target.value)} className = "select-time">
                    <option value = "-5 hour">5 horas</option>
                    <option value = "-12 hour">12 horas</option>
                    <option value = "-1 day">1 día</option>
                    <option value = "-7 day">7 días</option>
                    <option value = "-15 day">15 días</option>
                    <option value = "-31 day">1 mes</option>
                </select>
            </div>
            <div className="actions view-action-3">
                <Switch change={setView} text={"Vista completa "}/>
            </div>
            <GraphDetails id={props.deviceid} projectid ={props.projectid} view={view} period={period} date={props.date}/>
        </div>
    );
}
export default Details;