import React from "react";
import Logo from "../../CSLogo.svg";
import UserIcon from '@material-ui/icons/Person';
import ProjectIcon from '@material-ui/icons/BubbleChart';
import InfoIcon from '@material-ui/icons/Info';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import './topbar.css';

function TopbarSiocs (props)
{
    return(
        <div className = "topbar-container">
            <span className = "img-container">
                <img src = {Logo} className = "topbar-img" onClick={()=>props.changecontext("menu")}></img>
            </span>
            <span className = "user-container">
                <UserIcon className = "inline-icon"></UserIcon>{props.userinfo.username}
            </span>
            <span className = "project-name-contanier selectable-option" onClick={()=>props.modals(true,"Proyectos")}>
                <ProjectIcon className = "inline-icon"></ProjectIcon>{props.userinfo.projectname+" - "+props.userinfo.projectid}
            </span>
            <span className = "info-container selectable-option" onClick={()=>props.modals(true,"Info")}>
                <InfoIcon className = "inline-icon"></InfoIcon> Info
            </span>
            <span className = "logout-container selectable-option" onClick={()=>props.logout()}>
                <LogoutIcon className = "inline-icon"></LogoutIcon> Salir
            </span>
        </div>
    );
}
export default TopbarSiocs;
