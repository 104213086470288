import React,{useEffect,useState} from "react";
import SubTitle from "../../subTitle/SubTitle";
import PeakLineChart from "../../lineCharts/PeakLineChart";
import PeakPeakLineChart from "../../lineCharts/PeakPeakLineChart";
import RmsLineChart from "../../lineCharts/RmsLineChart";
import TemperatureLineChart from "../../lineCharts/TemperatureLineChart";

const GraphView = props =>
{
    const x = props.view;
    ////console.log.("aasd",x);
    return(
        <div>
            <div>
                <h3 className="inf-text">Vibracion Pico</h3>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <PeakLineChart projectid ={props.projectid} deviceid ={props.id} axis={"x"} reload ={x}/>
                </div>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <PeakLineChart projectid ={props.projectid} deviceid ={props.id} axis={"y"} reload ={x}/>
                </div>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <PeakLineChart projectid ={props.projectid} deviceid ={props.id} axis={"z"} reload ={x}/>
                </div>
            </div>
            <div>
                <h3 className="inf-text">Vibracion Pico Pico</h3>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <PeakPeakLineChart projectid ={props.projectid} deviceid ={props.id} axis={"x"} reload ={x}/>
                </div>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <PeakPeakLineChart projectid ={props.projectid} deviceid ={props.id} axis={"y"} reload ={x}/>
                </div>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <PeakPeakLineChart projectid ={props.projectid} deviceid ={props.id} axis={"z"} reload ={x}/>
                </div>
            </div>
            <div>
                <h3 className="inf-text">Vibracion RMS</h3>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <RmsLineChart projectid ={props.projectid} deviceid ={props.id} axis={"x"} reload ={x}/>
                </div>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <RmsLineChart projectid ={props.projectid} deviceid ={props.id} axis={"y"} reload ={x}/>
                </div>
                <div className ={(props.view)?"graph-widget-cs-x":"graph-widget-third"}>
                    <RmsLineChart projectid ={props.projectid} deviceid ={props.id} axis={"z"} reload ={x}/>
                </div>
            </div>
            <div>
                <h3 className="inf-text">Temperatura</h3>
                <div className ="graph-widget-cs-x">
                    <TemperatureLineChart projectid ={props.projectid} deviceid ={props.id} reload ={x}/>
                </div>
            </div>
        </div>  
    );
}
export default GraphView;