import { useEffect,useState } from "react";
import SubTitle from "../../../subTitle/SubTitle";
import ReactTooltip from "react-tooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 *                 <div>
                    <label className = "text-option">Notificar a los siguientes correos:</label>
                    <div><textarea className="styled-input custom-ta" id ="email" value = {emails} onChange={e=>setmails(e.target.value)}></textarea></div>
                </div>
*/
const MotionCondition = props =>
{
    const [emails,setmails] = useState(null);
    const [phones,setPhones] = useState(null);
    const [operationSpeeds,setSpeed] = useState(2);
    const [evaluationThreshold,setThreshold] = useState(0.60);
    useEffect(()=>
        {
            props.setBtn(true);
            if(props.policy)
            {
                setPhones(props.phones);
                setmails(props.emails);
                setSpeed(props.policy['conditionModelTemplate']['maxClusters']);
                setThreshold(props.policy['conditionModelTemplate']['windowThreshold']);
            }
        },[]
    );
    return(
        <div>
            <SubTitle section = "Configuración de la Política" size = "small" />
            <div>
                <label className ="text-option">Intervalo de entrenamiento del modelo:</label>
                <select className="styled-input custom-select" id = "modelTrainingInterval">
                    <option value = {300}>5 minutos</option>
                    <option value = {600}>10 minutos</option>
                    <option value = {1800}>30 minutos</option>
                    <option value = {3600}>1 hora</option>
                    <option value = {21600}>6 horas</option>
                    <option value = {43200}>12 horas</option>
                    <option value = {86400}>1 día</option>
                    <option value = {172800}>2 dias</option>
                    <option value = {259200}>3 días</option>
                    <option value = {345600}>4 días</option>
                    <option value = {432000}>5 días</option>
                    <option value = {518400}>6 días</option>
                    <option value = {604800} selected>7 días</option>
                    <option value = {1210000}>2 semanas</option>
                    <option value = {2628000}>1 mes</option>
                </select>
            </div>
            <div>
                <label className = "text-option">Velocidades operativas</label>
                <input type="number" className="styled-input" id ="operatingSpeeds" min = "2" value = {operationSpeeds} onChange={e=>setSpeed(e.target.value)}></input>
            </div>
            <div>
                <label className ="text-option">Características del modelo</label>
                <select className = "styled-input custom-select" id ="modelFeatures">
                    <option value = "ACCELERATION">Aceleración</option>
                    <option value = "VELOCITY">Velocidad</option>
                </select>
            </div>
            <SubTitle section ="Parametros de configuración" size="small"/>
            <div>
                <label className = "text-option">Ventana de evaluación</label>
                <select className = "styled-input custom-select" id = "evaluationWindow">
                    <option value = {300}>5 minutos</option>
                    <option value = {600}>10 minutos</option>
                    <option value = {1800}>30 minutos</option>
                    <option value = {3600}>1 hora</option>
                    <option value = {21600} selected>6 horas</option>
                    <option value = {43200}>12 horas</option>
                    <option value = {86400}>1 día</option>
                </select>
            </div>
            <div>
                <label className = "text-option">Umbral de evaluación</label>
                <input type = "number" className="styled-input" id="evaluationThreshold" min ="0.01" max = "1" step = "0.01" value = {evaluationThreshold} onChange={e=>setThreshold(e.target.value)}></input>
            </div>
            <div>
                <label className = "text-option">Notificar a los siguientes números:</label>
                <HelpOutlineIcon 
                        className = "inline-icon"
                        data-tip={"Ingresa números telefónicos con el código de país separados por ',' Ejemplo: 52XXXXXXXX83,52XXXXXXXX90"} 
                />
                <ReactTooltip/>
                <div><textarea className="styled-input custom-ta" id = "sms_whatsapp" defaultValue = {phones} onChange = {e=>setPhones(e.target.value)}></textarea></div>
            </div>
        </div>
    );
}
export default MotionCondition