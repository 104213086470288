import React,{useState, useContext} from 'react';
import SubTitle from '../../subTitle/SubTitle';
import Toast from '../../toast/Toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CSContext } from '../../../App';
import { css } from '@emotion/react';

const CreateZone = props =>
{

    const {apiPath} = useContext(CSContext)
    const [zoneName,setZoneName] = useState("");
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const create = async () =>
    {
        const zoneDescription = document.getElementById("description").value;
        const payload  = {
            projectid:props.projectid,
            zonename:zoneName,
            description:zoneDescription
        };
        const response = await fetch(apiPath+"createZone.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === "ok")
        {
            
            setToastClass("toast-ok toast-show");
            setToastContent("Zona creada");
            setTimeout(() => {
                setToastClass("toast-ok");
                document.getElementById("description").value = ""; 
                setZoneName("");
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("La zona no pudo ser creada");
            setTimeout(() => {
                setToastClass("toast-ok");
                document.getElementById("description").value = ""; 
                setZoneName("");
            },3000);
        }
        //console.log(action);
    }
    return(
        <div className= "view">
            <div>
                <ArrowBackIcon onClick={()=>props.changeView("options")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Crear nueva zona</label>
            </div>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            {(zoneName.length!=0)?<button className="center-btn-zone" onClick={create}>Crear zona</button>:null}
            <SubTitle section = {"Información de la zona"} size ={"small"}/>
            <label className = "text-option">Nombre de la zona:</label>
            <div><input type="text" className="styled-input" autoFocus defaultValue ={zoneName} onChange={e => setZoneName(e.target.value)}></input></div>
            <label className = "text-option">Descripción de la zona:</label>
            <div><textarea className="styled-input custom-ta" id = "description"></textarea></div>
        </div>
    );
}
export default CreateZone;