import React,{useState,useEffect} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import ConfPoliciesOpt from "../../components/configurations/configurationOptions/ConfPoliciesOpt";
import CreatePolicy from "../../components/configurations/views/CreatePolicy";
import AddBeek from "../../components/configurations/views/AddBeek";
import DeleteBeek from "../../components/configurations/views/DeleteBeek";
import AddBlufi from "../../components/configurations/views/AddBlufi";
import DeleteBlufi from "../../components/configurations/views/DeleteBlufi";
import DeletePolicy from "../../components/configurations/views/DeletePolicy";
import UpdatePolicy from "../../components/configurations/views/UpdatePolicy";
import "./configurations.css";

const Policies = props =>
{
    var content;
    const [view,setView] = useState("options");
    const changeView  = newView =>
    {
        setView(newView);
    }
    //////////console.log(view);
    switch (view) {
        case "options":
            content = <ConfPoliciesOpt changeView = {changeView}/>
            break;
        case "create":
            content = <CreatePolicy projectid={props.projectid} changeView ={changeView}/>
            break;
        case "update":
            content = <UpdatePolicy projectid={props.projectid} changeView ={changeView}/>;
            break;
        case "addbeek":
            content = <AddBeek device = {props.device} projectid ={props.projectid} changeView ={changeView}/>
            break;
        case "deletebeek":
            content = <DeleteBeek device =  {props.device} projectid ={props.projectid} changeView ={changeView}/>
            break;
        case "addblufi":
            content = <AddBlufi device =  {props.device} projectid = {props.projectid} changeView={changeView} />
            break;
        case "deleteblufi":
            content = <DeleteBlufi device = {props.device} projectid = {props.projectid} changeView={changeView} />
            break;
        case "deletepolicy":
            content = <DeletePolicy projectid = {props.projectid} changeView={changeView} />
            break;
        default:
            content = <ConfPoliciesOpt changeView = {changeView}/>;
            break;
    }
    return(
        <div className = "conf-container">
            <SubTitle section ={"Configurar Políticas"}/>
            <div className = "options-container">
                {content}
            </div>
        </div>
    );
}
export default Policies;