import React,{useState} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import ConfBlufisOpt from "../../components/configurations/configurationOptions/ConfBlufisOpt";
import AddBlufi from "../../components/configurations/views/AddBlufi";
import DeleteBlufi from "../../components/configurations/views/DeleteBlufi";
import ChangeBlufiName from "../../components/configurations/views/ChangeBlufiName";
//import DeletePolic from "../../components/configurations/views/DeletePolicy";
import DeleteBeekTemplate from "../../components/configurations/views/DeleteBeekTemplate";
import CreateBlufiTemplate from "../../components/configurations/views/CreateBlufiTemplate";
import "./configurations.css";

const Blufis = props =>
{
    var content;
    const [view,setView] = useState("options");
    const changeView  = newView =>
    {
        setView(newView);
    }
    switch (view) {
        case "options":
            content = <ConfBlufisOpt changeView ={changeView}/>
            break;
        case "changename":
            content = <ChangeBlufiName device = {props.device} projectid={props.projectid} changeView={changeView} />
            break;
        case "addblufi":
            content = <AddBlufi device =  {props.device} projectid ={props.projectid} changeView ={changeView}/>
            break;
        case "deleteblufi":
            content = <DeleteBlufi projectid ={props.projectid} changeView ={changeView}/>
            break;
        case "updatetemplate":
            break;
        case "createtemplate":
            content = <CreateBlufiTemplate projectid = {props.projectid} changeView={changeView}/>
            break;
        case "deletetemplate":
            content = <DeleteBeekTemplate projectid = {props.projectid} changeView ={changeView} blufi={true}/>
            break;
        default:
            content = <ConfBlufisOpt changeView ={changeView}/>
            break;
    }
    return(
        <div className = "conf-container">
            <SubTitle section ={"Configurar Blufis"}/>
            <div className = "options-container">
                {content}
            </div>
        </div>
    );
}
export default Blufis;