
const GraphPeriod = props =>
{
    
    return(
        <div className= "graph-options">
            <select onChange={e=> props.changePeriod(e.target.value)} className = "select-time">
                <option value = "-1 hour">1 hora</option>
                <option value = "-3 hour">3 horas</option>
                <option value = "-12 hour">12 horas</option>
                <option value = "-1 day">1 día</option>
                <option value = "-3 day">3 días</option>
                <option value = "-7 day">7 días</option>
            </select>
        </div>
    );
}
export default GraphPeriod;