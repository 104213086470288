import React,{useState,useEffect} from "react";

const DatesSelect = props =>
{
    if(props.show == true)
    {
        return(
            <span>
                <input type ="date" onChange={e=>props.setDate1(e.target.value)}>
                </input>
                <input type ="date" onChange={e=>props.setDate2(e.target.value)}>
                </input>
            </span>
        );
    }
    else
    {
        return(null)
    }
}
export default DatesSelect;