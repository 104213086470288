import React, {useState,useEffect,useContext} from "react";
import PoliciesTable from "../../tables/PoliciesTable";
import MultipleBeeksTable from "../../tables/MultipleBeeksTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from "@mui/material";
import { CSContext } from "../../../App";
import Toast from "../../toast/Toast";

const DeleteBeek = props =>
{
    const [selectedPolicyId,setSelectedId] = useState([]);
    const [selectedPolicyName,setSelectedName] = useState([]);
    const [selectedBeeks,setSelectedBeeks] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const [beekTable, setBeekTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const [policyTable, setPolicyTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const {apiPath} = useContext(CSContext)

    const setSelected = (policyId,policyName) =>
    {
        setSelectedId(policyId);
        setSelectedName(policyName);
    }

    const update = rows =>
    {
        var beeksids = [];
        rows.forEach(element => {
            beeksids.push(element["beekid"]);
        });
        setSelectedBeeks(beeksids);
    }
    ////console.log(selectedPolicyName);

    const eliminate = async ()=>
    {
        const payload ={
            projectid:props.projectid,
            policyid:selectedPolicyId,
            beeks:selectedBeeks
        };
        const response = await fetch(apiPath+"deleteBeek2policy.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === 204)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Dispositivos eliminados");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedName([]);
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Dispositivos NO eliminados");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedName([]);
            },3000);
        }
        //console.log(action);
    }
    useEffect(
        async()=>
        {
            const payload = {projectid:props.projectid};
            const response = await fetch(apiPath+"policiesTable.php",{
              method:"POST",
              body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            console.log(resultResponse);
            var result = resultResponse["data"];
            setPolicyTable(<PoliciesTable device = {props.device} projectid = {props.projectid} setSelected={setSelected} id={selectedPolicyId} data={result}/>)
        },[]
    )
    useEffect(
        //after version 2.0.1 when the subprojects was added the data must be load in this part of the code
        async () =>
        {
            const payload = {projectid:props.projectid,policyid:0};
            const response = await fetch(apiPath+"beeksTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            var result = resultResponse["data"];
            var rows = [];
            result.forEach(element =>{
                var projectRow = {"beekname":element["beekname"],"beekid":element["beekid"]};
                rows.push(projectRow);
            });
            setBeekTable(<MultipleBeeksTable data={rows} device ={props.device} projectid={props.projectid} update={update}/>)
        },[]
    ) 
    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>(selectedPolicyName.length==0)?props.changeView("options"):setSelectedName([])} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                {(selectedPolicyName.length!=0)?<label className = "center-title">{selectedPolicyName}</label>:<label className = "center-title">Eliminar Beacons de una política</label>}    
            </div>
            <p className = "opt-info">
                {(selectedPolicyName.length==0)?"Selecciona una política para eliminar Beacons de ella.":"Selecciona uno o más Beacons."}   
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <div>
                {(selectedBeeks.length!=0)?<button className="center-btn" onClick={eliminate}>Eliminar Beacons</button>:null}
            </div>            
            {(selectedPolicyName.length>0)?beekTable:policyTable}
        </div>
    );
}
export default DeleteBeek;