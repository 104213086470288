import React,{useState,useEffect,useContext} from "react";
import WarningIcon from '@mui/icons-material/Warning';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { CSContext } from "../../App";
import "./pushnotifications.css";


const PushNotifications = props =>
{
    const {apiPath} = useContext(CSContext)
    const [criticalAlerts,setCritical] = useState(null);
    const [arrayAlert,setArrayAlert] = useState([]);
    const [preventiveAlerts,setPreventive] = useState(null);
    const [preventiveArray,setParray] = useState([]);
    const [asd,setAsd] = useState(1);
    const [countP,setCountP] = useState(1);
    
    
    const xx = (id) =>
    {
        setAsd(asd+1);
        let newAlerts = arrayAlert.filter(item=>item!==id);
        setArrayAlert(newAlerts);
    }

    const deletePrev = (id) =>
    {
        setCountP(countP+1);
        let newAlerts = preventiveArray.filter(item=>item!==id);
        setParray(newAlerts);
    }

    useEffect(
        async ()=>
        {
            var alerts = [];
            const payload = {projectid:props.projectid};
            const response = await fetch(apiPath+"getCriticalAlerts.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const data = await response.json();
            ////console.log(data);
            data.data.forEach(element => {
                alerts.push(element["beekname"]+" "+element["policyname"]);
            });
            setArrayAlert(alerts);
        },[props.reload]
    );

    useEffect(
        async ()=>
        {
            var alerts = [];
            const payload = {projectid:props.projectid};
            const response = await fetch(apiPath+"getPreventiveAlerts.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const data = await response.json();
            //console.log(data);
            data.data.forEach(element => {
                alerts.push(element["beekname"]+" "+element["policyname"]);
            });
            setParray(alerts);
        },[props.reload]
    );
    useEffect(()=>
    {
        //console.log(arrayAlert);
        let notifications = arrayAlert.map((item,i)=>{
            return(
                <div id = {item} className = "notification critical" onClick={e=>xx(e.target.id)}><WarningIcon className = "inline-icon"></WarningIcon>{item}</div>
            );
        });
        setCritical(notifications);
    },[arrayAlert]);

    useEffect(()=>
    {
        ////console.log(arrayAlert);
        let notifications = preventiveArray.map((item,i)=>{
            return(
                <div id = {item} className = "notification preventive" onClick={e=>deletePrev(e.target.id)}><NotificationImportantIcon className = "inline-icon"></NotificationImportantIcon>{item}</div>
            );
        });
        setPreventive(notifications);
    },[preventiveArray]);

    return(
        <div className = "stack-zone">
            {criticalAlerts}
            {preventiveAlerts}
        </div>
    );
}
export default PushNotifications;