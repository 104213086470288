import React,{useState,useEffect, useContext} from "react";
import SubTitle from "../subTitle/SubTitle";
import { CSContext } from "../../App";
import "./navigationtab.css";


const StatisticsContent = props =>
{
    const {apiPath} = useContext(CSContext)
    const beekInfo = props.beekInfo;
    const [beekMotion,setBeekMotion] = useState({
        xRms:'cargando',
        yRms:'cargando',
        zRms:'cargando',
        xPeak:'cargando',
        yPeak:'cargando',
        zPeak:'cargando',
        xPeakToPeak:'cargando',
        yPeakToPeak:'cargando',
        zPeakToPeak:'cargando'
    });
    useEffect(async()=>
    {
        const payload ={projectid:props.projectid,beekid:props.beekid};
        //console.log(payload);
        const response = await fetch(apiPath+"getbeekMotion.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const motionInfo = await response.json();
        console.log("motion")
        console.log(motionInfo)
        console.log(props.beekInfo)
        setBeekMotion(motionInfo)
    },[])
    return(        
        <div className = "nav-content"> 
            <SubTitle section = {"Estadísticas generales"} size = {"small"}/>
            <label className = "cs-font-label">Temperatura actual:</label>
            <p className = "cs-font-label-info">{beekInfo.temp} °C</p>
            <label className = "cs-font-label">Voltaje de la batería</label>
            <p className = "cs-font-label-info">{beekInfo.battery/1000} V</p>
            <SubTitle section= {"Estadísticas de movimiento"} size = {"small"}/>
            <div>
                <div className = "statistics-col">
                    <div>
                        <label className = "cs-font-label">Valor RMS X:</label>
                        <p className = "cs-font-label-info">{beekMotion.xRms} g</p>
                        <label className = "cs-font-label">Valor RMS Y:</label>
                        <p className = "cs-font-label-info">{beekMotion.yRms} g</p>
                        <label className = "cs-font-label">Valor RMS Z:</label>
                        <p className = "cs-font-label-info">{beekMotion.zRms} g</p>
                    </div>
                </div>   
                <div className = "statistics-col">
                    <label className = "cs-font-label">Valor pico X:</label>
                    <p className = "cs-font-label-info">{beekMotion.xPeak} g</p>
                    <label className = "cs-font-label">Valor pico Y:</label>
                    <p className = "cs-font-label-info">{beekMotion.yPeak} g</p>
                    <label className = "cs-font-label">Valor pico Z:</label>
                    <p className = "cs-font-label-info">{beekMotion.zPeak} g</p>                    
                </div>    
                <div className = "statistics-col">
                    <label className = "cs-font-label">Valor pico pico X:</label>
                    <p className = "cs-font-label-info">{beekMotion.xPeakToPeak} g</p>
                    <label className = "cs-font-label">Valor pico pico Y:</label>
                    <p className = "cs-font-label-info">{beekMotion.yPeakToPeak} g</p>
                    <label className = "cs-font-label">Valor pico pico Z:</label>
                    <p className = "cs-font-label-info">{beekMotion.zPeakToPeak} g</p>  
               </div>         
            </div>
        </div>
    )
    /*return(
        <div className = "nav-content"> 
            <SubTitle section = {"Estadísticas generales"} size = {"small"}/>
            <label className = "cs-font-label">Temperatura actual:</label>
            <p className = "cs-font-label-info">{beekInfo.temp} °C</p>
            <label className = "cs-font-label">Voltaje de la batería</label>
            <p className = "cs-font-label-info">{beekInfo.battery/1000} V</p>
            <SubTitle section= {"Estadísticas de movimiento"} size = {"small"}/>
            <div>
                <div className = "statistics-col">
                    <div>
                        <label className = "cs-font-label">Valor RMS X:</label>
                        <p className = "cs-font-label-info">{beekMotion.xRms} g</p>
                        <label className = "cs-font-label">Valor RMS Y:</label>
                        <p className = "cs-font-label-info">{beekMotion.yRms} g</p>
                        <label className = "cs-font-label">Valor RMS Z:</label>
                        <p className = "cs-font-label-info">{beekMotion.zRms} g</p>
                    </div>
                </div>
                <div className = "statistics-col">
                    <label className = "cs-font-label">Valor pico X:</label>
                    <p className = "cs-font-label-info">{beekMotion.xPeak} g</p>
                    <label className = "cs-font-label">Valor pico Y:</label>
                    <p className = "cs-font-label-info">{beekMotion.yPeak} g</p>
                    <label className = "cs-font-label">Valor pico Z:</label>
                    <p className = "cs-font-label-info">{beekMotion.zPeak} g</p>                    
                </div>
                <div className = "statistics-col">
                    <label className = "cs-font-label">Valor pico pico X:</label>
                    <p className = "cs-font-label-info">{beekMotion.xPeakToPeak} g</p>
                    <label className = "cs-font-label">Valor pico pico Y:</label>
                    <p className = "cs-font-label-info">{beekMotion.yPeakToPeak} g</p>
                    <label className = "cs-font-label">Valor pico pico Z:</label>
                    <p className = "cs-font-label-info">{beekMotion.zPeakToPeak} g</p>  
               </div>
            </div>
        </div>
    );*/
}
export default StatisticsContent;
