
const CmPeriod = props =>
{
    
    return(
        <div className= "graph-options">
            <select onChange={e=> props.changePeriod(e.target.value)} className = "select-period">
                <option value = "-5 hour">5 horas</option>
                <option value = "-12 hour">12 horas</option>
                <option value = "-1 day">1 día</option>
                <option value = "-7 day">7 días</option>
                <option value = "-15 day">15 días</option>
                <option value = "-31 day">1 mes</option>
                <option value = "custom">custom</option>
            </select>
        </div>
    );
}
export default CmPeriod;