import React ,{useState,useEffect,useContext} from 'react';
import Toast from "../../toast/Toast";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CSContext } from '../../../App';


const UpdateDefault = props =>
{
    const {apiPath} = useContext(CSContext)
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const [zoneOptions,setZonesOptions] = useState(null);
    useEffect(
        async () =>
        {
            const payload = {projectid:props.projectid}
            const response = await fetch(apiPath+"getZonesTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const zonesData = await response.json();
            ////console.log(zonesData); 
            const zones = zonesData.data;
            let options = zones.map((item,i)=>{
                return(
                    <option value={item["Nombre de la zona"]}>{item["Nombre de la zona"]}</option>
                );
            })
            setZonesOptions(options);
        },[]
    );

    const update = async()=>
    {
        const zoneName = document.getElementById("zones").value;
        const payload = {
            projectid:props.projectid,
            zonename:zoneName
        };
        const response = await fetch(apiPath+"updateDefault.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === "ok")
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Nueva zona predeterminada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options")
            },3000);  
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("La zona no pudo ser actualizada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options")
            },3000);  
        }
        //console.log(action);
    }
    return(
        <div className= "view">
            <div>
                <ArrowBackIcon onClick={()=>props.changeView("options")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Cambiar zona predeterminada</label>
            </div>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <label className = "text-option">Nueva zona predeterminada:</label>
            <select className="styled-input custom-select" id = "zones">
                {zoneOptions}
            </select>
            <div>
                <button className = "center-btn-zone" onClick={update}>Actualizar</button>
            </div>
        </div>
    );
}
export default UpdateDefault;