import React,{useContext} from "react";
import { CSContext } from "../../App";
import "./content.css";

const ReportTemp = props =>
{

    const {apiPath} = useContext(CSContext)
    const getReport = async() =>
    {
        const startdate = document.getElementById("startdateTemp").value;
        const enddate = document.getElementById("enddateTemp").value;
        const payload = {
            projectid:props.projectid,
            startdate:startdate,
            enddate:enddate
        }
        alert("Gerando reporte, esto puede tardar algunos minutos")
        const response = await fetch(apiPath+"createTempReport.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const report = await response.json()
        console.log(report)
        if(report.status === "ok")
        {
            var download = document.createElement("a");
            download.href = apiPath+"downloadReport.php";
            download.style.display = "none";
            document.body.appendChild(download);
            download.click();
            document.body.removeChild(download);
        }
        else
        {
            console.log(report)
            alert("Estamos teniendo problemas para generar el reporte")
        }
    }
    return(
        <div>
            <div>
                <span className="margin-span">
                    <label>Inicio: </label>
                    <input type="date" id = "startdateTemp"></input>
                </span>
                <span className="margin-span">
                    <label>Final: </label>
                    <input type="date" id = "enddateTemp"></input>
                </span>
            </div> 
            <div className="btn" onClick={()=>getReport()}>
                Crear reporte
            </div>
        </div>
    );
}
export default ReportTemp;