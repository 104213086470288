import React from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import Navigationtab from "../../components/navigationTab/NavigationTab";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import "./beacon.css";
//<Navigationtab beekid={props.id} projectid ={props.projectid} devicename={props.name}/>
const Beacon = props =>
{
    //console.log(props.id);
    return(
        <div className = "beacon">
            <div>
                <span className = "back" onClick ={()=>props.changePage("devices")}>
                    <ChevronLeft className = "inline-icon"></ChevronLeft>
                </span>
                <SubTitle section ={props.name}/>
            </div>
            <div>
                <Navigationtab beekid={props.id} projectid ={props.projectid} devicename={props.name}/>
            </div>
        </div>
    );
}
export default Beacon;