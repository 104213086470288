import {useState,useEffect,useContext} from 'react';
import ZoneSelect from '../selects/GraphZone';
import MultipleBeeksTable from '../tables/MultipleBeeksTable';

import { CSContext } from '../../App';

import "./content.css";

const Report = props => 
{
    const [reportType,setReportType] = useState("all");
    const [zone,setZone] = useState(null);
    const [selectedBeeks,setBeeks] = useState([]);
    const {apiPath,setApiPath} = useContext(CSContext)
    
    useEffect(
        async () =>
        {
            if(!zone)
            {
                const payloadZone ={projectid:props.projectid}
                const zoneName = await fetch(apiPath+"getDefaultZone.php",{
                    method:"POST",
                    body:JSON.stringify(payloadZone)
                });
                const zoneData = await zoneName.json();
                setZone(zoneData.name);
            }
        },[zone]
    );

    const update = rows =>
    {
        var beeksids = [];
        rows.forEach(element => {
            beeksids.push(element["beekid"]);
        });
        setBeeks(beeksids);
    }

    const createReport = async () =>
    {
        alert("Tu reporte está siendo generado.");
        const date1 = document.getElementById("date1").value;
        const date2 = document.getElementById("date2").value;
        const payload = {
            firstDate:date1,
            secondDate:date2,
            projectid:props.projectid,
            projectname:props.projectname,
            beeks:selectedBeeks,
            reporttype:reportType,
            zone:zone
        };
        console.log(payload);
        const response = await fetch("/dashboard/cs/csback/createPDFReport.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        /*const response = await fetch("/dashboard/cs/csback/test.php",{
            method:"POST",
            body:JSON.stringify(payload)
        })*/
        const report = await response.json();
        console.log(report);
        if(report.status == "ok")
        {
            var download = document.createElement("a");
            download.href = "/dashboard/cs/csback/downloadpdf.php";
            download.style.display = "none";
            document.body.appendChild(download);
            download.click();
            document.body.removeChild(download);
        }
    }
    return(
        <div>
            <div>   
                <select id = "reporttype" className="cs-report" onChange={e=>setReportType(e.target.value)}>
                    <option value = "all">Todos los dispositivos</option>
                    <option value = "zones">Dispositivos en una zona</option>
                    <option value = "devices">Dispositivos independientes</option>
                </select>
            </div>
            <div>
                <label className = "cs-font-label">Desde la fecha:</label>
                <input type = "date" id = "date1" name = "date1" className = "cs-dateInput"></input>
            </div>
            <div>
                <label className = "cs-font-label">Hasta la fecha:</label>
                <input type = "date" id = "date2" name = "date1" className = "cs-dateInput"></input>
            </div>

            <div>
                {(reportType === "zones")?<label className="cs-font-label">Reporte de la zona:</label>:null}
                {(reportType === "zones")?<ZoneSelect modal={true} projectid ={props.projectid} zone = {setZone}/>:null}
            </div>
            <div>
                {(reportType === "devices")?<MultipleBeeksTable device = {props.device} projectid={props.projectid} update={update}/>:null}
            </div>
            <div className = "export center" onClick={createReport}>Crear reporte</div>
        </div>
    );
}
export default Report;