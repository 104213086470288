import React,{useState} from "react";
import BeeksTemplatesTable from "../../tables/BeeksTemplatesTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../toast/Toast";

const CloneBeekTemplate = props =>
{
    const [selectedTemplate,setSelectedTemplate] = useState([]);
    const [selectedId,setSelectedId] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");

    const update = (name,id) =>
    {
        setSelectedTemplate(name);
        setSelectedId(id);
    }

    const clone = async () =>
    {
        const payload = {
            projectid:props.projectid,
            template:selectedId
        };
        const response = await fetch("/dashboard/cs/csback/cloneTemplate.php",{
            method:"POST",
            body:JSON.stringify(payload)
        }); 
        const action = await response.json();
        if(action.status === 200)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Plantilla clonada");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedTemplate([]);
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Plantilla NO clonada");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedTemplate([]);
            },3000);
        }
        //console.log(action);
    }

    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>(selectedTemplate.length==0)?props.changeView("options"):setSelectedTemplate([])} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Clonar plantilla</label>    
            </div>
            <p className = "opt-info">
                {(selectedTemplate.length==0)?"Selecciona una plantilla para clonarla.":"Clonar la siguiente plantilla:"+selectedTemplate}   
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <div>
                {(selectedTemplate.length ==0)?<BeeksTemplatesTable projectid={props.projectid} update = {update}/>:<button className="center-btn" onClick={clone}>Clonar plantilla</button>}
            </div>
        </div>
    );
}
export default CloneBeekTemplate;