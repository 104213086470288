
import "./switch.css";
const Switch = props =>
{
    if(props.device != "phone")
    {
        return(
            <div>
                {props.text}
                <label class="switch">
                    <input type="checkbox" onChange={e=>props.change(e.target.checked)}></input>
                    <span class="slider round"></span>
                </label>
            </div>
        );
    }
    else
    {
        return(null);
    }
    
}
export default Switch;


