import React, {useState} from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import Modal from "../../components/modal/Modal";
import Devices from "../devices/Devices";
import Dashboard from "../dashboard/Dashboard";
import Beacon from "../beacon/Beacon";
import Blufi from "../blufi/Blufi";
import Monitor from "../../components/modalcontent/Monitor";
import Alerts from "../alerts/Alerts";
import Projects from "../../components/modalcontent/Projects";
import AlertsReport from "../reports/AlertsReport";
import Info from "../../components/modalcontent/Info";
import MetricReport from "../reports/MetricReport";
import Report from "../../components/modalcontent/Report";
import Beeks from "../configurations/Beeks";
import Blufis from "../configurations/Blufis";
import Polices from "../configurations/Policies";
import Zones from "../configurations/Zones";
import Graphs from "../graphs/Graphs";
import Details from "../details/Details";
import Mobilemenu from "../../components/mobileMenu/MobileMenu";
import MobileOptions from "../../components/mobileMenu/MobileOptions";
import PushNotifications from "../../components/pushNotifications/PushNotifications";
import SideMenu from "../../components/sidemenu/SideMenu";
import ReportMov from "../../components/modalcontent/ReportMov";
import ReportTemp from "../../components/modalcontent/ReportTemp";



import './celarsens.css';

function Celarsens (props)
{
    const key = props.page;
    const change = props.changepage;
    const projectId = props.userinfo.projectid;
    const projectName = props.userinfo.projectname;
    const vModule = props.userinfo.vmodule;
    const sModule = props.userinfo.smodule;

    const [modal,setModal] = useState(false);
    const [modalContent,setModalContent] = useState(null);
    const [modalTitle,setModalTitle] = useState(null);
    const [beekId,setBeekId] = useState(null);
    const [blufiId,setBlufiId] = useState(null);
    const [beekName,setBeekName] = useState(null);
    const [blufiName,setBlufiName] = useState(null);
    const [date,setDate] = useState(null);
    const [showContent,show] = useState(true);
    const [sideMenushow,showMenu] = useState(false);
    const [sideMenucontent,setMenuContent] = useState(null);
    
    const showSideMenu = (show,menuOptions) =>
    {
        setMenuContent(menuOptions);
        showMenu(show);
    }

    const showModal = (show,content) =>
    {
      //setModalContent(content);
      switch (content) {
        case "Monitoreo":
            setModalContent(<Monitor projectid = {projectId}/>);
            setModalTitle("Dispositivos con alertas activas");
          break;
        case "Proyectos":
            setModalContent(<Projects user={props.userinfo} change={props.changeproject}/>);
            setModalTitle("Proyectos disponibles");
          break;
        case "Report":
          setModalContent(<Report device = {props.device} projectid = {projectId} projectname = {projectName}/>);
          setModalTitle("Crear reporte ejecutivo");
          break
        case "Info":
          setModalContent(<Info projectid ={projectId}/>);
          setModalTitle("Información");
          break;
        case "Reptemp":
          setModalContent(<ReportTemp projectid={projectId}/>)
          setModalTitle("Reporte Temperatura")
          break;
        case "Repmov":
          setModalContent(<ReportMov projectid={projectId}/>)
          setModalTitle("Reporte Movimiento")
          break;
        default:
          break;
      }
      setModal(show);
    }
    const selectDevice = (deviceType, id, name) =>
    {
      if(deviceType === "beacon")
      {
        setBeekId(id);
        setBeekName(name);
        change("beacon");
      }
      else
      {
        setBlufiId(id);
        setBlufiName(name);
        change("blufi");
      }
    }

    const detailAlert = (deviceId,deviceName,date) =>
    {
      setBeekId(deviceId);
      setBeekName(deviceName);
      setDate(date);
      change("details");
    }

    var content;
    switch (key) {
      case "dashboard":
        content = <Dashboard device ={props.device} projectid = {projectId} reload = {props.reload} vmodule={vModule} smodule={sModule}/>
        break;
      case "details":
        content = <Details projectid = {projectId} deviceid={beekId} devicename={beekName} date={date} changePage={change}/>
        break;
      case "alert":
        content = <Alerts projectid = {projectId} showdetail = {detailAlert}/>
        break;
      case "devices":
        content = <Devices device = {props.device} projectid ={projectId} selectDevice={selectDevice} />;
        break;
      case "metrics":
        content = <MetricReport device  = {props.device} projectid ={projectId}/>;
        break;
      case "alerts":
        content = <AlertsReport device  = {props.device} projectid ={projectId}/>;
        break;
      case "graphs":
        content = <Graphs device ={props.device} projectid = {projectId} user={props.userinfo}/>;
        break;
      case "beeks":
        content = <Beeks device = {props.device} projectid ={projectId}/>;
        break;
      case "blufis":
        content = <Blufis device = {props.device} projectid ={projectId}/>;
        break;
      case "policies":
        content = <Polices device = {props.device} projectid ={projectId}/>;
        break;
      case "zones":
        content = <Zones device = {props.device} projectid ={projectId}/>
        break;
      case "beacon":
        content = <Beacon id={beekId} name ={beekName} changePage ={change} projectid={projectId}/>;
        break;
      case "blufi":
        content = <Blufi id={blufiId} name ={blufiName} changePage ={change} projectid={projectId}/>;
        break;
      default:
        break;
    }
    return(
      <div className = "cs">
        <PushNotifications projectid={projectId} reload = {props.reload}/>
        <Modal show={modal} content ={modalContent} autoclose = {showModal} title={modalTitle} />
        <SideMenu show = {showSideMenu} showmenu = {sideMenushow} content={sideMenucontent}/>
        <div className="mobile-menu-container">
          <Mobilemenu modals={showModal} userinfo={props.userinfo} showmenu={show} />
        </div>
        <div className = "topbar-container">
          <Topbar modals={showModal} logout={props.logout} userinfo ={props.userinfo} changecontext={props.changecontext}/>
        </div>
        <div className = "container container-white">
          <div className = "sidebar-container">
            <Sidebar changePage = {change} modals={showModal} showMenu = {showSideMenu} projectid={props.userinfo.projectid}/>
          </div>
          <div className = {(showContent)?"content-container fade-in":"content-hidden"}>
            {content}    
          </div>
          <div className = {(showContent)?"content-hidden":"content-container fade-in"}>
            <MobileOptions logout={props.logout} changePage = {change} showmenu={show} showed={showContent} modals={showModal} projectid={props.userinfo.projectid}/>
          </div>
        </div>
      </div>  
    );
}
export default Celarsens;