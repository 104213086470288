import React from "react";
import VibrationMonitor from "./VibrationMonitor";
import "./dashboard.css";


const Dashboard = props =>
{
    return(
        <div>
            <VibrationMonitor projectid ={props.projectid} reload ={props.reload} device = {props.device}/>
        </div>
    );
    
    /*const [series,setSeries] = useState([]);
    console.log(series)
    return(
        <div className = "dashboard">
            <SubTitle section = {"Movimiento"} />
            <div className = "graph-container">
                <LineChart projectid ={props.projectid} reload ={props.reload} device = {props.device} series={series} setSeries={setSeries}/>
            </div>    
            <SubTitle section = {"Temperaturas"} />
            <div className = "graph-container">
                <TemperatureChart projectid={props.projectid} reload ={props.reload}/>
            </div>     
        </div>
    );*/

}
export default Dashboard;