import TemperatureIcon from '@mui/icons-material/Thermostat';
import AmbientTemperatureIcon from '@mui/icons-material/ThermostatAuto';
import SpeedIcon from '@mui/icons-material/Sensors';
import VibrationIcon from '@mui/icons-material/Vibration';
import BatteryIcon from '@mui/icons-material/BatteryAlert';
import MotionIcon from '@mui/icons-material/Autorenew';
import PresenceIcon from '@mui/icons-material/VisibilityOff';
import ReactTooltip from 'react-tooltip';
import "./detail.css";

const Detail = props =>
{
    return(
        <div>
            <details>
                <summary className='cs-detail selectable'>
                    {props.beekname}
                    {(props.alerts["TEMPERATURE"])?<TemperatureIcon className = "inline-icon" data-tip={"alerta de temperatura"}></TemperatureIcon>:null}
                    {(props.alerts["MOTION"])?<MotionIcon className="inline-icon" data-tip={"alerta de movimiento"}></MotionIcon>:null}
                    {(props.alerts["MOTION_CONDITION"])?<SpeedIcon className = "inline-icon" data-tip={"alerta de aceleración"}></SpeedIcon>:null}
                    {(props.alerts["BATTERY"])?<BatteryIcon className = "inline-icon" data-tip={"alerta de batería"}></BatteryIcon>:null}
                    {(props.alerts["AMBIENT_TEMPERATURE"])?<AmbientTemperatureIcon className="inline-icon" data-tip={"alerta de temperatura ambiente"}></AmbientTemperatureIcon>:null}
                    {(props.alerts["PRESENCE"])?<PresenceIcon className = "inline-icon" data-tip={"alerta de presencia"}></PresenceIcon>:null}
                    {(props.alerts["VIBRATION_THRESHOLD"])?<VibrationIcon className = "inline-icon" data-tip={"alerta de vibración"}></VibrationIcon>:null}          
                </summary>
                <div className='cs-detail'>
                    {props.policies.map(item=><div>{item}</div>)}
                </div>
            </details>
            <ReactTooltip />
        </div>
    );
}
export default Detail;