import React,{useEffect,useState,useContext} from "react";
import SubTitle from "../subTitle/SubTitle";
import DeviceViolation from "../tables/DeviceViolation";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../App";
import "./navigationtab.css";

const ViolationsContent = props =>
{
    const {apiPath} = useContext(CSContext)
    const [violationContent, setViolationContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />)
    useEffect(async () =>
    {
        const payload={projectid:props.projectid, devicetype:props.devicetype, deviceid:props.deviceid};
        const response = await fetch(apiPath+"deviceAlert.php",{
            method:"POST",
            body:JSON.stringify(payload)
        })
        var result = await response.json();
        console.log(result)
        setViolationContent(<DeviceViolation projectid={props.projectid} devicetype={props.devicetype} deviceid={props.deviceid} data ={result}/>)
    },[])

    return(
        <div className = "nav-content">
            <div className = "delete-alerts" onClick={()=>props.delete()}>
                Eliminar Alertas
            </div>
            <div>
                {violationContent}                
            </div>
        </div>
    );
}
export default ViolationsContent;