import React,{useEffect,useState} from "react";
import GraphPeriod from "../selects/GraphPeriod";
import GraphZone from "../selects/GraphZone";
import OrderBy from "../selects/OrderBy";
import GenericColumnChart from "../graphs/GenericColumnChart";
import MotorSelect from "../selects/MotorSelect";
import CustomLineChart from "../graphs/CustomLineChart";
import { Skeleton } from "@mui/material";
import { PartyMode, PinDropRounded } from "@material-ui/icons";

const LineChartD = props =>
{
    const [content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={200} />);
    const [firstPriority,setFirstPriority] = useState(1);
    const [period,setPeriod] = useState("-1 hour");
    const [zone,setZone] = useState(null);
    const [gruoping,setGruoping] = useState("priority");
    const [reloadchart,setReload] = useState(false);
    const [priorityBlock,setPriorityBlock] = useState("1");
    const [axis,setAxis] = useState("rms");

    
    const movePeriod = (newperiod) =>
    {
        setPeriod(newperiod);
        setContent(<Skeleton variant="rectangular" width={"99%"} height={200} />);
    }

    //console.log(props.series);

    // useEffect(
    //     async () =>
    //     {
    //         if(!zone)
    //         {
    //             const payloadZone ={projectid:props.projectid}
    //             const zoneName = await fetch("/cs/csback/getDefaultZone.php",{
    //                 method:"POST",
    //                 body:JSON.stringify(payloadZone)
    //             });
    //             const zoneData = await zoneName.json();
    //             setZone("none");
    //         }
    //     },[]
    // );
    useEffect( 
        async () =>
        {
            let data;
            let selectedZone = (gruoping==="priority")?"none":zone;
            setContent(<Skeleton variant="rectangular" width={"99%"} height={200} />);
            let graphTitle = "Movimiento 1 hora";
            let fileType = "1h";
            switch (period) {
                case "-1 hour":
                    graphTitle = "Movimiento en 1 hora";
                    fileType = "1h";
                    break;
                case "-3 hour":
                    graphTitle = "Movimiento en 3 horas";
                    fileType = "3h";
                    break;
                case "-12 hour":
                    graphTitle = "Movimiento en 12 horas";
                    fileType = "12h";
                    break;
                case "-1 day":
                    graphTitle = "Movimiento en 1 día";
                    fileType = "1d";
                    break;
                case "-3 day":
                    graphTitle = "Movimiento en 3 días";
                    fileType = "3d";
                    break;
                case "-7 day":
                    graphTitle = "Movimiento en 7 días";
                    fileType = "7d";
                    break;
            }

            const basePath = "https://celarsens-storage.s3.us-west-1.amazonaws.com/";
            const order = (gruoping==="priority")?"/lineChart.json":"/zonelineChart.json"
            
            //const payload = {"path":props.projectid+"/lineChart.json"}
            //const response = await fetch("/cs/csback/getFileData.php",{
            //    method:"POST",
            //    body:JSON.stringify(payload)
            //})
            //const basePath = "/charts/"+props.projectid+"/lineChart.json";

            //const response = await fetch(basePath)
            const response = await  fetch(basePath+props.projectid+order);
            const chartData = await response.json();
            //console.log(chartData)
            //console.log(props.series)
            if(gruoping==="priority")
            {
                //const blocks = Object.keys(chartData);
                //setMaxPBlock(parseInt(blocks.pop()));
                data = chartData[fileType][axis];
            }
            else
            {
                data = chartData[fileType][axis][zone];
            }

            const options = {
                title: graphTitle,
                titleTextStyle: {
                    color: "#061528",    
                    fontSize: 13 
                  },
                backgroundColor:'#f8f8ff',
                hAxis:{textStyle:{color:'#f8f8ff'}},
                vAxis: {
                    format:'##.## g',
                    gridlines: {
                      color: 'none'
                    },
                    textStyle:{color:"#061528"}
                }
            };
            //console.log(chartData[fileType][axis])
            //<GraphZone projectid = {props.projectid} able={gruoping} zone={setZone} setload={setReload} reload={reloadchart}/>
            //<OrderBy changeOrder ={setGruoping} setLines={props.setSeries} zones ={setZone} projectid={props.projectid} setload={setReload} reload={reloadchart}/>
            setContent(<CustomLineChart data={data} options = {options} cols= {props.series}/>);
        },[priorityBlock,period,props.reload,zone,gruoping,props.series,reloadchart]
    );
    //console.log(props.reload)
    useEffect(()=>{},[reloadchart]);
    return(
        <div>
            <div className = "graph-widget-cs-line">
                <div>
                    <GraphPeriod changePeriod ={movePeriod}/>
                    <MotorSelect projectid = {props.projectid} setLines={props.setSeries} groupby={gruoping} zone={zone}/>
                </div>
                {content}
            </div>
        </div>
    );
}
export default LineChartD;