
import { useState,useEffect } from "react";
import Chart from "react-google-charts";
import "./icons.css";
import "./graphs.css";

const CustomLineChart = props =>
{
    const [columns,setColumns] = useState([])
    //console.log(props.cols)
    useEffect(
        ()=>
        {
            setColumns(props.cols);
            //console.log("cols",props.cols)
        },[props.cols]
    );
    return(
        <div>
            <Chart 
                chartType="LineChart"
                data = {props.data}
                options = {props.options}
                chartWrapperParams={{ view: { columns: columns} }}
            />
        </div>
    );
}
export default CustomLineChart;