import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';

const ConfPoliciesOpt = props =>
{
    return(
        <div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("create")}>
                    <NotificationsActiveIcon className = "inline-icon-opt"></NotificationsActiveIcon>
                    Crear una nueva política.
                </div>
                <p className = "opt-info">
                    Crea una nueva politica para generar alertas cuando los dispositivos infringan ciertos parametros.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("update")}>
                    <UpdateIcon className = "inline-icon-opt"></UpdateIcon>
                    Actualizar política.
                </div>
                <p className = "opt-info">
                    Selecciona una política existente y modifica sus parametros para modificarla.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("addbeek")}>
                    <AddCircleIcon className = "inline-icon-opt"></AddCircleIcon>
                    Agregar Beacons a una política.
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para agregarlos a una politica
                    y recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("deletebeek")}>
                    <RemoveCircleIcon className = "inline-icon-opt"></RemoveCircleIcon>
                    Eliminar Beacons de una política
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para eliminarlos de una politica y
                    dejar de recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("addblufi")}>
                    <AddCircleIcon className = "inline-icon-opt"></AddCircleIcon>
                    Agregar Blufis a una política.
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para agregarlos a una politica
                    y recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={() => props.changeView("deleteblufi")}>
                    <RemoveCircleIcon className = "inline-icon-opt"></RemoveCircleIcon>
                    Eliminar Blufis de una política
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para eliminarlos de una politica y
                    dejar de recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick = {()=> props.changeView("deletepolicy")}>
                    <DeleteIcon className ="inline-icon-opt"></DeleteIcon>
                    Eliminar politica.
                </div>
                <p className = "opt-info">
                    Selecciona una o más politicas para eliminarlas del sistema.
                </p>
            </div>
        </div>
    );
}
export default ConfPoliciesOpt;