
import SensorsIcon from '@mui/icons-material/Sensors';
import BluetoothSearchingIcon from '@mui/icons-material/BluetoothSearching';
import GavelIcon from '@mui/icons-material/Gavel';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

const ConfigMenu = props =>
{
    const changePage = (page) =>
    {
        props.show(false,null);
        props.change(page);
    }
    return(
        <div className="container-options">
            <div className="menu-option" onClick={()=>changePage("beeks")}>
                <SensorsIcon className = "inline-icon"></SensorsIcon>
                Beacons
            </div>
            <div className="menu-option" onClick={()=>changePage("blufis")}>
                <BluetoothSearchingIcon className='inline-icon'></BluetoothSearchingIcon>
                Blufis
            </div>
            <div className="menu-option" onClick={()=>changePage("policies")}>
                <GavelIcon className = "inline-icon"></GavelIcon>
                Políticas
            </div>
            <div className="menu-option" onClick={()=>changePage("zones")}>
                <WorkspacesIcon className='inline-icon'></WorkspacesIcon>
                Zonas
            </div>
        </div>
    );
}
export default ConfigMenu;