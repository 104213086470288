
import React,{useState} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import MultipleBeeksTable from "../../components/tables/MultipleBeeksTable";
import "./reports.css";
const AlertsReport  = props =>
{
    const [selectedBeeks,setSelectedBeeks] = useState(null);
    const createAlertReport = async () =>
    {
        alert("Tu reporte está siendo generado.");
        const battery = document.getElementById("battery").checked;
        const temp = document.getElementById("temp").checked;
        const motion = document.getElementById("motion").checked;
        const motion_condition = document.getElementById("motion_condition").checked;
        const vibration = document.getElementById("vibration").checked;
        const presence = document.getElementById("presence").checked;
        const date1 = document.getElementById("date1").value;
        const date2 = document.getElementById("date2").value;
        const payload = {
            projectid:props.projectid,
            devices:selectedBeeks,
            battery:battery,
            temp:temp,
            motion:motion,
            motion_condition:motion_condition,
            vibration:vibration,
            presence:presence,
            firstDate:date1,
            secondDate:date2
        };
        const response = await fetch("/dashboard/cs/csback/createAlertReport.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const report = await response.json();
        if(report.status == "ok")
        {
            var download = document.createElement("a");
            download.href = "/dashboard/cs/csback/downloadReport.php";
            download.style.display = "none";
            document.body.appendChild(download);
            download.click();
            document.body.removeChild(download);
        } 
    }
    const updateSelection = beeks =>
    {
      setSelectedBeeks(beeks);
    }
    return(
        <div className = "reports-container">
            <div className = "report-info-container">
                <div className = "widget-half">
                    <SubTitle section = {"Información del reporte"}/>
                    <div>
                        <input type = "checkbox" id = "battery"></input>
                        <label className = "cs-font-label">Alertas de batería</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "temp"></input>
                        <label className = "cs-font-label">Alertas de temperatura</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "presence"></input>
                        <label className = "cs-font-label">Alertas de presencia</label>
                    </div>
                    <div>
                        <input type = "checkbox" id="motion_condition"></input>
                        <label className = "cs-font-label">Alertas de velocidad/aceleracipon</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "motion"></input>
                        <label className = "cs-font-label">Alertas de movimiento</label>
                    </div>
                    <div>
                        <input type = "checkbox" id = "vibration"></input>
                        <label className = "cs-font-label">Alertas de vibración</label>
                    </div>
                </div>
                <div className = "widget-half">
                    <SubTitle section = {"Crear reporte"}/>
                    <div>
                        <label className = "cs-font-label">Desde la fecha:</label>
                        <input type = "date" id = "date1" name = "date1" className = "cs-dateInput"></input>
                    </div>
                    <div>
                        <label className = "cs-font-label">Hasta la fecha:</label>
                        <input type = "date" id = "date2" name = "date1" className = "cs-dateInput"></input>
                    </div>
                    <div>
                        <label className = "cs-font-label">Dispositivos:</label>
                        <select className = "cs-select-reports">
                            <option>Todos los dispositivos</option>
                            <option disabled>Agrupar por zonas</option>
                        </select>
                    </div>
                    <div className = "export center" onClick ={createAlertReport}>Crear reporte</div>
                </div>
            </div>
            <div>
               <MultipleBeeksTable device = {props.device} projectid ={props.projectid} update={updateSelection}/> 
            </div>
        </div>
    );
}
export default AlertsReport;