import React,{useState, useContext} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import ConfZonesOpt from "../../components/configurations/configurationOptions/ConfZonesOpt";
import CreateZone from "../../components/configurations/views/CreateZone";
import UpdateZone from "../../components/configurations/views/UpdateZone";
import UpdateDefault from "../../components/configurations/views/UpdateDefault";
import AddBeek2Zone from "../../components/configurations/views/AddBeek2Zone";
import DeleteZone from "../../components/configurations/views/DeleteZone";

import "./configurations.css";
import { CSContext } from "../../App";


const Zones = props =>
{
    var content;
    const [view,setView] = useState("options");
    const {apiPath} = useContext(CSContext)
    const changeView  = newView =>
    {
        setView(newView);
    }
    switch (view) {
        case "options":
            content = <ConfZonesOpt changeView={changeView}/>
            break;
        case "create":
            content = <CreateZone projectid = {props.projectid} changeView={changeView}/>
            break;
        case "update":
            content = <UpdateZone projectid = {props.projectid} changeView={changeView}/>
            break;
        case "updatedefault":
            content = <UpdateDefault projectid = {props.projectid} changeView={changeView}/>
            break;
        case "addbeeks":
            content = <AddBeek2Zone device = {props.device} projectid={props.projectid} changeView={changeView}/>;
            break;
        case "delete":
            content = <DeleteZone device = {props.device} projectid={props.projectid} changeView={changeView}/>;
            break;
        default:
            content = <ConfZonesOpt changeView={changeView}/>
            break;
    }
    return(
        <div className = "conf-container">
            <SubTitle section ={"Configurar Zonas"}/>
            <div className = "options-container">
                {content}
            </div>
        </div>
    );
}
export default Zones;