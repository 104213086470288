import React,{useState} from 'react'
import SubTitle from "../../components/subTitle/SubTitle";
import TemperatureChart from "../../components/barCharts/TemperatureChart";
import LineChart from "../../components/lineCharts/LineChartD";
const VibrationMonitor = props =>
{
    const [series,setSeries] = useState([]);
    //console.log(series)
    return(
        <div className = "dashboard">
            <SubTitle section = {"Movimiento"} />
            <div className = "graph-container">
                <LineChart projectid ={props.projectid} reload ={props.reload} device = {props.device} series={series} setSeries={setSeries}/>
            </div>    
            <SubTitle section = {"Temperaturas"} />
            <div className = "graph-container">
                <TemperatureChart projectid={props.projectid} reload ={props.reload}/>
            </div>     
        </div>
    );
}
export default VibrationMonitor;