import React from "react";
import SubTitle from "../subTitle/SubTitle";

const GeneralContent = props =>
{
    const blufiInfo = JSON.parse(props.blufiinfo);
    //console.log(beekInfo.name);
    return(
        <div className = "nav-content">
            <SubTitle section={"Información general del Blufi"} size={"small"}/>
            <div>
                <label className = "cs-font-label">Nombre del dispositivo:</label>
                <p className = "cs-font-label-info">{blufiInfo.name}</p>
            </div>
            <div>
                <label className = "cs-font-label">ID del dispositivo:</label>
                <p className = "cs-font-label-info">{blufiInfo.deviceId}</p>
            </div>
            <div>
                <label className = "cs-font-label">Estado del dispositivo:</label>
                <p className = "cs-font-label-info">{blufiInfo.status}</p>
            </div>
            <SubTitle section ={"Configuraciones generales"} size={"small"} />
            <div>
                <label className = "cs-font-label">Tipo de dispositivo:</label>
                <p className = "cs-font-label-info">{blufiInfo.deviceType.name}</p>
            </div>
            <div>
                <label className = "cs-font-label">Nombre de la plantilla seleccionada:</label>
                <p className = "cs-font-label-info">{blufiInfo.blufiTemplateName}</p>
            </div>
            <div>
                <label className = "cs-font-label">ID de la plantilla seleccionada:</label>
                <p className = "cs-font-label-info">{blufiInfo.blufiTemplateId}</p>
            </div>
            <SubTitle section ={"Configuraciones de red"} size ={"small"} />
            <div>
                <label className = "cs-font-label">Nombre de la red:</label>
                <p className = "cs-font-label-info">{blufiInfo.wifiSsid}</p>
            </div>
            <div>
                <label className = "cs-font-label">Tipo de seguridad de la red:</label>
                <p className = "cs-font-label-info">{blufiInfo.wifiTemplateSecurityType}</p>
            </div>
            <div>
                <label className = "cs-font-label">Dirección IP del dispositivo:</label>
                <p className = "cs-font-label-info">{blufiInfo.ipAddress}</p>
            </div>
            <div>
                <label className = "cs-font-label">Dirección IP de la puerta de enlace:</label>
                <p className= "cs-font-label-info">{blufiInfo.gwAddress}</p>
            </div>
        </div>
    );
}
export default GeneralContent;