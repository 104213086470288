import React,{useEffect,useState} from "react";
import SubTitle from "../../../subTitle/SubTitle";
import ReactTooltip from "react-tooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


/**
 *                 <div>
                    <label className = "text-option">Notificar a los siguientes correos:</label>
                    <div><textarea className="styled-input custom-ta" id ="email" value = {emails} onChange={e=>setmails(e.target.value)}></textarea></div>
                </div>
*/
const Battery = props =>
{
    const [beaconsCheck,setBeaconCheck] = useState(true);
    const [deviceCheck,setDeviceCheck] = useState(true);
    const [emails,setmails] = useState(null);
    const [phones,setPhones] = useState(null);
    useEffect( () =>
    {
        if(props.policy)
        {
            props.setBtn(true);
            if(props.policy["namespace"] =="BLUFI"){setBeaconCheck(false)}
            if(props.policy["scope"]=="GLOBAL"){setDeviceCheck(false)}
            setmails(props.emails);
            setPhones(props.phones);
        }
    },[]
    );
    return(
        <div>
            <SubTitle section = {"Configuración de la política"} size = {"small"}/>
            <div>
                <div>
                    <label className = "text-option">Tipo de dispositivo</label>
                </div>
                <div>
                    <label className="text-option">Beacon</label>
                    <input type="radio" checked={beaconsCheck} id = "radioBeacon" onClick={()=>setBeaconCheck(true)}></input>
                    <label className="text-option">Blufi</label>
                    <input type="radio" id = "radioBlufi" checked={!beaconsCheck} onClick={()=>setBeaconCheck(false)}></input>
                </div>
                <div><label className = "text-option">Aplicar política:</label></div>
                <div>
                    
                    <label className="text-option">Todos los dispositivos</label>
                    <input type="radio" id = "radioGlobal" checked={!deviceCheck} onClick={()=>setDeviceCheck(false)}></input>
                    <label className="text-option" >Dispositivos especificos</label>
                    <input type="radio" checked={deviceCheck}id = "radioIndividual" onClick={()=>setDeviceCheck(true)}></input>
                </div>

                <div>
                    <label className = "text-option">Notificar a los siguientes números:</label>
                    <HelpOutlineIcon 
                        className = "inline-icon"
                        data-tip={"Ingresa números telefónicos con el código de país separados por ',' Ejemplo: 52XXXXXXXX83,52XXXXXXXX90"} 
                    />
                    <ReactTooltip/>
                    <div><textarea className="styled-input custom-ta" id = "sms_whatsapp" defaultValue = {phones} onChange = {e=>setPhones(e.target.value)}></textarea></div>
                </div>
            </div>
        </div>
    );
}
export default Battery;