import React,{useState} from "react";


const ZoneUpdateOptions = props =>
{

    const [newZoneName,setZonenewName] = useState(props.zoneName)
    //const [zoneDesc,setZoneDesc] = useState(props.zoneDesc)
    return(
        <div>
            <label className = "text-option">Nombre de la zona:</label>
            <div><input type="text" className="styled-input" defaultValue ={props.zoneName} onChange={e => props.setName(e.target.value)}></input></div>
            <label className = "text-option">Descripción:</label>
            <div><textarea className="styled-input custom-ta" id = "description" defaultValue={props.zoneDesc} onChange={e=>props.setDesc(e.target.value)}></textarea></div>
            {(newZoneName != null)?<button className = "center-btn-zone" onClick={()=>props.update()}>Actualizar</button>:null}
        </div>
    );
}
export default ZoneUpdateOptions