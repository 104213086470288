import React ,{useState,useContext} from 'react';
import SubTitle from '../../subTitle/SubTitle';
import Battery from "./suboptions/Battery";
import Presence from "./suboptions/Presence";
import Temperature from './suboptions/Temperature';
import Motion from './suboptions/Motion';
import Toast from "../../toast/Toast";
import MotionCondition from './suboptions/MotionCondition';
import Vibration from './suboptions/Vibration';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CSContext } from '../../../App';

const CreatePolicy = props =>
{
    const {apiPath} = useContext(CSContext)
    const [selectedType,setSelectedType] = useState(null);
    const [disableBtn,setDisableBtn] = useState(false);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const setbtn = (setValue) =>
    {
        setDisableBtn(setValue);
    }
    //console.log(disableBtn);
    var optionContent;
    switch (selectedType) {
        case "PRESENCE":
            optionContent = <Presence setBtn={setbtn} />
            break;
        case "BATTERY":
            optionContent = <Battery setBtn={setbtn}/>
            break;
        case "TEMPERATURE":
            optionContent = <Temperature setBtn={setbtn} />
            break;
        case "MOTION":
            optionContent = <Motion setBtn={setbtn} />
            break;
        case "MOTION_CONDITION":
            optionContent = <MotionCondition setBtn ={setbtn}/>
            break;
        case "VIBRATION_THRESHOLD":
            optionContent = <Vibration setBtn={setbtn}/>
            break;
        default:
            break;
    }
    const create = async () =>
    {
        alert("Espera mientras se crea la política");
        var payload;
        var policyType = document.getElementById("policyType_select").value;
        var policyName = document.getElementById("policyName").value;
        var policyDescription = document.getElementById("policyDescription").value;
        var policyPriority = document.getElementById("priorityLevel").value;
        var email = '';
        var sms_whatsapp = document.getElementById("sms_whatsapp").value;
          
        try{var autoClear = document.getElementById("autoClearCheck").checked;}
        catch(error){}
        try{var checkTemp = document.getElementById("durationCheck").checked;}
        catch(error){}
        if(checkTemp == true){var duration = 300;}
        if(autoClear == true){var duration = 300;}
        
        switch (policyType) {
            case "PRESENCE":
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                var durationPresence = document.getElementById("presenceTime").value;
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                payload = {      
                    projectid:props.projectid,          
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyPriority:policyPriority,
                    duration:parseInt(durationPresence,10),
                    policyType:policyType,
                    device:device,
                    scope:scope,
                    email:email,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "MOTION":
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                var duration = document.getElementById('duration_Motion').value;
                var clearDuration = document.getElementById('durationSelectClear').value;
                var motionInterval = document.getElementById('motionWindowInterval').value;
                var motionTolerance = document.getElementById('motionWindowToleranceValue').value;
                payload = {
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyPriority:policyPriority,
                    duration:duration,
                    policyType:policyType,
                    device:device,
                    scope:scope,
                    windowInterval:motionInterval,
                    windowTolerance:motionTolerance,
                    clearDuration:clearDuration,
                    email:email,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "TEMPERATURE":
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                var minTemp = document.getElementById("minTemperature").value;
                var maxTemp = document.getElementById("maxTemperature").value;
                payload = {                
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyPriority:policyPriority,
                    policyType:policyType,
                    duration:duration,
                    device:device,
                    scope:scope,
                    email:email,
                    minTemp:minTemp,
                    maxTemp:maxTemp,
                    checkTemp:checkTemp,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "BATTERY":
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                payload = {                
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyPriority:policyPriority,
                    policyType:policyType,
                    device:device,
                    scope:scope,
                    email:email,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "VIBRATION_THRESHOLD":
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                var vibrationMetric = document.getElementById("vibrationMetric").value;
                var threshold = document.getElementById("thresholdValue").value;
                var duration = document.getElementById("timeWindow").value;
                payload = {
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyPriority:policyPriority,
                    policyType:policyType,
                    device:"BEACON",
                    scope:scope,
                    vibrationmetric:vibrationMetric,
                    threshold:threshold,
                    duration:duration,
                    email:email,
                    sms_whatsapp:sms_whatsapp
                }
                break;
            case "MOTION_CONDITION":
                var trainingInterval = document.getElementById("modelTrainingInterval").value;
                var operatingSpeeds = document.getElementById("operatingSpeeds").value;
                var modelFeatures = document.getElementById("modelFeatures").value;
                var evaluationWindow = document.getElementById("evaluationWindow").value;
                var evaluationThreshold = document.getElementById("evaluationThreshold").value;
                payload = {
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyPriority:policyPriority,
                    policyType:policyType,
                    device:"BEACON",
                    scope:"DEVICE",
                    traininginterval:trainingInterval,
                    operatingspeeds:operatingSpeeds,
                    modelfeatures:modelFeatures,
                    evaluationwindow:evaluationWindow,
                    evaluationThreshold:evaluationThreshold,
                    email:email,
                    sms_whatsapp:sms_whatsapp
                }
                break;
            default:
                break;
        }
        ////console.log(payload);
        const response = await fetch(apiPath+"createPolicy.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
       
        const action = await response.json();
        if(action.status === 200)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Política creada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Politica NO creada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
        }
        ////console.log(action);
    }
    return(
        <div className = "view">
            <div>
                <ArrowBackIcon onClick={()=>props.changeView("options")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Crear nueva política</label>
            </div>
            <div>
                <SubTitle section = {"Información de la política"} size ={"small"}/>
            </div>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <div>
                <label className = "text-option">Nombre de la política:</label>
                <div><input type="text" className="styled-input" id = "policyName"></input></div>
                <label className = "text-option">Descripción de la política:</label>
                <div><textarea className="styled-input custom-ta" id = "policyDescription"></textarea></div>
                <label className = "text-option">Prioridad la política:</label>
                <div>
                    <select type="text" className="styled-input custom-select" id = "priorityLevel">
                        <option value = "normal">Normal</option>
                        <option value = "preventive">Preventiva</option>
                        <option value = "critical">Critica</option>
                    </select>
                </div>
                <label className = "text-option" >Tipo de política:</label>
                <div>        
                    <select id = "policyType_select" type="text" className="styled-input custom-select" onChange={e =>setSelectedType(e.target.value) }>
                        <option>{" "}</option>
                        <option value = "PRESENCE">Presencia</option>
                        <option value = "BATTERY">Batería</option>
                        <option value = "TEMPERATURE">Temperatura</option>
                        <option value = "MOTION">Movimiento</option>
                        <option value = "MOTION_CONDITION">Condicion de movimiento</option>
                        <option value = "VIBRATION_THRESHOLD">Vibración</option>
                    </select>
                </div>
                <div>
                    {optionContent}
                    {(disableBtn)?<button onClick ={create} className="center-btn">Crear política</button>:null}
                </div>
            </div>
        </div>
    );
}
export default CreatePolicy; 