import React, { useState, useEffect,useContext } from "react";
import BeeksTable from "../../tables/BeeksTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../toast/Toast";
import DraggableList from "../../DraggableList/DraggableList";
import { CSContext } from "../../../App";
import "./lists.css";

const ChangePriority = props => {
    //const [beekName, setSelectedBeekName] = useState([]);
    //const [beekId, setSelectedBeekId] = useState([]);
    //const [inputValue, setInputValue] = useState("");
    //const [maxPriority, setMaxPriority] = useState(null);
    //const [actualPriority, setActualPriority] = useState(null);
    const [toastClass, setToastClass] = useState("toast-ok");
    const [toastContent, setToastContent] = useState("");
    const {apiPath} = useContext(CSContext)
    /*const select = async(uselessvar, beekid, beekname) => {

        const useless = uselessvar;
        const payload = { projectid: props.projectid, id: beekid };
        const response = await fetch("/dashboard/dashboard/cs/csback/getMaxPriority.php", {
            method: "POST",
            body: JSON.stringify(payload)
        });
        const priority = await response.json();
        ////console.log(priority);
        setMaxPriority(priority["maxpriority"]);
        setActualPriority(priority["actualpriority"]);
        setSelectedBeekId(beekid);
        setSelectedBeekName(beekname);
    }*/

    /*const priority = async() => {
        const payload = {
            devicename: beekName,
            projectid: props.projectid,
            newpriority: actualPriority
        };
        const response = await fetch("/dashboard/dashboard/cs/csback/changePriority.php", {
            method: "POST",
            body: JSON.stringify(payload)
        });
        const action = await response.json();
        if (action.status === "ok") {
            setToastClass("toast-ok toast-show");
            setToastContent("Nueva prioridad");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedBeekName([]);
            }, 3000);

        } else {
            setToastClass("toast-wrong toast-show");
            setToastContent("Prioridad NO cambiada");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedBeekName([]);
            }, 3000);
        }
        //console.log(action);
    }*/
    const cpriority = async() =>
    {
        alert("La prioridad de los dispositivos ha sido cambiada. Esto puede tardar un momento para que el dashboard se actualice.")
        let items = document.getElementsByClassName('list-item');
        let beeks = [];
        for (let index = 0; index < items.length; index++) 
        {
            beeks.push(items[index].textContent)     
        }
        const payload = {projectid:props.projectid,priorities:beeks}
        const action = await fetch(apiPath+"changePriority.php",{
            method:"POST",
            body:JSON.stringify(payload)
        })
        const response = await action.json()
        if (response.status === "ok") {
            setToastClass("toast-ok toast-show");
            setToastContent("Nueva prioridad");
            setTimeout(() => {
                setToastClass("toast-ok");
            }, 3000);

        } else {
            setToastClass("toast-wrong toast-show");
            setToastContent("Prioridad NO cambiada");
            setTimeout(() => {
                setToastClass("toast-ok");
            }, 3000);
        }
    }
    return(
        <div className="non-margin-view">
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <div>
                <ArrowBackIcon onClick={()=>props.changeView("options")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Cambiar prioridad de los dispositivos</label>
            </div>
            <div>
                <p className = "opt-info">
                    Arrastra los dispositivos para cambiar su prioridad en el sistema
                </p>
            </div>
            <div>
                <button className="center-btn" onClick={cpriority}>Guardar cambios</button>
            </div>
            <DraggableList projectid = {props.projectid}/>
        </div>
    )
    /*return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>(beekName.length==0)?props.changeView("options"):setSelectedBeekName([])} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                {(beekName.length!=0)?<label className = "center-title">{beekName}</label>:<label className = "center-title">Cambiar prioridad del Beacon</label>}    
            </div>
            <p className = "opt-info">
                Arrastra los dispositivos para cambiar su prioridad en el sistema
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <div style={{ width: 300}}>
                <DraggableList width={300} heigth={50} rowsize={1}>
                    {listItems.map((item, index) => (
                        <li className="item-list" key={index}>{`${index + 1}.  ${item}`}</li>
                    ))}
                </DraggableList>
            </div>
        </div>
    );*/

}
export default ChangePriority;