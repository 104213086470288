import "./sidemenu.css";


const SideMenu = props =>
{
    return(
        <div className={(props.showmenu)?"sidemenu-show":"sidemenu"}>
            <div className="sidemenu-close-btn" onClick={()=>props.show(false,null)}>
                &times;
            </div>
            <div>
                {props.content}
            </div>
        </div>
    );
}
export default SideMenu;