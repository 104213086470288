import React,{useEffect,useState,useContext} from "react";
import LineChart from "../graphs/LineChart";
import CmPeriod from "../selects/CmPeriod ";
import DatesSelect from "../selects/DatesSelect";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../App";

const TemperatureLineChart = props =>
{
    const [content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={200} />);
    const [period,setPeriod] = useState("-5 hour");
    const [showDates,setDates] = useState(false)
    const [startDate,setStart] = useState(null)
    const [endDate,setEnd] = useState(null)
    const { apiPath } = useContext(CSContext)

    const changePeriod = (newperiod) =>
    {
        if(newperiod === "custom")
        {
            setEnd(null)
            setStart(null)
            setDates(true)
        }
        else
        {
            setEnd(null)
            setStart(null)
            setDates(false)
            setContent(<Skeleton variant="rectangular" width={"99%"} height={200}/>);
            setPeriod(newperiod);
        }
    }
    useEffect(
        async () =>
        {
            if(startDate && endDate)
            {
                var fontSizeTitle = 12;
                const payload = {
                    projectid : props.projectid,
                    deviceid : props.deviceid,
                    startdate:startDate,
                    enddate:endDate,
                };
                const response = await fetch(apiPath+"getCustomTemp.php",{
                    method:"POST",
                    body:JSON.stringify(payload)
                });
                const chartData = await response.json();
                var options = {
                    title: "Temperatura",
                    titleTextStyle: {
                      color: '#061528',    
                      fontSize: fontSizeTitle  
                    },
                    legend:"none",
                    colors: ['#a6b8b8'],
                    backgroundColor:'#f8f8ff',
                    hAxis:{textStyle:{color:'#f8f8ff'}},
                    vAxis: {
                        format:'##.## C°',
                        gridlines: {
                          color: 'none'
                        },
                        textStyle:{color:"#061528"}
                    }
                  };
                setContent(<LineChart options = {options} data = {chartData.data}/>);
            }

        },[startDate,endDate]
    )
    useEffect(
        async () =>
        {
            setContent(<Skeleton variant="rectangular" width={"99%"} height={200}/>);
            var payload;
            var fontSizeTitle = 12; 
            var graphTitle = "Temperatura 5 horas";
            switch (period) {
                case "-5 hour":
                    graphTitle = "Temperatura 5 horas";
                    break;
                case "-12 hour":
                    graphTitle = "Temperatura 12 horas";
                    break;
                case "-1 day":
                    graphTitle = "Temperatura 1 día";
                    break;
                case "-7 day":
                    graphTitle = "Temperatura 7 días";
                    break;
                case "-15 day":
                    graphTitle = "Temperatura 15 días";
                    break;
                case "-31 day":
                    graphTitle = "Temperatura 31 días";
                    break;
            }
            if(props.details)
            {
                payload = {
                    projectid : props.projectid,
                    deviceid : props.deviceid,
                    axis : props.axis,
                    date:props.date,
                    period:props.period
                };                
            }
            else
            {
                payload = {
                    projectid : props.projectid,
                    deviceid : props.deviceid,
                    axis : props.axis,
                    period:period
                };
            }
            //console.log(payload);
            const response = await fetch(apiPath+"getTemperatureLine.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const chartData = await response.json();
            //console.log(chartData);
            var options = {
                title: graphTitle,
                titleTextStyle: {
                  color: '#061528',    
                  fontSize: fontSizeTitle  
                },
                legend:"none",
                colors: ['#ad1271'],
                backgroundColor:'#f8f8ff',
                hAxis:{textStyle:{color:'#f8f8ff'}},
                vAxis: {
                    format:'##.## C°',
                    gridlines: {
                      color: 'none'
                    },
                    textStyle:{color:"#061528"}
                }
              };
            setContent(<LineChart options = {options} data = {chartData.data}/>);
        },[period,props.reload,props.period]
    );
    return(
        <div>
            <div>
                <div>
                    {(props.details)?null:<CmPeriod changePeriod = {changePeriod}/>}
                    <DatesSelect show={showDates} setDate1 ={setStart} setDate2={setEnd}/>
                </div>
                {content}
            </div>
        </div>
    );
}
export default TemperatureLineChart;