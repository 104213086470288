import React,{useEffect,useState,useContext} from "react";
import GraphPeriod from "../selects/GraphPeriod";
import GraphZone from "../selects/GraphZone";
import OrderBy from "../selects/OrderBy";
import GenericColumnChart from "../graphs/GenericColumnChart";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../App";


const AlertChart = props =>
{
    const [content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const [period,setPeriod] = useState("-30 day");
    const [metric,setMetric] = useState("MOTION");
    const {apiPath} = useContext(CSContext)

    useEffect(
        async () =>
        {
            setContent(<Skeleton variant="rectangular" width={"99%"} height={250} />);
            var graphTitle = "Alertas generadas en un mes";
            switch (period)
            {
                case "-30 day":
                    graphTitle = "Alertas generadas en 1 mes";
                    break;
                case "-1 day":
                    graphTitle = "Alertas generadas en 1 día";
                    break;
                case "-3 day":
                    graphTitle = "Alertas generadas en 3 días";
                    break;
                case "-5 day":
                    graphTitle = "Alertas generadas en 5 días";
                    break;
                case "-7 day":
                    graphTitle = "Alertas generadas en 7 días";
                    break;
                case "-15 day":
                    graphTitle = "Alertas generadas en 15 días";
                    break;
            }
            const payload = {
                projectid:props.projectid,
                metric:metric,
                period:period
            };
            const response = await fetch(apiPath+"getAlertChart.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const chartData = await response.json();
            console.log(chartData)
            const options = {
                bar: {
                  groupWidth: "95%"
                },
                tooltip: {isHtml: true},
                focusTarget: 'category',
                colors: ['#de8c1b'],
                legend: 'none',
                title: graphTitle,
                titleTextStyle: {
                  color: "white",    
                  fontSize: 10  
                },
                backgroundColor:'#f8f8ff',
                seriesType: 'bars',
                hAxis: {
                    textStyle : {
                        fontSize: 10,
                        bold:'true',
                        color:"#061528"
                    }
                },
                vAxis: {
                    gridlines: {
                      color: 'none'
                    },
                    textStyle:{color:"#061528"}
                }
            };
            setContent(<GenericColumnChart options={options} data={chartData.data}/>);
        },[period,metric]
    );

    return(
        <div>
            <div className = "graph-widget">
                <div>
                    <select className = "select-time" id = "period" onChange = {e=>setPeriod(e.target.value)}>
                        <option value ="-30 day">1 mes</option>
                        <option value ="-1 day">1 día</option>
                        <option value ="-3 day">3 días</option>
                        <option value ="-5 day">5 días</option>
                        <option value ="-7 day">7 días</option>
                        <option value ="-15 day">15 días</option>
                    </select>
                    <select id = "metric" className = "select-time" onChange={e=>setMetric(e.target.value)}>
                        <option value = "MOTION">Movimiento</option>
                        <option value = "MOTION_CONDITION">Aceleración/velocidad</option>
                        <option value = "TEMPERATURE">Temperatura</option>
                        <option value = "BATTERY">Batería</option>
                        <option value = "PRESENCE">Presencia</option>
                        <option value = "VIBRATION">Vibración</option>
                    </select>
                </div>
                {content}
            </div>
        </div>
    );
}
export default AlertChart;