import { useState,useEffect, useContext} from "react";
import Battery from "../views/suboptions/Battery";
import Motion from "../views/suboptions/Motion";
import Presence from "../views/suboptions/Presence";
import Temperature from "../views/suboptions/Temperature";
import Vibration from "../views/suboptions/Vibration";
import MotionCondition from "../views/suboptions/MotionCondition";
import Toast from "../../toast/Toast";
import { CSContext } from "../../../App";

const UpdateOptions = props =>
{
    const {apiPath} = useContext(CSContext)
    const [policyInfo,setPolicyInfo] = useState(null);
    const [policyName,setPolicyName] = useState(null);
    const [policyDescription,setPolicyDescription] = useState(null);
    const [options,setOptions] = useState(null);
    const [showBtn,setButton] = useState(false);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    useEffect(
        async () =>
        {
           const payload = {projectid:props.projectid,policyid:props.policyid};
           const response = await fetch(apiPath+"getPolicyInfo.php",{
               method:"POST",
               body:JSON.stringify(payload)
           }); 
           const policy = await response.json();
           //console.log(policy);
           setPolicyInfo(policy.data);
           switch (policy.data["metricType"]) {
                case "TEMPERATURE":
                    setOptions(<Temperature phones={policy.phones} emails = {policy.emails} policy={policy.data} setBtn ={setButton}/>);
                break;
                case "BATTERY":
                    setOptions(<Battery phones={policy.phones} emails = {policy.emails} policy={policy.data} setBtn ={setButton}/>);
                break;
                case "MOTION":
                    setOptions(<Motion phones={policy.phones} emails = {policy.emails} policy={policy.data} setBtn ={setButton}/>);
                break;
                case "MOTION_CONDITION":
                    setOptions(<MotionCondition phones={policy.phones} emails ={policy.emails} policy={policy.data} setBtn={setButton}/>);
                break;    
                case "VIBRATION_THRESHOLD":
                    setOptions(<Vibration phones={policy.phones} emails={policy.emails} policy={policy.data} setBtn ={setButton}/>);
                break;
                case "PRESENCE":
                    setOptions(<Presence phones={policy.phones} emails = {policy.emails} policy={policy.data} setBtn ={setButton}/>);
                break;
                default:
                   break;
           }
           setPolicyName(policy.data["name"]);
           setPolicyDescription(policy.data["description"]);
        },[]
    );
    const update = async () =>
    {
        alert("Espera mientras se actualiza la política");
        var payload;
        var policyType = policyInfo["metricType"];
        var policyName = document.getElementById("policyName").value;
        var policyDescription = document.getElementById("policyDescription").value;
        var email = '';
        var sms_whatsapp = document.getElementById("sms_whatsapp").value;
        
        try{var autoClear = document.getElementById("autoClearCheck").checked;}
        catch(error){}
        try{var checkTemp = document.getElementById("durationCheck").checked;}
        catch(error){}
        if(checkTemp == true){var duration = 300;}
        if(autoClear == true){var duration = 300;}
        
        switch (policyType) {
            case "PRESENCE":
                var durationPresence = document.getElementById("presenceTime").value;
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                payload = {      
                    projectid:props.projectid,          
                    policyName:policyName,
                    policyDescription:policyDescription,
                    duration:parseInt(durationPresence,10),
                    policyType:policyType,
                    device:device,
                    scope:scope,
                    email:email,
                    policyid:props.policyid,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "MOTION":
                var duration = document.getElementById('duration_Motion').value;
                var clearDuration = document.getElementById('durationSelectClear').value;
                var motionInterval = document.getElementById('motionWindowInterval').value;
                var motionTolerance = document.getElementById('motionWindowToleranceValue').value;
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                payload = {
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    duration:duration,
                    policyType:policyType,
                    device:device,
                    scope:scope,
                    windowInterval:motionInterval,
                    windowTolerance:motionTolerance,
                    clearDuration:clearDuration,
                    email:email,
                    policyid:props.policyid,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "TEMPERATURE":
                var minTemp = document.getElementById("minTemperature").value;
                var maxTemp = document.getElementById("maxTemperature").value;
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                payload = {                
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyType:policyType,
                    duration:duration,
                    device:device,
                    scope:scope,
                    email:email,
                    minTemp:minTemp,
                    policyid:props.policyid,
                    maxTemp:maxTemp,
                    checkTemp:checkTemp,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "BATTERY":
                var beacon = document.getElementById("radioBeacon").checked;
                var device = (beacon)?"BEACON":"BLUFI";
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                payload = {                
                    projectid:props.projectid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyType:policyType,
                    device:device,
                    scope:scope,
                    email:email,
                    policyid:props.policyid,
                    sms_whatsapp:sms_whatsapp
                };
                break;
            case "VIBRATION_THRESHOLD":
                var vibrationMetric = document.getElementById("vibrationMetric").value;
                var threshold = document.getElementById("thresholdValue").value;
                var duration = document.getElementById("timeWindow").value;
                var globalScope = document.getElementById("radioGlobal").checked;
                var scope = (globalScope)?"GLOBAL":"DEVICE";  
                payload = {
                    projectid:props.projectid,
                    policyid:props.policyid,
                    policyName:policyName,
                    policyDescription:policyDescription,
                    policyType:policyType,
                    device:"BEACON",
                    scope:scope,
                    vibrationmetric:vibrationMetric,
                    threshold:threshold,
                    duration:duration,
                    email:email,
                    sms_whatsapp:sms_whatsapp
                }
                break;
                case "MOTION_CONDITION":
                    var trainingInterval = document.getElementById("modelTrainingInterval").value;
                    var operatingSpeeds = document.getElementById("operatingSpeeds").value;
                    var modelFeatures = document.getElementById("modelFeatures").value;
                    var evaluationWindow = document.getElementById("evaluationWindow").value;
                    var evaluationThreshold = document.getElementById("evaluationThreshold").value;
                    payload = {
                        projectid:props.projectid,
                        policyName:policyName,
                        policyid:props.policyid,
                        policyDescription:policyDescription,
                        policyType:policyType,
                        device:"BEACON",
                        scope:"DEVICE",
                        traininginterval:trainingInterval,
                        operatingspeeds:operatingSpeeds,
                        modelfeatures:modelFeatures,
                        evaluationwindow:evaluationWindow,
                        evaluationThreshold:evaluationThreshold,
                        email:email,
                        sms_whatsapp:sms_whatsapp
                    }
                break;
            default:
                break;
        }
        //console.log(payload);
        const response = await fetch(apiPath+"updatePolicy.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
       
        const action = await response.json();
        if(action.status === 200)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Política actualizada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.change([]);
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Politica NO actualizada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.change([]);
            },3000);
        }
        //console.log(action);
    }
    return(
        <div>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <label className = "text-option">Nombre de la política:</label>
            <div><input type="text" className="styled-input" id = "policyName" defaultValue={(policyInfo)?policyName:null} onChange={e=>setPolicyName(e.target.value)}></input></div>
            <label className = "text-option">Descripción de la política:</label>
            <div><textarea className="styled-input custom-ta" id = "policyDescription" defaultValue ={(policyInfo)?policyDescription:null} onChange={e=>setPolicyDescription(e.target.value)}></textarea></div>
            {options}
            {(showBtn)?<button onClick={update} className = "center-btn">Actualizar Política</button>:null}
        </div>
    );
}
export default UpdateOptions;