import React,{useContext} from "react";
import SubTitle from "../subTitle/SubTitle";
import { CSContext } from "../../App";
import "./navigationtab.css";

const MetricsContent = props =>
{
    const {apiPath} = useContext(CSContext)
    const exportMetrics = async ()=>
    {
        const tempCheck = document.getElementById("temp").checked;
        const tempMaxCheck = document.getElementById("maxtemp").checked;
        const tempMinCheck = document.getElementById("mintemp").checked;
        const peakCheck = document.getElementById("peakinfo").checked;
        const rmsCheck = document.getElementById("rmsinfo").checked;
        const peakpeakCheck = document.getElementById("peakpeakinfo").checked;
        const batteryCheck = document.getElementById("batteryinfo").checked;
        const date1 = document.getElementById("date1").value;
        const date2 = document.getElementById("date2").value;
        const payload = {
            export:"ok",
            projectid:props.projectid,
            device:props.deviceid,
            devicename:props.devicename,
            temp:tempCheck,
            minTemp:tempMinCheck,
            maxTemp:tempMaxCheck,
            peakInfo:peakCheck,
            peakpeakInfo:peakpeakCheck,
            rmsInfo:rmsCheck,
            batteryInfo:batteryCheck,
            firstDate: date1,
            secondDate: date2
        }
        //console.log(payload);
        const response = await fetch(apiPath+"exportMetric.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const content = await response.json();
        if(content.created == "ok")
        {
            var download = document.createElement("a");
            download.href = apiPath+"downloadExportMetric.php";
            download.style.display = "none";
            document.body.appendChild(download);
            download.click();
            document.body.removeChild(download);
        }
    }
    return(
        <div className = "nav-content">
            <SubTitle section = {"Selecciona el tipo de métricas que deseas exportar"} size ={"small"}/>
            <div>
                <input type = "checkbox" id = "temp"></input>
                <label className = "cs-font-label">Temperatura</label>
            </div>
            <div>
                <input type = "checkbox" id = "maxtemp"></input>
                <label className = "cs-font-label">Temperatura máxima</label>
            </div>
            <div>
                <input type = "checkbox" id = "mintemp"></input>
                <label className = "cs-font-label">Temperatura mínima</label>
            </div>
            <div>
                <input type = "checkbox" id = "peakinfo"></input>
                <label className = "cs-font-label">Vibraciones pico promedio</label>
            </div>
            <div>
                <input type = "checkbox" id = "peakpeakinfo"></input>
                <label className = "cs-font-label">Vibraciones pico pico promedio</label>
            </div>
            <div>
                <input type = "checkbox" id = "rmsinfo"></input>
                <label className = "cs-font-label">Vibraciones RMS promedio</label>
            </div>
            <div>
                <input type = "checkbox" id = "batteryinfo"></input>
                <label className = "cs-font-label">Voltaje de la batería</label>
            </div>
            <SubTitle section={"Selecciona el intervalo de tiempo para exportar las métricas"} size = {"small"}/>
            <div>
                <label className = "cs-font-label">Fecha 1:</label>
                <input type = "date" id = "date1" name = "date1" className = "cs-dateInput"></input>
            </div>
            <div>
                <label className = "cs-font-label">Fecha 2:</label>
                <input type = "date" id = "date2" name = "date2" className = "cs-dateInput"></input>
            </div>
            <div className = "export" onClick={()=>exportMetrics()}>
                Exportar métricas
            </div>
        </div>
    );
}
export default MetricsContent; 