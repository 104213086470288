import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import CreateIcon from '@mui/icons-material/Create';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DeleteIcon from '@mui/icons-material/Delete';

const ConfZonesOpt = props =>
{
    return(
        <div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("create")}>
                    <CreateIcon  className = "inline-icon-opt"></CreateIcon >
                    Crear nueva zona.
                </div>
                <p className = "opt-info">
                    Crea una nueva zona para agrupar dispositivos en ella.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("update")}>
                    <UpdateIcon className = "inline-icon-opt"></UpdateIcon>
                    Actualizar zona.
                </div>
                <p className = "opt-info">
                    Selecciona una zona para modificar sus valores y actualizarla.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("updatedefault")}>
                    <DynamicFeedIcon  className = "inline-icon-opt"></DynamicFeedIcon>
                    Actualizar zona predeterminada
                </div>
                <p className = "opt-info">
                    Selecciona una nueva zona predeterminada.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("addbeeks")}>
                    <AddCircleIcon className = "inline-icon-opt"></AddCircleIcon>
                    Agregar Beacons a una zona.
                </div>
                <p className = "opt-info">
                    Selecciona una zona y agrega uno o más Beacons a ella.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("delete")}>
                    <DeleteIcon className ="inline-icon-opt"></DeleteIcon>
                    Eliminar zona.
                </div>
                <p className = "opt-info">
                    Selecciona una zona para eliminarla del sistema.
                </p>
            </div>
        </div>
    );
}
export default ConfZonesOpt;