import React,{forwardRef,useEffect} from "react";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { ThemeProvider, createTheme } from "@mui/material";

import './table.css';
const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};


const PoliciesTable = props =>
{
  /*console.log(props.id);
  let data = async query =>
  {
    const payload = {projectid:props.projectid};
    const response = await fetch("/cs/csback/policiesTable.php",{
      method:"POST",
      body:JSON.stringify(payload)
    });
    var resultResponse = await response.json();
    console.log(resultResponse);
    var result = resultResponse["data"];
    var total = resultResponse["total"];
    //var pages = resultResponse["pages"];
    const searchWord = query.search;
    const order = query.orderDirection;
    var pages = query.page;
    console.log(query.page);
    var orderCol = "ID de la politica";
    if (typeof query.orderBy !== 'undefined')
    {
      orderCol = query.orderBy.title;
    } 
    if(orderCol === "ID de la politica")
    {
      result.sort((a,b)=>{
        if (a["ID de la politica"] < b["ID de la politica"])
          return (order ==="asc")?-1:1;
        if (a["ID de la politica"] > b["ID de la politica"])
          return (order ==="asc")?1:-1;
        return 0;
      });
    }
    if(orderCol === "Nombre de la politica")
    {
      result.sort((a,b)=>{
        if (a["Nombre de la politica"] < b["Nombre de la politica"])
          return (order ==="asc")?-1:1;
        if (a["Nombre de la politica"] > b["Nombre de la politica"])
          return (order ==="asc")?1:-1;
        return 0;
      });
    } 
    result = result.filter((item) =>{
      if(item["Nombre de la politica"].toLowerCase().includes(searchWord.toLowerCase()))
      {
        return item["Nombre de la politica"];
      }
    }); 
    result = result.slice((5*pages),(5+(5*pages)));
    return {
      data: result,
      page: pages,// current page number
      totalCount:total // total row number
    }
    
  }*/
  const defaultMaterialTheme = createTheme()
  return(
    <div className = "cs-table">
      <ThemeProvider theme= {defaultMaterialTheme} >
        <MaterialTable
          icons={tableIcons}
          options={{
            pageSizeOptions:[],
            headerStyle:{
              backgroundColor:'#f8f8ff',
              color:'#061528',
              borderBottom:'none',
              zIndex:'0'
            },
            /*rowStyle: {
              backgroundColor:'rgb(84,83,83)',     
              color:'#ffff',
              borderTop:'3px solid #0000',
            },*/
            rowStyle: rowData => ({
              backgroundColor: (rowData["ID de la politica"]==props.id) ? 'rgb(64, 63, 63)' : '#f8f8ff',
              color:'#061528',
              borderTop:'3px solid #0000',
            }),
            searchFieldStyle:{
              color:'#000000'
            }
          }}
          columns={[
            { title: (props.device == "phone")?"Nombre de la política":"ID de la política", field: (props.device == "phone")?"Nombre de la politica":"ID de la politica"},
            { title: (props.device == "phone")?"ID de la política":"Nombre de la política", field:(props.device == "phone")?"ID de la politica":"Nombre de la politica"},
          ]} 
          data = {props.data}
          onRowClick={((evt, selectedRow) => props.setSelected(selectedRow["ID de la politica"],selectedRow["Nombre de la politica"]))}
          title="Políticas"
          style= {{
            backgroundColor:'#f8f8ff',
            border:'none',
            color:'#061528'
          }}
        />
      </ThemeProvider>
  </div>
  );
}
export default PoliciesTable;