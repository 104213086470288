import React,{useEffect,useState, useContext} from "react";
import GraphPeriod from "../selects/GraphPeriod";
import GraphZone from "../selects/GraphZone";
import OrderBy from "../selects/OrderBy";
import GenericColumnChart from "../graphs/GenericColumnChart";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../App";

const TemperatureChart = props =>
{
    const [content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={200} />);
    const [firstPriority,setFirstPriority] = useState(1);
    const [period,setPeriod] = useState("-1 hour");
    const [zone,setZone] = useState(null);
    const [gruoping,setGruoping] = useState("priority");
    const [priorityBlock,setPriorityBlock] = useState("1")
    const [maxPBlock,setMaxPBlock] = useState();
    const {apiPath} = useContext(CSContext)

    const moveRight = () =>
    {
        let newBlock = parseInt(priorityBlock);
        if(newBlock == maxPBlock)
        {
            setPriorityBlock("1");
        }
        else
        {
            newBlock++;
            setPriorityBlock(newBlock.toString());
        }
        setContent(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    }
    const moveLeft = () =>
    {
        let newBlock = parseInt(priorityBlock);
        if(newBlock == 1)
        {
            setPriorityBlock(maxPBlock.toString());
        }
        else
        {
            newBlock--;
            setPriorityBlock(newBlock.toString());
        }
        setContent(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    }

    const movePeriod = (newperiod) =>
    {
        setPeriod(newperiod);
        setContent(<Skeleton variant="rectangular" width={"99%"} height={200} />);
    }
    useEffect(
        async () =>
        {
            if(!zone)
            {
                const payloadZone ={projectid:props.projectid}
                const zoneName = await fetch(apiPath+"getDefaultZone.php",{
                    method:"POST",
                    body:JSON.stringify(payloadZone)
                });
                const zoneData = await zoneName.json();
                setZone(zoneData.name);
                //setZone("none")
            }
        },[]
    );
    useEffect( 
        async () =>
        {
            let selectedZone = (gruoping==="priority")?"none":zone;
            let data;
            setContent(<Skeleton variant="rectangular" width={"99%"} height={200} />);
            var graphTitle = "Movimiento Pico 1 hora";
            let fileType = "";
            switch (period) {
                case "-1 hour":
                    graphTitle = "Temperatura en 1 hora";
                    fileType = "1h";
                    break;
                case "-3 hour":
                    graphTitle = "Temperatura en 3 horas";
                    fileType = "3h";
                    break;
                case "-12 hour":
                    graphTitle = "Temperatura en 12 horas";
                    fileType = "12h";
                    break;
                case "-1 day":
                    graphTitle = "Temperatura en 1 día";
                    fileType = "1d";
                    break;
                case "-3 day":
                    graphTitle = "Temperatura en 3 días";
                    fileType = "3d";
                    break;
                case "-7 day":
                    graphTitle = "Temperatura en 7 días";
                    fileType = "7d";
                    break;
            }

            const basePath = "https://celarsens-storage.s3.us-west-1.amazonaws.com/";
            
            
            
            //const basePath = "/charts/"
            const order = (gruoping==="priority")?"/tempchart.json":"/zonetempchart.json"
            const response = await  fetch(basePath+props.projectid+order);
            const chartData = await response.json();
            if(gruoping==="priority")
            {
                const blocks = Object.keys(chartData);
                setMaxPBlock(parseInt(blocks.pop()));
                data = chartData[fileType][priorityBlock]
            }
            else
            {
                data = chartData[fileType][zone]
            }
            //console.log(gruoping)
            //console.log(zone)
            const options = {
                bar: {
                  groupWidth: "61.8%"
                },
                tooltip: {isHtml: true},
                focusTarget: 'category',
                colors: ['#a6b8b8', '#2380a5', '#781d3e'],
                legend: 'none',
                title: graphTitle,
                titleTextStyle: {
                  color: "#061528",    
                  fontSize: 13
                },
                backgroundColor:'#f8f8ff',
                seriesType: 'bars',
                hAxis:{textStyle:{color:"#061528"}},
                vAxis: {
                    format:'##.## °C',
                    gridlines: {
                      color: 'none'
                    },
                    textStyle:{color:"#061528"}
                }
            };
            setContent(<GenericColumnChart data={data} options = {options}/>);
        },[priorityBlock,period,props.reload,zone,gruoping]
    );

    return(
        <div>
            <div className = "graph-widget-cs">
                <div>
                    <button onClick={moveLeft} className = "graph-option first-graph-option btn-graph">{"<"}</button>
                    <GraphPeriod changePeriod ={movePeriod}/>
                    <GraphZone projectid = {props.projectid} able={gruoping} zone={setZone}/>
                    <OrderBy changeOrder ={setGruoping} zones ={setZone} projectid={props.projectid}/>
                    <button onClick={moveRight} className = "graph-option btn-graph">{">"}</button>
                </div>
                {content}
            </div>
        </div>
    );
}
export default TemperatureChart;