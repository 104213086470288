    import React,{useEffect,useState, useContext} from "react";
    import LineChart from "../graphs/LineChart";
    import CmPeriod from "../selects/CmPeriod ";
    import DatesSelect from "../selects/DatesSelect";
    import { Skeleton } from "@mui/material";
    import { CSContext } from "../../App";


    const PeakPeakLineChart = props =>
    {
        const [content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={200} />);
        const [period,setPeriod] = useState("-5 hour");
        const [showDates,setDates] = useState(false)
        const [startDate,setStart] = useState(null)
        const [endDate,setEnd] = useState(null)
        const {apiPath } = useContext(CSContext)

        const changePeriod = (newperiod) =>
        {
            if(newperiod === "custom")
            {
                setEnd(null)
                setStart(null)
                setDates(true)
            }
            else
            {
                setEnd(null)
                setStart(null)
                setDates(false)
                setContent(<Skeleton variant="rectangular" width={"99%"} height={200}/>);
                setPeriod(newperiod);
            }
        }
        useEffect(
            async () =>
            {
                if(startDate && endDate)
                {
                    var fontSizeTitle = 12;
                    const payload = {
                        projectid : props.projectid,
                        deviceid : props.deviceid,
                        axis : props.axis,
                        startdate:startDate,
                        enddate:endDate,
                        line:"peak_peak"
                    };
                    const response = await fetch(apiPath+"getCustomLine.php",{
                        method:"POST",
                        body:JSON.stringify(payload)
                    });
                    const chartData = await response.json();
                    var options = {
                        title: "Movimiento Pico Pico",
                        titleTextStyle: {
                          color: '#061528',    
                          fontSize: fontSizeTitle  
                        },
                        legend:"none",
                        colors: ['#a6b8b8'],
                        backgroundColor:'#f8f8ff',
                        hAxis:{textStyle:{color:'#f8f8ff'}},
                        vAxis: {
                            format:'##.## g',
                            gridlines: {
                              color: 'none'
                            },
                            textStyle:{color:"#061528"}
                        }
                      };
                    setContent(<LineChart options = {options} data = {chartData.data}/>);
                }
    
            },[startDate,endDate]
        )
        useEffect(
            async () =>
            {
                setContent(<Skeleton variant="rectangular" width={"99%"} height={200}/>);
                var fontSizeTitle = 12; 
                var payload;
                var graphTitle = "Movimiento Pico Pico 5 horas eje: "+props.axis;
                switch (period) {
                    case "-5 hour":
                        graphTitle = "Movimiento Pico Pico 5 horas eje: "+props.axis;
                        break;
                    case "-12 hour":
                        graphTitle = "Movimiento Pico Pico 12 horas eje: "+props.axis;
                        break;
                    case "-1 day":
                        graphTitle = "Movimiento Pico Pico 1 día eje: "+props.axis;
                        break;
                    case "-7 day":
                        graphTitle = "Movimiento Pico Pico 7 días eje: "+props.axis;
                        break;
                    case "-15 day":
                        graphTitle = "Movimiento Pico Pico 15 días eje: "+props.axis;
                        break;
                    case "-31 day":
                        graphTitle = "Movimiento Pico Pico 1 mes eje: "+props.axis;
                        break;
                }
                if(props.details)
                {
                    payload = {
                        projectid : props.projectid,
                        deviceid : props.deviceid,
                        axis : props.axis,
                        date:props.date,
                        period:props.period
                    };                
                }
                else
                {
                    payload = {
                        projectid : props.projectid,
                        deviceid : props.deviceid,
                        axis : props.axis,
                        period:period
                    };
                }
                const response = await fetch(apiPath+"getPeakPeakLine.php",{
                    method:"POST",
                    body:JSON.stringify(payload)
                });
                const chartData = await response.json();
                var options = {
                    title: graphTitle,
                    titleTextStyle: {
                    color: '#061528',    
                    fontSize: fontSizeTitle  
                    },
                    legend:"none",
                    colors: ['#de8c1b'],
                    backgroundColor:'#f8f8ff',
                    hAxis:{textStyle:{color:'#f8f8ff'}},
                    vAxis: {
                        format:'##.## g',
                        gridlines: {
                        color: 'none'
                        },
                        textStyle:{color:"#061528"}
                    }
                };
                setContent(<LineChart options = {options} data = {chartData.data}/>);
            },[period,props.reload,props.period]
        );
        return(
            <div>
                <div>
                    <div>
                        {(props.details)?null:<CmPeriod changePeriod = {changePeriod}/>}
                        <DatesSelect show={showDates} setDate1 ={setStart} setDate2={setEnd}/>
                    </div>
                    {content}
                </div>
            </div>
        );
    }
    export default PeakPeakLineChart;