import {useState} from "react";

//import "./navigationtab.css";
//<div className = {(tabOption == "metrics")?"selected-nav-header-option option-mobile":"nav-header-option"} onClick={()=>selectOption("metrics")}>Métricas</div>
const NavigationHeader = props =>
{
    const [tabOption,setTabOption] = useState("violations");
    const selectOption = (option) =>
    {
        props.change(option);
        setTabOption(option);
    }
    //<div className = {(tabOption == "general")?"selected-nav-header-option option-mobile":"nav-header-option"} onClick={()=>selectOption("general")}>General</div>
    return(
        <div className = "nav-header">
            <div className = {(tabOption == "violations")?"selected-nav-header-option option-mobile":"nav-header-option"} onClick={()=>selectOption("violations")}>Violaciones</div>
            
            <div className = {(tabOption == "statistics")?"selected-nav-header-option option-mobile":"nav-header-option"} onClick={()=>selectOption("statistics")}>Estadísticas</div>    
        </div>
        );
}
export default NavigationHeader;