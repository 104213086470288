import { useContext } from "react";
import { CSContext } from "../../App";

const OrderBy = props =>
{

    const {apiPath} = useContext(CSContext)
    const change = async (newOrder) =>
    {
        const payloadZone ={projectid:props.projectid}
        const zoneName = await fetch(apiPath+"getDefaultZone.php",{
            method:"POST",
            body:JSON.stringify(payloadZone)
        });
        const zoneData = await zoneName.json();
        props.zones(zoneData.name);
        props.changeOrder(newOrder)

    }
    return(
        <div className = "graph-options">
            <select onChange = {e=>change(e.target.value)} className = "select-time">
                <option value = "priority">Prioridad</option>
                <option value = "zones">Zonas</option>
            </select>
        </div>
    );
}
export default OrderBy;