import React,{useState} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import ConfBeeksOpt from "../../components/configurations/configurationOptions/ConfBeeksOpt";
import AddBeek from "../../components/configurations/views/AddBeek";
import DeleteBeek from "../../components/configurations/views/DeleteBeek";
import ChangeBeekName from "../../components/configurations/views/ChangeBeekName";
import CloneBeekTemplate from "../../components/configurations/views/CloneBeekTemplate";
import DeleteBeekTemplate from "../../components/configurations/views/DeleteBeekTemplate";
import ChangePriority from "../../components/configurations/views/ChangePriority";
import "./configurations.css";


const Beeks = props =>
{    
    var content;
    const [view,setView] = useState("options");
    const changeView  = newView =>
    {
        setView(newView);
    }
    switch (view) {
        case "options":
            content = <ConfBeeksOpt changeView = {changeView}/>
            break;
        case "changename":
            content = <ChangeBeekName device = {props.device} projectid={props.projectid} changeView ={changeView}/>
            break;
        case "addbeek":
            content = <AddBeek device = {props.device} projectid ={props.projectid} changeView ={changeView}/>
            break;
        case "deletebeek":
            content = <DeleteBeek device = {props.device} projectid ={props.projectid} changeView ={changeView}/>
            break;
        case "updatebeektemplate":
            content = "updatebt";
            break;
        case "clonetemplate":
            content = <CloneBeekTemplate projectid ={props.projectid} changeView = {changeView}/>           
            break;
        case "updatetemplate":
            content = "templeateupd"
            break;
        case "deletetemplate":
            content = <DeleteBeekTemplate projectid = {props.projectid} changeView = {changeView}/>
            break;
        case "updatepriority":
            content = <ChangePriority device = {props.device} projectid = {props.projectid} changeView = {changeView}/>
            break;            
        default:
            content = <ConfBeeksOpt changeView = {changeView}/>;
            break;
    }
    return(
        <div className = "conf-container">
            <SubTitle section = {"Configurar Beacons"}/>
            <div className = "options-container">
                {content}
            </div>
        </div>
    );
}
export default Beeks; 