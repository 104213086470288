import React, {useEffect,useState,useContext} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import NavigationtabBF from "../../components/navigationTabBF/NavigationTabBF";
import { CSContext } from "../../App";

import "./blufi.css";



const  Blufi = props =>
{
    const {apiPath} = useContext(CSContext)
    const [blufiData,setBlufiData] = useState(null);
    useEffect(
        async()=>
        {
            const payload ={projectid:props.projectid,blufiid:props.id};
            console.log(payload);
            const response = await fetch(apiPath+"getBlufiInfo.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const blufiInfo = await response.json();
            //console.log(blufiInfo);
            setBlufiData(blufiInfo);
            
        },[blufiData]
    );
    return(
        <div className = "blufi">
            <div>
                <span className = "back" onClick ={()=>props.changePage("devices")}>
                    <ChevronLeft className = "inline-icon"></ChevronLeft>
                </span>
                <SubTitle section ={props.name}/>  
            </div>
            <div>
                <NavigationtabBF blufiinfo ={blufiData} projectid={props.projectid} blufiid={props.id}/>
            </div>
        </div>
    );
}
export default Blufi;