import React,{forwardRef, useEffect} from "react";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { ThemeProvider, createTheme } from "@mui/material";

import './table.css';
const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

const ProjectsTable = props =>
{
    
    /*const changeProject = (val)=>
    {
        async () =>
        {
            const payload = {projectid:val["ID del proyecto"]}
            const response = await fetch("/dashboard/cs/csback/changeProject.php",{
                method:"POST",
                body: JSON.stringify(payload)
            });
            const project = await response.json();
            if(project["status"] == "ok")
            {
                const newUserdata={"username":props.userinfo.username,"role":props.userinfo.role,"projectname":val["Nombre del proyecto"],"projectid":val["ID del proyecto"]};
                var change = document.createElement("div");
                change.style.display = "none";
                change.onclick = () => props.change(newUserdata);
                document.body.appendChild(change);
                change.click();
                document.body.removeChild(change);
                
            }
            
        }
        //console.log(val);
    }*/
    const defaultMaterialTheme = createTheme()
    return(
        <div>
            <ThemeProvider theme = {defaultMaterialTheme} >
                <MaterialTable
                    icons={tableIcons}
                    options={{
                        headerStyle:{
                            backgroundColor:'#f8f8ff',
                            color:'#061528',
                            borderBottom:'none',
                            zIndex:'0'
                        },
                        rowStyle: {
                            backgroundColor:'#f8f8ff',     
                            color:'#061528',
                            borderTop:'3px solid #0000',
                        },
                        pageSizeOptions: [2, 5, 10, { value: props.data.length, label: 'All' }],
                        pageSize: 2,
                        searchFieldStyle:{
                            color:'#000000'
                        }
                    }}
                    columns={[
                        {title:"Nombre del proyecto", field: "Nombre del proyecto"},
                        {title:"ID del proyecto", field: "ID del proyecto" },
                    ]} 
                    title=""
                    data = {props.data}
                    onRowClick={((evt, selectedRow) => props.change(selectedRow))}
                    style= {{
                        backgroundColor:'#f8f8ff',
                        border:'none',
                        color:'#061528'
                    }}
                />
            </ThemeProvider>
        </div>
    );
}
export default ProjectsTable;