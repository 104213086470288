import React,{useState,useEffect,useContext} from "react";
import ProjectsTable from "../tables/ProjectsTable";

import { CSContext } from "../../App";

const Projects = props =>
{
    const [content,setContent] = useState(<div>Cargando...</div>);
    const {apiPath,setApiPath} = useContext(CSContext)
    useEffect(
        async () =>
        {
            const payload = {user:props.user.username};
            const response = await fetch(apiPath+"getAllprojects.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const projects = await response.json();
            const projectsData = Object.values(projects);
            var rows = [];
            projectsData.forEach(element =>{
                var projectRow = {"Nombre del proyecto":element["Nombre del proyecto"],"ID del proyecto":element["ID del proyecto"]};
                rows.push(projectRow);
            });
            setContent(<ProjectsTable data={rows} change={props.change} userinfo={props.user}/>);
            //console.log(projects);
        },[]
    );
    return(
        <div>
            {content}
        </div>
    );
}
export default Projects;