import React,{useEffect,useState} from 'react';
import DashboardIcon from '@material-ui/icons/Speed';
import AlertIcon from '@material-ui/icons/NotificationImportant';
import SensorsIcon from '@material-ui/icons/OfflineBolt';
import ChartIcon from '@material-ui/icons/Timeline';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import BluetoothSearchingIcon from '@mui/icons-material/BluetoothSearching';
import GavelIcon from '@mui/icons-material/Gavel';
import Zone from '@mui/icons-material/CalendarViewMonth';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BlufisConected from '@mui/icons-material/Wifi';
import BeaconsConnected from '@mui/icons-material/BluetoothConnected';
import "./mobilemenu.css";

const MobileOptions = props =>
{
    const [conectedBeeks,setConectedBeeks] = useState('?');
    const [totalBeeks,setTotalBeeks] = useState('?');
    const [statusBeeks,setStatusBeeks] = useState("con-device con-ok");
    const [conectedBlufis,setConectedBlufis] = useState('?');
    const [totalBlufis,setTotalBlufis] = useState('?')
    const [statusBlufis,setStatusBlufi] = useState("con-device con-ok");


    const change = (page) =>
    {
        props.showmenu(!props.showed);
        props.changePage(page);
    }  
    const openModal = (modalName) =>
    {
        props.showmenu(!props.showed);
        props.modals(true,modalName);
    }


    useEffect(
        async() =>
        {
            const payload = {projectid:props.projectid}
            const response = await fetch("/dashboard/cs/csback/getConectedBeeks.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const devices = await response.json();
            const porcentaje= (devices.activedevices / devices.totaldevices)*100;
            const intPorcentaje = Math.round( porcentaje );
            if(intPorcentaje == 100)
            {
                setStatusBeeks("con-device con-ok");
            }
            if(intPorcentaje < 100)
            {
                setStatusBeeks("con-device con-war");
            }
            if(intPorcentaje <= 30)
            {
                setStatusBeeks("con-device con-error");
            }

            setConectedBeeks(devices.activedevices);
            setTotalBeeks(devices.totaldevices)
        },[]       
    );

    useEffect(
        async() =>
        {
            const payload = {projectid:props.projectid}
            const response = await fetch("/dashboard/cs/csback/getConectedBlufis.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const devices = await response.json();
            const porcentaje= (devices.activedevices / devices.totaldevices)*100;
            const intPorcentaje = Math.round( porcentaje );
            if(intPorcentaje == 100)
            {
                setStatusBlufi("con-device con-ok");
            }
            if(intPorcentaje < 100)
            {
                setStatusBlufi("con-device con-war");
            }
            if(intPorcentaje <= 30)
            {
                setStatusBlufi("con-device con-error");
            }
            setConectedBlufis(devices.activedevices);
            setTotalBlufis(devices.totaldevices);
            
        },[]
    );
    return(
        <div className='menu'>
            <div className='card-opt-row'>
                <div className='card-opt-col'>
                    <div className={statusBeeks}>
                        <div className='center-content'>
                            <BeaconsConnected className='menu-icon'></BeaconsConnected>
                            <div className='text-device'>
                                {"Beacons conectados:"+conectedBeeks+"/"+totalBeeks}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-opt-col'>
                    <div className={statusBlufis}>
                        <div className='center-content'>
                            <BlufisConected className='menu-icon'></BlufisConected>
                            <div className='text-device'>
                                {"Blufis conectados:"+conectedBlufis+"/"+totalBlufis}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-opt-row'>
                <div className='card-opt-col'>
                    <div className='card-opt' onClick={()=>change("dashboard")}>
                        <div className='center-content'>
                            <DashboardIcon className='menu-icon'></DashboardIcon>
                            <div className='text-long'>
                                Dashboard
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-opt-col'>
                    <div className='card-opt' onClick={()=>change("alert")}>
                        <div className='center-content'>
                            <AlertIcon className='menu-icon'></AlertIcon>
                            <div className='text-middle'>
                                Alertas
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-opt-row'>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>change("graphs")}>
                            <ChartIcon className='menu-icon'></ChartIcon>
                            <div className='text-middle'>
                                Gráficas
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>change("devices")}>
                            <SensorsIcon className='menu-icon'></SensorsIcon>
                            <div className='text-long'>
                                Dispositivos
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-opt-row'>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>change("beeks")}>
                            <SettingsRemoteIcon className='menu-icon'></SettingsRemoteIcon>
                            <div className='text-middle'>
                                Beacons
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>change("blufis")}>
                            <BluetoothSearchingIcon className='menu-icon'></BluetoothSearchingIcon>
                            <div className='text-middle'>
                                Blufis
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-opt-row'>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content'onClick={()=>change("policies")} >
                            <GavelIcon className='menu-icon'></GavelIcon>
                            <div className='text-middle'>
                                Políticas
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>change("zones")}>
                            <Zone className='menu-icon'></Zone>
                            <div className='text-middle'>
                                Zonas
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-opt-row'>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>props.modals(true,"Reptemp")}>
                            <ThermostatIcon className='menu-icon'></ThermostatIcon>
                            <div className='text-long'>
                                Reporte Temperatura
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>props.modals(true,"Repmov")}>
                            <TrendingUpIcon className='menu-icon'></TrendingUpIcon>
                            <div className='text-long'>
                                Reporte Movimiento
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-opt-row'>
                <div className='card-opt-col'>
                    <div className='card-opt'>
                        <div className='center-content' onClick={()=>props.logout()}>
                            <LogoutIcon className='exit-icon'></LogoutIcon>
                            <div className='text-exit'>
                                Salir
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MobileOptions;