import React, {useEffect,useState, useContext} from "react";
import Detail from "../detail/Detail";

import { CSContext } from "../../App";

const Monitor = props =>
{  
    
    const[content,setContent] = useState(<div>Cargando...</div>);
    const{apiPath,setApiPath} = useContext(CSContext) 

    useEffect(
        async () =>
        {
            const payload={projectid:props.projectid};
            const response = await fetch(apiPath+ "getActiveViolations.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const violations = await response.json();
            const alerts = Object.values(violations);
            var tags = [];
            alerts.forEach(element =>{
                tags.push(<Detail beekname={element.beekname} alerts={element.alerts} policies={element.policies}/>);
                //console.log(element.beekname);
            });
            //console.log(violations);
            setContent(tags);
            
        },[]
    );
    
    return(
        <div>
            {content}
        </div>
    );
}
export default Monitor;