import React,{useEffect,useState,useContext} from "react";
import SubTitle from "../subTitle/SubTitle";
import HelpIcon from '@mui/icons-material/Help';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { CSContext } from "../../App";

import "./content.css";
const Info = props =>
{
    const [messagesCount,setMessagesCount] = useState("cargando...");
    const {apiPath, setApiPath} = useContext(CSContext)

    useEffect(
        async () =>
        {
            const payload = {projectid:props.projectid};
            const response = await fetch(apiPath+"getMessagesCount.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const messages = await response.json();
            setMessagesCount(messages.count);
        },[messagesCount]

    );
    return(
        <div>
            <SubTitle section = {"Correos para atención"} size = {"small"}/>
            <a className = "cs-link margin-text" href="mailto:ayudame@cork.com.mx?Subject=SOLICITUD%20AYUDA%20CELARSENS"><HelpIcon className = "inline-icon"></HelpIcon>Ayuda cork</a>
            <SubTitle section = {"Servicios"} size ={"small"}/>
            <div className = "margin-text">
                <WhatsAppIcon className="inline-icon"></WhatsAppIcon>
                Alertas por whatsapp restantes: {messagesCount}
            </div>
        </div>
    );
}
export default Info;


