import React,{useEffect,useState,useContext} from "react";
import { CSContext } from "../../App";


const GraphZone = props =>
{
    const style = (props.modal)?"cs-dateInput":"select-time"
    const [content,setContent] = useState(null);
    const {apiPath} = useContext(CSContext)
    useEffect(
        async () =>
        {
            const payload = {projectid:props.projectid}
            const response = await fetch(apiPath+"getZonesTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const zonesData = await response.json();
            //console.log(zonesData); 
            const zones = zonesData.data;
            let options = zones.map((item,i)=>{
                return(
                    <option value={item["Nombre de la zona"]}>{item["Nombre de la zona"]}</option>
                );
            })
            setContent(options);
        },[]    
    );
    return(
        <div className = "graph-options">
            <select className = {style} disabled={(props.able=="priority")?true:false} onChange={e=>props.zone(e.target.value)}>
                {content}
            </select>
        </div>
    );
}
export default GraphZone;