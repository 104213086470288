import Chart from "react-google-charts";
import "./icons.css";
import "./graphs.css";

const LineChart = props =>
{
    return(
        <div>
            <Chart 
                chartType="LineChart"
                data = {props.data}
                options = {props.options}
            />
        </div>
    );
}
export default LineChart;