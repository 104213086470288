import React,{forwardRef,useState} from "react";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { ThemeProvider, createTheme } from "@mui/material";

import './table.css';
const tableIcons = {
  //FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  //LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  //NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  //PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  //SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  //Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  //Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  //ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

const MultipleBeeksTable = props =>
{ 
  const defaultMaterialTheme = createTheme()
  return(
    <div className = "cs-table">
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          icons={tableIcons}
          options={{
            pageSizeOptions:[],
            selection: true,
            headerStyle:{
              backgroundColor:'#f8f8ff',
              color:'#061528',
              borderBottom:'none',
              zIndex:'0'
            },
            /*rowStyle: {
              backgroundColor:'rgb(84,83,83)',     
              color:'#ffff',
              borderTop:'3px solid #0000',
            },*/
            rowStyle: rowData => ({
              backgroundColor: (rowData["ID del dispositivo"]!=props.id) ? '#f8f8ff' : '#f8f8ff',
              color:'#061528',
              borderTop:'3px solid #0000',
            }),
            searchFieldStyle:{
              color:'#000000'
            }
          }}
          columns={[
            { title: (props.device=="phone")?"Nombre del dispositivo":"ID del dispositivo", field: (props.device=="phone")?"beekname":"beekid"},
            { title: (props.device=="phone")?"ID del dispositivo":"Nombre del dispositivo", field: (props.device=="phone")?"beekid":"beekname"},
          ]} 
          data = {props.data}
          onSelectionChange={(rows) => props.update(rows)}
          title="Beacons"
          style= {{
            backgroundColor:'#f8f8ff',
            border:'none',
            color:'#061528'
          }}
        />
      </ThemeProvider>
    </div>
  );
}
export default MultipleBeeksTable;