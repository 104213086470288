import React,{useState,useEffect} from "react";
import Menu from "../../pages/menu/Menu";
import Celarsens from "../../pages/cs/Celarsens";
import Siocs from "../../pages/siocs/Siocs";

const Distributor = props =>
{
    const [distributorContent, setContent] = useState(null)
    const [context, saveContext] = useState(null)
    useEffect(()=>
    {
        saveContext("cs")
        setContent(
            < Celarsens device = { props.device }
            update = { props.update }
            reload = { props.reload }
            page = { props.page }
            changepage = { props.changepage }
            logout = { props.logout }
            userinfo = { props.userinfo }
            changeproject = { props.changeproject }
        />
        )
    })
    //const changeContext = (view) =>
    /*{
        if(view == "cs")
        {
            saveContext("cs")
            setContent(
                < Celarsens device = { props.device }
                update = { props.update }
                reload = { props.reload }
                page = { props.page }
                changepage = { props.changepage }
                logout = { props.logout }
                userinfo = { props.userinfo }
                changeproject = { props.changeproject }
            />
            )
            return
        }
        if(view == "siocs")
        {
            saveContext("siocs")
            setContent(
                <Siocs 
                    device = { props.device }
                    update = { props.update }
                    reload = { props.reload }
                    page = { props.page }
                    changepage = { props.changepage }
                    logout = { props.logout }
                    userinfo = { props.userinfo }
                    changeproject = { props.changeproject }
                />
            )
            return
        }
    }
    useEffect(
        ()=>
        {
            if((props.userinfo.vmodule == "1") && (props.userinfo.smodule == "1"))
            {
                if(context == null){setContent(<Menu changecontext ={changeContext}/>)}
                if(context == "cs")
                {
                    setContent(
                        < Celarsens device = { props.device }
                            update = { props.update }
                            reload = { props.reload }
                            page = { props.page }
                            changepage = { props.changepage }
                            logout = { props.logout }
                            userinfo = { props.userinfo }
                            changeproject = { props.changeproject }
                        />
                    )
                }
                if(context == "siocs")
                {
                    setContent(
                        <Siocs 
                            device = { props.device }
                            update = { props.update }
                            reload = { props.reload }
                            page = { props.page }
                            changepage = { props.changepage }
                            logout = { props.logout }
                            userinfo = { props.userinfo }
                            changeproject = { props.changeproject }
                        />
                    )
                }
                
                
            }
            else if (props.userinfo.vmodule=="1")
            {
                //alert("v modlue")
                setContent(
                    < Celarsens device = { props.device }
                        update = { props.update }
                        reload = { props.reload }
                        page = { props.page }
                        changepage = { props.changepage }
                        logout = { props.logout }
                        userinfo = { props.userinfo }
                        changeproject = { props.changeproject }
                    />
                )
            }
            else
            {
                setContent(
                    <Siocs 
                        device = { props.device }
                        update = { props.update }
                        reload = { props.reload }
                        page = { props.page }
                        changepage = { props.changepage }
                        logout = { props.logout }
                        userinfo = { props.userinfo }
                        changeproject = { props.changeproject }
                    />
                )
            }
        },[props.page]
    )*/
    return(
        <div>
            {distributorContent}
        </div>
    )
}
export default Distributor;