import {useState,useEffect,useContext} from "react";
import PoliciesTable from "../../tables/PoliciesTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SubTitle from "../../subTitle/SubTitle";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../../App";
import UpdateOptions from "../updateOptions/UpdateOptions";

const UpdatePolicy = props => 
{
    const [selectedPolicy,setSelectedPolicy] = useState([]);
    const [selectedPolicyName,setSelectedName] = useState([]);
    const [policyTable, setPolicyTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const {apiPath} = useContext(CSContext)


    const setPolicyInfo = (id,name) =>
    {
        setSelectedPolicy(id);
        setSelectedName(name);
    }

    useEffect(
        async()=>
        {
            const payload = {projectid:props.projectid};
            const response = await fetch(apiPath+"policiesTable.php",{
              method:"POST",
              body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            console.log(resultResponse);
            var result = resultResponse["data"];
            setPolicyTable(<PoliciesTable device = {props.device} projectid = {props.projectid} setSelected={setPolicyInfo} id={selectedPolicy} data={result}/>)
        },[]
    )
    //{(selectedPolicy.length==0)?<PoliciesTable projectid={props.projectid} setSelected={setPolicyInfo}/>:<UpdateOptions change={setSelectedPolicy} projectid={props.projectid} policyid={selectedPolicy}/>}
    return(
        <div className = {(selectedPolicy.length==0)?"non-margin-view":"view"}>
            
            <div>
                <ArrowBackIcon onClick={()=>(selectedPolicy.length==0)?props.changeView("options"):setSelectedPolicy([])} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
            <label className = "center-title">Actualizar Política</label>
            </div>
            <div>
                {(selectedPolicy.length>0)?<SubTitle section = {"Información de la política"} size ={"small"}/>:null}
            </div>
            {(selectedPolicy.length==0)?policyTable:<UpdateOptions change={setSelectedPolicy} projectid={props.projectid} policyid={selectedPolicy}/>}
        </div>
    );
}
export default UpdatePolicy;