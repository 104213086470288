import React,{useState,useEffect,useContext} from "react";
import Drag from '@mui/icons-material/DragIndicator';
import "./draggablelist.css";
import { CollectionsBookmarkOutlined } from "@material-ui/icons";
import { CSContext } from "../../App";


const DraggableList = props =>
{

    const {apiPath} = useContext(CSContext)
    const [listItems, setItems] = useState([]);
    let src
    const handleDragStart = (e) =>
    {
        console.log(e)
        console.log(e.dataTransfer.effectAllowed)
        e.srcElement.style.opacity = '0.4'
        src = e.srcElement
        e.dataTransfer.effectAllowed = 'move'
        
        e.dataTransfer.setData('text/html',e.srcElement.innerHTML)
    }
    const handleDragEnd = (e) =>
    {
        e.srcElement.style.opacity = '1'
    }

    const handleDragEnter = (e) =>
    {
        e.srcElement.classList.add('over')
    }

    const handleDragLeave = (e) =>
    {
        e.srcElement.classList.remove('over')
    }

    const handleDragOver = (e) =>
    {
        e.preventDefault()
        return false
    }

    const handleDrop = (e) =>
    {
        e.stopPropagation()
        if(src !== e.srcElement)
        {
            
            
            const aux = e.srcElement.index
            e.srcElement.index = src.index
            src.index = aux

            src.innerHTML = e.srcElement.innerHTML
            e.srcElement.innerHTML = e.dataTransfer.getData('text/html')
            let items = document.querySelectorAll('.draggable-container .list-item');
            items.forEach(item=>{
                console.log(item.id)
                item.classList.remove('over');
            })
            console.log("elemento",src)
        }
        return false
    }

    useEffect(()=>
    {

        let items = document.querySelectorAll('.draggable-container .list-item');
        
        items.forEach(item => {
            item.classList.remove('over');
          });

        items.forEach(item=>{
            item.addEventListener('dragstart', handleDragStart);
            item.addEventListener('dragover', handleDragOver);
            item.addEventListener('dragenter', handleDragEnter);
            item.addEventListener('dragleave', handleDragLeave);
            item.addEventListener('dragend', handleDragEnd);
            item.addEventListener('drop', handleDrop);
        });
    },[listItems]
    )

    useEffect(async ()=>
    {
        const payload = {projectid:props.projectid}
        const action = await fetch(apiPath+"getPriorities.php",{
            method:"POST",
            body:JSON.stringify(payload)
        })
        const response = await action.json()
        setItems(response.beeksnames)
    },[]
    )

    return(
        <div className="draggable-container">

            {listItems.map((item,index)=>{
                return <div className="list-item" id={index+1} draggable={true}><Drag></Drag>{item}</div>
            })}
        </div>
    )
}
export default DraggableList;