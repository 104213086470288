
import React, {useState,useEffect} from "react";
import SubTitle from "../subTitle/SubTitle";
import SeenDevices from "../tables/SeenDevices";
import { Skeleton } from "@mui/material";
import "./navigationtab.css";


const StatisticsContent = props =>
{
    const blufiInfo = JSON.parse(props.blufiinfo);
    const [content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />)
    //console.log(blufiInfo.scanDataMap.data);
    useEffect(
        async () =>
        {
            var devices = Object.values(blufiInfo.scanDataMap.data);
            var deviceTableInfo = [];
            devices.forEach(element=>{
                var devicesInfo = {Potencia:element.rssi,Temperatura:element.temp,Estado:element.status,Dispositivo:element.beaconName};
                deviceTableInfo.push(devicesInfo);
            });
            setContent(<SeenDevices data ={deviceTableInfo}/>)
        },[]
    )
    return(
        <div className = "nav-content"> 
        <SubTitle section = {"Estadísticas generales"} size = {"small"}/>
        <label className = "cs-font-label">Control de datos promedio RSSI</label>
        <p className = "cs-font-label-info">{blufiInfo.stat.avgDataCtrlRssi} dB</p>
        <label className = "cs-font-label">Batería cargando:</label>
        <p className = "cs-font-label-info">{(blufiInfo.stat.batteryCharging)?"SI":"NO"}</p>
        <label className = "cs-font-label">Nivel de batería:</label>
        <p className = "cs-font-label-info">{blufiInfo.stat.batteryVoltage} mV</p>
        <label className = "cs-font-label">Temperatura:</label>
        <p className = "cs-font-label-info">{blufiInfo.stat.temperature} °C</p>
        <SubTitle section= {"Estadísticas de red"} size = {"small"}/>
        <label className = "cs-font-label">Conexiones exitosas:</label>
        <p className ="cs-font-label-info">{blufiInfo.stat.conSuccess}</p>
        <label className = "cs-font-label">Conexiones fallidas</label>
        <p className="cs-font-label-info">{blufiInfo.stat.conFail}</p>
        <label className ="cs-font-label">Bytes enviados:</label>
        <p className = "cs-font-label-info">{blufiInfo.stat.sendBytes}</p>
        <label className = "cs-font-label">Bytes recibidos:</label>
        <p className = "cs-font-label-info">{blufiInfo.stat.recvBytes}</p>
        <label className = "cs-font-label">Paquetes enviados:</label>
        <p className = "cs-font-label-info">{blufiInfo.stat.sendPackets}</p>
        <label className = "cs-font-label">Paquetes recibidos</label>
        <p className = "cs-font-label-info">{blufiInfo.stat.recvPackets}</p>
        <SubTitle section = {"Dispositivos"} size = {"small"} />
        <div>
            {content}
        </div>
    </div>
    );
}
export default StatisticsContent;
