import React, {useState,useRef,useEffect,useContext} from 'react';
import ZonesTable from "../../tables/ZonesTable";
import Toast from "../../toast/Toast";
import ZoneUpdateOptions from './suboptions/ZoneUpdateOptions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from '@mui/material';
import { CSContext } from '../../../App';

const UpdateZone = props =>
{
    const {apiPath} = useContext(CSContext)
    const [zoneName,setZoneName] = useState(null);
    const [zoneDesc,setZoneDesc] = useState(null);
    const [newZoneName,setZonenewName] = useState(null);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const [zoneTable,setZoneTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const inputRef = useRef();

    const select = async (name) =>
    {
        const payload ={projectid:props.projectid,zone:name}
        const response = await fetch(apiPath+"getZoneInfo.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const zonedesc = await response.json();
        setZonenewName(name);
        setZoneDesc(zonedesc["description"]);
        setZoneName(name);
    }

    const update = async () =>
    {
        //const zoneDescription = document.getElementById("description").value;
        
        const payload = {
            projectid:props.projectid,
            zonename:zoneName,
            newzonename:newZoneName,
            description:zoneDesc
        };
        console.log(payload)
        const response = await fetch(apiPath+"updateZone.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === "ok")
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Zona Actualizada");
            setTimeout(() => {
                setToastClass("toast-ok");
                setZoneName("");
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("La zona no pudo ser actualizada");
            setTimeout(() => {
                setToastClass("toast-ok");
                setZoneName("");
            },3000);
        }
        //console.log(action);

    }


    /*const ZoneUpdateOptions = () =>
    {
        /*useEffect(() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }, []);
        return(
            <div>
                <label className = "text-option">Nombre de la zona:</label>
                <div><input type="text" className="styled-input" defaultValue ={newZoneName} onChange={e => setZonenewName(e.target.value)}></input></div>
                <label className = "text-option">Descripción:</label>
                <div><textarea className="styled-input custom-ta" id = "description">{zoneDesc}</textarea></div>
                {(newZoneName != null)?<button className = "center-btn-zone" onClick={update}>Actualizar</button>:null}
            </div>
        );
    }*/


    useEffect(
        async () =>
        {
            const payload = {projectid:props.projectid};
            console.log(payload);
            const response = await fetch(apiPath+"getZonesTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            const result = resultResponse["data"]
            setZoneTable(<ZonesTable projectid={props.projectid} select={select} data={result}/>)
        },[]
    )
    return(
        <div className = "non-margin-view">
            <ArrowBackIcon onClick={()=>(zoneName!=null)?props.changeView("options"):setZoneName("")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
            {(zoneName!=null)?<label className = "center-title">{zoneName}</label>:<label className = "center-title">Actualizar zona</label>}
            <p className = "opt-info">
                {(zoneName!=null)?"Selecciona una Zona para actualizarla.":null}   
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            {(zoneName==null)?zoneTable:<ZoneUpdateOptions zoneName={newZoneName} setName={setZonenewName}  zoneDesc={zoneDesc} setDesc={setZoneDesc} update = {update}/>}
        </div>
    );
}
export default UpdateZone;