import React, {useState,useEffect,useContext} from "react";
import PoliciesTable from "../../tables/PoliciesTable";
import MultipleBlufisTable from "../../tables/MultipleBlufisTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../toast/Toast";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../../App";

const DeleteBlufi = props =>
{
    const {apiPath} = useContext(CSContext)
    const [selectedPolicyId,setSelectedId] = useState([]);
    const [selectedPolicyName,setSelectedName] = useState([]);
    const [selectedBlufis,setSelectedBlufis] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const [blufiTable, setBlufiTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const [policyTable, setPolicyTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);

    const setSelected = (policyId,policyName) =>
    {
        setSelectedId(policyId);
        setSelectedName(policyName);
    }

    const update = rows =>
    {
        var blufisids = [];
        rows.forEach(element => {
            blufisids.push(element["blufiid"]);
        });
        setSelectedBlufis(blufisids);
    }

    const eliminate = async () =>
    {
        const payload ={
            projectid:props.projectid,
            policyid:selectedPolicyId,
            deviceids:selectedBlufis
        };
        const response = await fetch(apiPath+"deleteBlufi2policy.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === 204)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Dispositivos eliminados");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedName([]);
                setSelectedBlufis([]);
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Dispositivos NO eliminados");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedName([]);
                setSelectedBlufis([]);
            },3000);
        }
        ////console.log(action);
    }


    ////console.log(selectedPolicyName);
    useEffect(
        async()=>
        {
            const payload = {projectid:props.projectid};
            const response = await fetch(apiPath+"policiesTable.php",{
              method:"POST",
              body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            console.log(resultResponse);
            var result = resultResponse["data"];
            setPolicyTable(<PoliciesTable device = {props.device} projectid = {props.projectid} setSelected={setSelected} id={selectedPolicyId} data={result}/>)
        },[]
    )
    useEffect(
        //after version 2.0.1 when the subprojects was added the data must be load in this part of the code
        async () =>
        {
            const payload = {projectid:props.projectid,policyid:0};
            const response = await fetch(apiPath+"beeksTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            var result = resultResponse["data"];
            var rows = [];
            result.forEach(element =>{
                var projectRow = {"beekname":element["beekname"],"beekid":element["beekid"]};
                rows.push(projectRow);
            });
            setBlufiTable(<MultipleBlufisTable data={rows} device ={props.device} projectid={props.projectid} update={update}/>)
        },[]
    ) 
    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>(selectedPolicyName.length==0)?props.changeView("options"):setSelectedName([])} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                {(selectedPolicyName.length!=0)?<label className = "center-title">{selectedPolicyName}</label>:<label className = "center-title">Eliminar Blufis de una política</label>}    
            </div>
            <p className = "opt-info">
                {(selectedPolicyName.length==0)?"Selecciona una política para eliminar Beacons de ella.":"Selecciona uno o más Beacons."}   
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <div>
                {(selectedBlufis.length!=0)?<button className="center-btn" onClick={eliminate}>Eliminar Blufis</button>:null}
            </div>            
            {(selectedPolicyName.length>0)?blufiTable:policyTable}
        </div>
    );
}
export default DeleteBlufi;