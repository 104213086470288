import { imageListClasses } from "@mui/material";
import React,{useEffect,useState} from "react";
import SubTitle from "../../../subTitle/SubTitle";
import ReactTooltip from "react-tooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 *                 <div>
                    <label className = "text-option">Notificar a los siguientes correos:</label>
                    <div><textarea className="styled-input custom-ta" id ="email" value = {emails} onChange={e=>setmails(e.target.value)}></textarea></div>
                </div>
*/

const Temperature = props =>
{
    
    const [beaconsCheck,setBeaconCheck] = useState(true);
    const [deviceCheck,setDeviceCheck] = useState(true);
    const [maxTemp,setMaxTemp] = useState(null);
    const [minTemp,setMinTemp] = useState(null);
    const [emails,setmails] = useState(null);
    const [phones,setPhones] = useState(null);

    useEffect( () =>
    {
        props.setBtn(true);
        if(props.policy)
        {
            if(props.policy["namespace"] =="BLUFI"){setBeaconCheck(false)}
            if(props.policy["scope"]=="GLOBAL"){setDeviceCheck(false)}
            setMaxTemp(props.policy["thresholdRange"][1]);
            setMinTemp(props.policy["thresholdRange"][0]);
            setmails(props.emails);
            setPhones(props.phones);
        }
    },[]
    );
    return(
        <div>
            <SubTitle section = {"Configuración de la política"} size = {"small"}/>
            <div>
                <div>
                    <label className = "text-option">Tipo de dispositivo</label>
                </div>
                <div>
                    <label className="text-option">Beacon</label>
                    <input type="radio" defaultChecked={beaconsCheck} id = "radioBeacon" name = "radiodevice"></input>
                    <label className="text-option" >Blufi</label>
                    <input id = "radioBlufi" defaultChecked={!beaconsCheck} type="radio" name = "radiodevice"></input>
                </div>
                <div><label className = "text-option">Aplicar política:</label></div>
                <div>
                    
                    <label className="text-option">Todos los dispositivos</label>
                    <input type="radio" id = "radioGlobal" name = "radioscope" defaultChecked={!deviceCheck}></input>
                    <label className="text-option" >Dispositivos especificos</label>
                    <input type="radio" id = "radioIndividual" name = "radioscope" defaultChecked={deviceCheck}></input>
                </div>
                <div>
                    <label className = "text-option">Rangos de temperatura:</label>
                    <div>
                        <label className = "text-option">Temperatura mínima</label>
                        <div><input type="number" className="styled-input" id = "minTemperature" min="-85" placeholder = "0" value={minTemp}></input></div>
                    </div>
                    <div>
                        <label className = "text-option">Temperatura máxima</label>
                        <div><input id = "maxTemperature" type="number" className="styled-input" placeholder = "85" max="85" value={maxTemp}></input></div>
                    </div>
                    <div>
                        <label>Eliminar alertas automaticamente</label>
                        <input type = "checkbox" id = "durationCheck" checked={(props.policy)?props.policy["autoClearEnabled"]:false}></input>
                    </div>

                </div>
                <div>
                    <label className = "text-option">Notificar a los siguientes números:</label>
                    <HelpOutlineIcon 
                        className = "inline-icon"
                        data-tip={"Ingresa números telefónicos con el código de país separados por ',' Ejemplo: 52XXXXXXXX83,52XXXXXXXX90"} 
                    />
                    <ReactTooltip/>
                    <div><textarea className="styled-input custom-ta" id = "sms_whatsapp" defaultValue = {phones} onChange={e=>setPhones(e.target.value)}></textarea></div>
                </div>
            </div>
        </div>
    );
}
export default Temperature;