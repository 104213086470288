import React,{useState} from "react";
import SubTitle from "../../subTitle/SubTitle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../toast/Toast";

const CreateBlufiTemplate = props =>
{


    const [netProtocol,setNetProtocol] = useState("DHCP");
    const [securityPass,setSecurityPass] = useState("WPA");
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");

    const ProtocolOptions = () =>
    {
        return(
            <div>
                <label className = "text-option">Dirección IP:</label>
                <div><input type="text" className="styled-input" id = "ip"></input></div>
                <label className = "text-option">Mascara de red:</label>
                <div><input type="text" className="styled-input" id = "mask"></input></div>
                <label className = "text-option">Dirección DNS:</label>
                <div><input type="text" className="styled-input" id = "dns"></input></div>
                <label className = "text-option">Dirección de la puerta de enlace:</label>
                <div><input type="text" className="styled-input" id = "gateway"></input></div>
            </div>
        );
    }


    const create = async () =>
    {
        var name = document.getElementById('templateName').value;
        var description = document.getElementById('templateDescription').value;
        var networkType = document.getElementById('selectNetwork').value;
        var protocol = document.getElementById('selectProtocol').value;
        var netPassword = document.getElementById('pass').value;
        if(protocol == "STATIC")
        {
            var ipAddress = document.getElementById('ip').value;
            var mask = document.getElementById('mask').value;
            var dns = document.getElementById('dns').value;
            var gateway = document.getElementById('gateway').value;
        }
        var ssid = document.getElementById('networkName').value;
        var security = document.getElementById('selectSecurity').value;
        var ntp = document.getElementById('serverntp').value;
        var host = document.getElementById('hostName').value;
        var port = document.getElementById('port').value;
        var user = document.getElementById('userName').value;
        var password  = document.getElementById('password').value;
        var wifiPolicy = document.getElementById('powerPolicy').value;
        if(document.getElementById('BSSID').value)
        {
            var bssid = document.getElementById('BSSID').value;
        }
        else
        {
            var bssid = null;
        }
        var backOffFact = document.getElementById('backoffFactor').value;
        var minWaitTime = document.getElementById('minimumWaitTime').value;
        var maxWaitTime = document.getElementById('maximumWaitTime').value;
        var failTime = document.getElementById('failureWaitTime').value;
        var wifiMinScan = document.getElementById('minimumScanTime').value;
        var wifiMaxScan = document.getElementById('maximumScanTime').value;
        var wifiDelta = document.getElementById('scanTimeIncrement').value;
        var confirmationTime = document.getElementById('confirmationTime').value;
        var maxRetries = document.getElementById('retries').value;
        var di = document.getElementsByName('antena');
        var valorSeleccionado = "";
        var i = 0;
        for(i=0; i <di.length; i++){
            if(di[i].checked==true) {
                valorSeleccionado = di[i].value;
            }
            if(valorSeleccionado) {
                var antennaSel = valorSeleccionado;
            } 
        }
        var gain = document.getElementById('gain').checked;

        const payload = {        
            projectid:props.projectid,
            name:name,
            description:description,
            networkSelectorTypex:networkType,
            networkProtocolType:protocol,
            passphrase:netPassword,
            ipAddress:ipAddress,
            netMask:mask,
            gatewayAddress:gateway,
            dnsAddress:dns,
            ssid:ssid,
            securityType:security,
            ntpServers:ntp,
            proxyHost:host,
            proxyPort:port,
            proxyUsername:user,
            proxyPassword:password,
            wifiPmPolicytype:wifiPolicy,
            bssid:bssid,
            wifiBackoffFactor:backOffFact,
            wifiMinWaitTime:minWaitTime,
            wifiMaxWaitTime:maxWaitTime,
            wifiConChangeMaxTime:failTime,
            wifiMinScan:wifiMinScan,
            wifiMaxScan:wifiMaxScan,
            wifiDeltaScanTime:wifiDelta,
            wifiConKeptTime:confirmationTime,
            wifiConMaxRetries:maxRetries,
            antennaSel:antennaSel,
            rxGainEnabled:gain
        };
        const response = await fetch("/dashboard/cs/csback/createBlufiTemplate.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === 200)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Plantilla creada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options")
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Plantilla NO creada");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
        }
        //console.log(action);

    }

    return(
        <div className = "view">
            <div>
                <ArrowBackIcon onClick={()=>props.changeView("options")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Crear nueva plantilla</label>
            </div>   
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <SubTitle section = {"Configuraciones generales"} size ={"small"}/>
            <label className = "text-option">Nombre de la plantilla:</label>
            <div><input type="text" className="styled-input" id = "templateName"></input></div>
            <label className = "text-option">Descripción de la plantilla:</label>
            <div><textarea className="styled-input custom-ta" id = "templateDescription"></textarea></div>
            <SubTitle section = {"Tipo de red"} size = {"small"}/>
            <select id = "selectNetwork" className="styled-input custom-select"> 
                <option value = "ANY">WiFi + Ethernet (cuando este disponible)</option>
                <option value = "WIFI">WiFi</option>
                <option value = "ETHERNET">Ethernet</option>
            </select>
            <SubTitle section = {"Protocolo de red"} size = {"small"} />
            <select id = "selectProtocol" className= "styled-input custom-select" onChange={e=>setNetProtocol(e.target.value)}>
                <option value="DHCP">DHCP</option>
                <option value="STATIC">Estatica</option>
            </select>
            {(netProtocol!="DHCP")?<ProtocolOptions />:null}
            <SubTitle section = {"Seguridad"} size = {"small"} />
            <label className = "text-option">Nombre de la red (SSID):</label>
            <div><input type="text" className="styled-input" id = "networkName"></input></div>
            <label className="text-option">Tipo de seguridad:</label>
            <select id = "selectSecurity" className="styled-input custom-select" onChange={e=>setSecurityPass(e.target.value)}>
                <option value = "WPA2">WPA/WPA2</option>
                <option value = "WEP">WEP</option>
                <option value = "OPEN">Abierta</option>
            </select>
            {(securityPass!="OPEN")?<div>
                <label className = "text-option">Password:</label>
                <div><input type="pasword" className="styled-input" id = "pass"></input></div>
            </div>:null}
            <SubTitle section ={"NTP"} size = {"small"} />
            <label className = "text-option">Servidores:</label>
            <div><input type="text" className="styled-input" id = "serverntp"></input></div>
            <SubTitle section = {"Proxy"} size ={"small"}/>
            <label className = "text-option">Host:</label>
            <div><input type="text" className="styled-input" id = "hostName"></input></div>
            <label className = "text-option">Puerto:</label>
            <div><input type="text" className="styled-input" id = "port"></input></div>
            <label className = "text-option">Nombre de usuario:</label>
            <div><input type="text" className="styled-input" id = "userName"></input></div>
            <label className = "text-option">Password:</label>
            <div><input type="text" className="styled-input" id = "password"></input></div>
            <SubTitle section ={"Administración de energía"} />
            <label className = "text-option">Política de administración de energía:</label>
            <select className="styled-input custom-select" id = "powerPolicy">
                <option value = "ALWAYS_ON">Siempre encendida</option>
                <option value = "NORMAL">Normal</option>
            </select>
            <SubTitle section = {"Configuraciones para reconección WiFi"} size = {"small"} />
            <label className = "text-option">BSSID:</label>
            <div><input type="text" className="styled-input" id = "BSSID"></input></div>
            <label className = "text-option">Backoff factor:</label>
            <div><input type="number" className="styled-input" id = "backoffFactor"></input></div>
            <label className = "text-option">Tiempo mínimo de espera:</label>
            <div><input type="number" className="styled-input" id = "minimumWaitTime"></input></div>
            <label className = "text-option">Tiempo máximo de espera:</label>
            <div><input type="number" className="styled-input" id = "maximumWaitTime"></input></div>
            <label className = "text-option">Tiempo de espera para falla:</label>
            <div><input type="number" className="styled-input" id = "failureWaitTime"></input></div>
            <label className = "text-option">Tiempo mínimo de escaneo:</label>
            <div><input type="number" className="styled-input" id = "minimumScanTime"></input></div>
            <label className = "text-option">Tiempo máximo de escaneo:</label>
            <div><input type="number" className="styled-input" id = "maximumScanTime"></input></div>
            <label className = "text-option">Incremento del tiempo de escaneo:</label>
            <div><input type="number" className="styled-input" id = "scanTimeIncrement"></input></div>
            <label className = "text-option">Tiempo de confirmación:</label>
            <div><input type="number" className="styled-input" id = "confirmationTime"></input></div>
            <label className = "text-option">Máximo número de intentos:</label>
            <div><input type="number" className="styled-input" id = "retries"></input></div>
            <SubTitle section = {"Selección de antena"} />
            <div>
                <label className = "text-option">Horizontal</label>
                <input type = "radio" id = "horizontal" value = "HORIZONTAL" name = "antena"></input>
            </div>
            <div>
                <label className = "text-option">Vertical</label>
                <input type = "radio" id = "vertical" value = "VERTICAL" name = "antena"></input>
            </div>
            <div>
                <label className = "text-option">Omni-Direccional</label>
                <input type = "radio" id = "other" value = "OMNI" name = "antena"></input>
            </div>
            <SubTitle section = {"Ganancia de la antena"} size = {"small"}/>
            <label className = "text-option">Incrementar ganancia</label>
            <input type="checkbox" id ="gain"></input>
            <div>
                <button className = "center-btn" onClick={create}>Crear plantilla</button>
            </div>
        </div>
    );
}
export default CreateBlufiTemplate;