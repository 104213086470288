import { useState } from "react";
import Logo from "../../CSLogo.svg";
import ProjectIcon from '@mui/icons-material/BubbleChart';
import MenuIcon from '@mui/icons-material/Menu';
import "./mobilemenu.css";
import Update from "@mui/icons-material/Update";
const Mobilemenu = props =>
{
    const [showMenu,setShowMenu] = useState(true);
    const menu = () =>
    {
        props.showmenu(!showMenu);
        setShowMenu(!showMenu);
    }   
    return(
        <div className="mobile-menu">
            <div className="logo-menu">
                <img src = {Logo} className = "img"></img>
            </div>
            <div className="project-name center-text" onClick={()=>props.modals(true,"Proyectos")}>
                <ProjectIcon className="inline-icon"></ProjectIcon>{props.userinfo.projectname+" - "+props.userinfo.projectid}
            </div>
            <div className="menu-opt center-text">
                <div className="icon-container">
                    <MenuIcon onClick={menu}/>
                </div>
            </div>
        </div>
    );
}
export default Mobilemenu;