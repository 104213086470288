import React,{useState,useEffect,useContext} from "react";
import ZonesTables from "../../tables/ZonesTable";
import MultipleBeeksTable from "../../tables/MultipleBeeksTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toast from "../../toast/Toast";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../../App";
const AddBeek2Zone = props =>
{
    const {apiPath} = useContext(CSContext)
    const [selectedZone,setSelectedZone] = useState("");
    const [selectedbeeks,setSelectedBeeks] = useState([]);
    const [beeksNames,setBeeksNames] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const [zoneTable,setZoneTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);
    const [beekTable, setBeekTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);

    const update = rows =>
    {
        var beeksids = [];
        var beeksnames = []
        rows.forEach(element => {
            beeksids.push(element["beekid"]);
            beeksnames.push(element["beekname"]);
        });
        setSelectedBeeks(beeksids);
        setBeeksNames(beeksnames);
    }
    const add = async () =>
    {
        const payload = {
            projectid:props.projectid,
            devicesid:selectedbeeks,
            devicesname:beeksNames,
            zone:selectedZone
        };
        const response = await fetch(apiPath+"addBeek2Zone.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === 200)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Dispositivos agregados");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedZone("");
            },3000);  
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Dispositivos no agregados");
            setTimeout(() => {
                setToastClass("toast-ok");
                setSelectedZone("");
            },3000);  
        }
        //console.log(action);
    }
    useEffect(
        //after version 2.0.1 when the subprojects was added the data must be load in this part of the code
        async () =>
        {
            const payload = {projectid:props.projectid,policyid:0};
            const response = await fetch(apiPath+"beeksTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            var result = resultResponse["data"];
            var rows = [];
            result.forEach(element =>{
                var projectRow = {"beekname":element["beekname"],"beekid":element["beekid"]};
                rows.push(projectRow);
            });
            console.log(rows)
            setBeekTable(<MultipleBeeksTable data={rows} device ={props.device} update={update}/>)
        },[]
    ) 

    useEffect(
        async () =>
        {
            const payload = {projectid:props.projectid};
            console.log(payload);
            const response = await fetch(apiPath+"getZonesTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            const result = resultResponse["data"]
            setZoneTable(<ZonesTables multi={false} projectid={props.projectid} select={setSelectedZone} data={result}/>)
        },[]
    )
    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>(selectedZone.length==0)?props.changeView("options"):setSelectedZone("")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Agregar beacons a la zona</label>
            </div>
            <p className = "opt-info">
                {(selectedZone.length==0)?"Selecciona una zona para agregar Beacons a ella.":"Selecciona uno o más Beacons."}   
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            {(selectedbeeks.length>0)?<button className = "center-btn-zone" onClick={add}>Agregar</button>:null}
            {(selectedZone.length==0)?zoneTable:beekTable}
        </div>
    );
}
export default AddBeek2Zone;