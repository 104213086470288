import AddCircleIcon from '@mui/icons-material/AddCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UpdateIcon from '@mui/icons-material/Update';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteIcon from '@mui/icons-material/Delete';

/*
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("updatetemplate")}>
                    <UpdateIcon className = "inline-icon-opt"></UpdateIcon>
                    Actualizar plantilla del Blufi.
                </div>
                <p className = "opt-info">
                    Modifica la plantilla del dispositivo para cambiar su comportamiento.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("createtemplate")}>
                    <NoteAddIcon className = "inline-icon-opt"></NoteAddIcon>
                    Crear plantilla.
                </div>
                <p className = "opt-info">
                    Crea una plantilla para Blufis.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("deletetemplate")}>
                    <DeleteIcon className ="inline-icon-opt"></DeleteIcon>
                    Eliminar plantilla.
                </div>
                <p className = "opt-info">
                    Selecciona una o más plantillas para eliminarlas del sistema.
                </p>
            </div>
*/
const ConfBlufisOpt = props =>
{
    return(
        <div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("changename")}>
                    <DriveFileRenameOutlineIcon className = "inline-icon-opt"></DriveFileRenameOutlineIcon>
                    Cambiar el nombre del Blufi.
                </div>
                <p className = "opt-info">
                    Modifica el nombre del dispositivo seleccionado.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("addblufi")}>
                    <AddCircleIcon className = "inline-icon-opt"></AddCircleIcon>
                    Agregar Blufis a una política.
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para agregarlos a una politica
                    y recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt"onClick={()=>props.changeView("deleteblufi")}>
                    <RemoveCircleIcon className = "inline-icon-opt"></RemoveCircleIcon>
                    Eliminar Blufis de una política
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para eliminarlos de una politica y
                    dejar de recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>

        </div>
    );
}
export default ConfBlufisOpt;