import React,{useState,useContext} from "react";
import './Login.css';
import Logo from '../../Logo.svg';
import Hide from '@mui/icons-material/VisibilityOff';
import Show from '@mui/icons-material/Visibility';
import {CSContext} from "../../App"


function Login (props)
{
    const [icon,setIcon] = useState(0);
    const { apiPath, setApiPath  } = useContext(CSContext);
    console.log(apiPath)
    const signIn =  async() =>
    {
        const email = document.getElementById('email').value;
        const pass = document.getElementById('pass').value;
        const payload = {mail:email,password:pass};
        const response = await fetch(apiPath+"verifyLogin.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        //console.log(response)
        const content = await response.json();
        //console.log(content)
        if(!content.successlog)
        {
            alert("Usuario y/o contraseña invalidos");
        }
        else
        {
           
            props.verify(true,content.userInfo);
        }    
    }
    return(
        <div className = "container">
            <div className = "form-signin">
                <img src ={Logo} className = "login-img"/>
                <div>
                    <input id = "email" className = "form-input-email" type = "email" placeholder = "Email"/>
                </div>
                <div>
                    <input id = "pass" className = "form-input-pass" type ={ (icon)?"text":"password"} placeholder="Password"/>
                    {(icon==0)?<Hide className = "icon" onClick={()=>setIcon(1)}></Hide>:<Show className= "icon" onClick={()=>setIcon(0)}></Show>}
                </div>
                <div>
                    <button className = "form-btn" onClick = {signIn}>Acceder</button>
                </div>
                <p className = "cork-info">CelarsenS V2.0.4 &copy;2023 by Cork</p>
            </div>
        </div>
    );
}
export default Login;

