import React, {useEffect,useState, useContext} from "react";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../App";

import Detail from "../detail/Detail";
const Monitor = props =>
{  
    const {apiPath} = useContext(CSContext)
    const[content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={200} />);
    useEffect(
        async () =>
        {
            const payload={projectid:props.projectid};
            const response = await fetch(apiPath+"getActiveViolations.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const violations = await response.json();
            const alerts = Object.values(violations);
            var tags = [];
            alerts.forEach(element =>{
                tags.push(<Detail beekname={element.beekname} alerts={element.alerts} policies={element.policies}/>);
            });
            setContent(tags);
            
        },[]
    );
    
    return(
        <div>
            {content}
        </div>
    );
}
export default Monitor;