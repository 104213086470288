import React,{forwardRef,useState} from "react";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { ThemeProvider, createTheme } from "@mui/material";

import './table.css';
import { create } from "@mui/material/styles/createTransitions";
const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};


const BeeksTemplatesTable = props =>
{
  const defaultMaterialTheme = createTheme()
  let data = async query =>
  {
    var device = 0;
    if(props.blufi)
    {
      device = 1
    }
    const payload = {projectid:props.projectid,device:device};
    const response  = await fetch("/cs/csback/templatesTable.php",{
      method:"POST",
      body:JSON.stringify(payload)
    });
    const resultResponse = await response.json();
    console.log(resultResponse);
    var result = resultResponse["data"];
    var total = resultResponse["total"];
    const searchWord = query.search;
    const order = query.orderDirection;
    var pages = query.page;
    var orderCol = "ID de la plantilla";
    if (typeof query.orderBy !== 'undefined')
    {
      orderCol = query.orderBy.title;
    } 
    if(orderCol === "ID de la plantilla")
    {
      result.sort((a,b)=>{
        if (a["ID de la plantilla"] < b["ID de la plantilla"])
          return (order ==="asc")?-1:1;
        if (a["ID de la plantilla"] > b["ID de la plantilla"])
          return (order ==="asc")?1:-1;
        return 0;
      });
    }
    if(orderCol === "Nombre de la plantilla")
    {
      result.sort((a,b)=>{
        if (a["Nombre de la plantilla"] < b["Nombre de la plantilla"])
          return (order ==="asc")?-1:1;
        if (a["Nombre de la plantilla"] > b["Nombre de la plantilla"])
          return (order ==="asc")?1:-1;
        return 0;
      });
    }      
    result = result.filter((item) =>{
      if(item["Nombre de la plantilla"].toLowerCase().includes(searchWord.toLowerCase()))
      {
        return item["Nombre de la plantilla"];
      }
    }); 
    result = result.slice((5*pages),(5+(5*pages)));
    return {
      data: result,
      page: pages,// current page number
      totalCount:total // total row number
    }
    
  }
  return(
    <div className = "cs-table">
      <ThemeProvider theme ={defaultMaterialTheme}>
        <MaterialTable
          icons={tableIcons}
          options={{
            pageSizeOptions:[],
            headerStyle:{
              backgroundColor:'#f8f8ff',
              color:'#061528',
              borderBottom:'none',
              zIndex:'0'
            },
            rowStyle: {
              backgroundColor:'#f8f8ff',     
              color:'#061528',
              borderTop:'3px solid #0000',
            },
            searchFieldStyle:{
              color:'#000000'
            }
          }}
          columns={[
            { title: "ID de la plantilla", field: "ID de la plantilla"},
            { title: "Nombre de la plantilla", field: "Nombre de la plantilla" },
          ]} 
          data = {data}
          onRowClick={((evt, selectedRow) => props.update(selectedRow["Nombre de la plantilla"],selectedRow["ID de la plantilla"]))}//Utilizar este apartado para cambiar el estado de la app y cambiar de pagina
          title="Plantillas"
          style= {{
            backgroundColor:'#f8f8ff',
            border:'none',
            color:'#061528'
          }}
          />
      </ThemeProvider>
    </div>
  );
}
export default BeeksTemplatesTable;