import React, { useState, useEffect, useRef } from 'react';
import "./multiselect.css";

const MotorSelect = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('Buscar motor');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(async () => {
    const basePath = `https://celarsens-storage.s3.us-west-1.amazonaws.com/${props.projectid}/lineChart.json`;
    const response = await fetch(basePath);
    const chartData = await response.json();

    const priorityResponse = await fetch(`https://celarsens-storage.s3.us-west-1.amazonaws.com/${props.projectid}/priorities.json`);
    const priorities = await priorityResponse.json();

    let index = 1;
    const motorOptions = Object.keys(priorities).map((key) => {
      return { label: key, value: priorities[key] };
    });

    const lines = [0, ...motorOptions.slice(0, 3).map((option) => option.value)];

    props.setLines(lines);
    setFilteredOptions(motorOptions);
    setOptions(motorOptions.slice(0, 3));
    setSelectedOptions(motorOptions.slice(0, 3));
  }, []);

  useEffect(() => {
    const values = selectedOptions.map((item) => item.value);
    const lines = [0, ...values];
    props.setLines(lines);
  }, [selectedOptions]);

  const handleOptionClick = (option) => {
    if (selectedOptions.some((item) => item.value === option.value)) {
      setSelectedOptions(selectedOptions.filter((item) => item.value !== option.value));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterOptions(event.target.value);
  };

  const filterOptions = (searchValue) => {
    if (!searchValue) {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()));
      setFilteredOptions(filtered);
    }
  };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="graph-options">
      <button onClick={handleDropdownClick} className="motor-select">
        {selectedOptions.length === 0 ? 'Seleccionar motores' : `${selectedOptions.length} motores seleccionados`}
      </button>
      {isOpen && (
        <div className="motor-select-menu">
          <div className="search-cont">
            <input
              type="text"
              placeholder="Buscar"
              value={searchTerm}
              onChange={handleSearchChange}
              className="searching"
            />
          </div>
          <ul>
            {filteredOptions.map((option) => (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={selectedOptions.some((item) => item.value === option.value) ? 'selected-motor' : 'unselected-motor'}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MotorSelect;
