import React, { useEffect, useState, useContext, createContext } from 'react';
import Login from './components/login/Login';
import Celarsens from './pages/cs/Celarsens';
import Menu from './pages/menu/Menu';
import Distributor from './components/distributor/Distributor';


export const CSContext = createContext()

const Provider = ({children}) =>
{
    const [apiPath,setApiPath] = useState("/dashboard/cs/csback/")
    return(
        <CSContext.Provider value={{apiPath,setApiPath}}>
            {children}
        </CSContext.Provider>
    )
}

function App() {
    
    const apiPath = "/dashboard/cs/csback/"
    const [signedIn, setsignedIn] = useState(sessionStorage.getItem('sign'));
    const [page, setPage] = useState("dashboard");
    const [userData, setUserData] = useState((sessionStorage.getItem('sign')) ? JSON.parse(sessionStorage.getItem("userData")) : { "username": null, "role": null, "projectname": null, "projectid": null,"vmodule":null, "smodule":null });
    const [reload, setReload] = useState(false);
    const [device, setDevice] = useState("desktop");
    const [pageContent,setContent] = useState(<Menu />)
//    console.log(signedIn);

    useEffect(() => {
        document.title = "celarsens"
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setReload(!reload);
        }, 300000);
    });

    useEffect (()=>
    {
        //console.log(window.location.href);
        window.addEventListener("beforeunload", function (e) {
            //var confirmationMessage = "\o/";
            //var confirmationMessage = "Estas a punto de abondar CelarsenS.";
            //(e || window.event).returnValue = confirmationMessage; //Gecko + IE
            //return confirmationMessage;
            e.preventDefault();
            e.returnValue = '';
            //window.history.pushState(null, "", window.location.href);                            //Webkit, Safari, Chrome
          });
    })
    const alertUser = (e) => {
        setReload(!reload);
    };
    useEffect(() => {
        const windowSize = window.matchMedia("(max-device-width: 480px)");
        setDevice((windowSize.matches) ? "phone" : "desktop");
    },[]);
    const signIn = (successLogin, userInfo) => {
        setUserData(userInfo);
        sessionStorage.setItem('userData', JSON.stringify(userInfo));
        sessionStorage.setItem("sign", successLogin);
        //console.log(sessionStorage.getItem("sign"));
        setsignedIn(sessionStorage.getItem("sign"));
    }

    const logout = () => {
        sessionStorage.clear();
        setUserData({ "username": null, "role": null, "projectname": null, "projectid": null, "vmodule":null, "smodule":null});
        setsignedIn(0)
    }

    const changePage = (pageName) => {
        setPage(pageName);
        setReload(!reload);
    }

    let changeProject = async(val) => {

        const payload = { projectid: val["ID del proyecto"] }
        const response = await fetch(apiPath+"changeProject.php", {
            method: "POST",
            body: JSON.stringify(payload)
        });
        const project = await response.json();
        if (project["status"] == "ok") {
            const newUserdata = { "username": userData.username, "role": userData.role, "projectname": val["Nombre del proyecto"], "projectid": val["ID del proyecto"] };
            sessionStorage.setItem('userData', JSON.stringify(newUserdata));
            setUserData(newUserdata);
            setReload(!reload);
        }
    }

    /*return (
        signedIn ? < Celarsens device = { device }
        update = { setReload }
        reload = { reload }
        page = { page }
        changepage = { changePage }
        logout = { logout }
        userinfo = { userData }
        changeproject = { changeProject }
        />: <Login verify = {signIn}/ >
    );*/

    return(
        signedIn ? <Provider>
            <Distributor userinfo = {userData} 
                update = {setReload}
                reload = {reload}
                page = {page}
                changepage = {changePage}
                logout = {logout}
                changeproject = {changeProject}
                device = {device}/>
            </Provider>:
            <Provider>
                <Login verify = {signIn} />
            </Provider>
    )
}

export default App;