import React from 'react';
import SensorsIcon from '@mui/icons-material/Sensors';
import BoltIcon from '@mui/icons-material/Bolt';
import Logo from '../../Logo.svg';
import "./menu.css";

const Menu = props =>
{
    return(
        <div className='menu-container'>
            <img src ={Logo} className = "cs-img"/>
            <div className='menu-opt-container'>
                <div className='card-menu-opt' onClick={()=>props.changecontext("cs")}>
                    <div className='icon-container'>
                        <SensorsIcon fontSize='inherit'/>
                    </div>
                    Control de vibración y temperatura.
                </div>
                <div className='card-menu-opt' onClick={()=>props.changecontext("siocs")}>
                    <div className='icon-container'>
                        <BoltIcon fontSize='inherit'/>
                    </div>
                    Control de energia
                </div>
            </div>
        </div>
    )
}
export default Menu;