import React,{useEffect,useState} from "react";
import SubTitle from "../../../subTitle/SubTitle";
import ReactTooltip from "react-tooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 *                 <div>
                    <label className = "text-option">Notificar a los siguientes correos:</label>
                    <div><textarea className="styled-input custom-ta" id ="email" value = {emails} onChange={e=>setmails(e.target.value)}></textarea></div>
                </div>
*/
const Motion = props =>
{
    const [beaconsCheck,setBeaconCheck] = useState(true);
    const [deviceCheck,setDeviceCheck] = useState(true);
    const [movementState,setMovementState] = useState(false);
    const [windowInterval,setwindowInterval] = useState(null);
    const [tolerance,setTolerance] = useState(null);
    const [emails,setmails] = useState(null);
    const [phones,setPhones] = useState(null);
    useEffect( () =>
    {
        props.setBtn(true);
        if(props.policy)
        {
            if(props.policy["namespace"] =="BLUFI"){setBeaconCheck(false)}
            if(props.policy["scope"]=="GLOBAL"){setDeviceCheck(false)}
            if(props.policy["operatorType"]=="MOVEMENT"){setMovementState(true)}
            setwindowInterval(props.policy['sampleInterval']);
            setTolerance(props.policy['threshold']);
            setmails(props.emails);
            setPhones(props.phone);
        }
    },[]
    );
    return(
        <div>
            <SubTitle section = {"Configuración de la política"} size = {"small"}/>
            <div>
                <div>
                    <label className = "text-option">Tipo de dispositivo</label>
                </div>
                <div>
                    <label className="text-option">Beacon</label>
                    <input type="radio" checked={beaconsCheck} id = "radioBeacon" name = "radiodevice" onClick={()=>setBeaconCheck(true)}></input>
                    <label className="text-option">Blufi</label>
                    <input type="radio" id = "radioBlufi" name = "radiodevice" checked={!beaconsCheck} onClick={()=>setBeaconCheck(false)}></input>
                </div>
                <div>
                    <div>
                        <label className = "text-option">Estado de alerta</label>
                    </div>
                    <div>
                        <label className="text-option">Al descanso</label>
                        <input type="radio" checked={!movementState} id = "radioRest" name = "radiostate" onClick={()=>setMovementState(false)}></input>
                        <label className="text-option">En movimiento</label>
                        <input type="radio" id = "radioMotion" name = "radiostate" checked={movementState} onClick={()=>setMovementState(true)}></input>
                    </div>
                </div>
                <div><label className = "text-option">Aplicar política:</label></div>
                <div>
                    
                    <label className="text-option">Todos los dispositivos</label>
                    <input type="radio" id = "radioGlobal" name = "radiox" checked={!deviceCheck} onClick={()=>setDeviceCheck(false)}></input>
                    <label className="text-option" >Dispositivos especificos</label>
                    <input type="radio" checked={deviceCheck} id = "radioIndividual" name = "radiox" onClick={()=>setDeviceCheck(true)}></input>
                </div>
                <div>
                    <label className="text-option">Duración:</label>
                    <select id = "duration_Motion" type="text" className="styled-input custom-select">
                        <option value ={1}>1 segundo</option>
                        <option value ={10}>10 segundos</option>
                        <option value ={30}>30 segundos</option>
                        <option value ={60}>1 minuto</option>
                        <option value ={120}>2 minutos</option>
                        <option value ={180}>3 minutos</option>
                        <option value ={240}>4 minutos</option>
                        <option value ={300}>5 minutos</option>
                        <option value ={600}>10 minutos</option>
                        <option value ={1800}>30 minutos</option>
                        <option value ={3600}>1 hora</option>
                        <option value ={21600}>6 horas</option>
                        <option value ={43200}>12 horas</option>
                        <option value ={86400}>1 día</option>
                    </select>
                </div>
                <div>
                    <label className = "text-option">Tiempo para limpiar la alerta:</label>
                    <select type="text" className="styled-input custom-select" id ="durationSelectClear">
                        <option value ={1}>1 segundo</option>
                        <option value ={10}>10 segundos</option>
                        <option value ={30}>30 segundos</option>
                        <option value ={60}>1 minuto</option>
                        <option value ={120}>2 minutos</option>
                        <option value ={180}>3 minutos</option>
                        <option value ={240}>4 minutos</option>
                        <option value ={300}>5 minutos</option>
                        <option value ={600}>10 minutos</option>
                        <option value ={1800}>30 minutos</option>
                        <option value ={3600}>1 hora</option>
                        <option value ={21600}>6 horas</option>
                        <option value ={43200}>12 horas</option>
                        <option value ={86400}>1 día</option>
                    </select>
                </div>
                <div>
                    <div>
                        <label className = "text-option">Motion window interval</label>
                        <div><input type="number" className="styled-input" placeholder="0" min="-85" id = "motionWindowInterval" value={windowInterval}></input></div>
                    </div>
                    <div>
                        <label className = "text-option">Motion window tolerance</label>
                        <div><input id = "motionWindowToleranceValue" type="number" className="styled-input" placeholder = "85" max="85" value={tolerance}></input></div>
                    </div>
                </div>
                <div>
                    <label className = "text-option">Notificar a los siguientes números:</label>
                    <HelpOutlineIcon 
                        className = "inline-icon"
                        data-tip={"Ingresa números telefónicos con el código de país separados por',' Ejemplo: 52XXXXXXXX83,52XXXXXXXX90"} 
                    />
                    <ReactTooltip/>
                    <div><textarea className="styled-input custom-ta" id = "sms_whatsapp" defaultValue = {phones} onChange = {e=>setPhones(e.target.value)}></textarea></div>
                </div>
            </div>
        </div>
    );
}
export default Motion;