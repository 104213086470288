import React,{forwardRef,useEffect,useState} from "react";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { ThemeProvider, createTheme } from "@mui/material";

import './table.css';
const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

const DeviceViolation = props =>
{
  const defaultMaterialTheme = createTheme()
  return(
    <div>
      <ThemeProvider theme = {defaultMaterialTheme}> 
        <MaterialTable
          icons={tableIcons}
          options={{
            pageSizeOptions:[],
            headerStyle:{
              backgroundColor:'#f8f8ff',
              color:'#061528',
              borderBottom:'none',
              zIndex:'0'
            },
            rowStyle: {
              backgroundColor:'#f8f8ff',     
              color:'#061528',
              borderTop:'3px solid #0000',
            },
            searchFieldStyle:{
              color:'#000000'
            }
          }}
          columns={[
            {title:"Nombre de la política", field: "Nombre de la politica"},
            {title:"Tipo de política", field: "Tipo de politica" },
            {title:"Fecha", field:"Fecha"},
          ]} 
          data = {props.data}
          //onRowClick={((evt, selectedRow) => props.select("beacon",selectedRow.beekid,selectedRow.beekname))}//Utilizar este apartado para cambiar el estado de la app y cambiar de pagina
          title="Alertas activas"
          style= {{
            backgroundColor:'#f8f8ff',
            border:'none',
            color:'rgb(255,255,255)'
          }}
          />
      </ThemeProvider>
    </div>
  );
}
export default DeviceViolation;