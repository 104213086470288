import React,{useContext} from "react";
import { CSContext } from "../../App";
import "./content.css";
const ReportMov = props =>
{
    const {apiPath} = useContext(CSContext)
    const getReport = async() =>
    {
        const startdate = document.getElementById("startdateMov").value
        const enddate = document.getElementById("enddateMov").value
        alert("Gerando reporte, esto puede tardar algunos minutos")
        const payload = {
            projectid:props.projectid,
            startdate:startdate,
            enddate:enddate
        }
        const response = await fetch(apiPath+"createMovReport.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const report = await response.json()
        if(report.status === "ok")
        {
            var download = document.createElement("a");
            download.href = apiPath+"downloadReport.php";
            download.style.display = "none";
            document.body.appendChild(download);
            download.click();
            document.body.removeChild(download);
        }
        else
        {
            alert("Estamos teniendo problemas para generar el reporte")
        }
    }
    return(
        <div>
            <div>
                <span className="margin-span">
                    <label>Inicio: </label>
                    <input type="date" id = "startdateMov"></input>
                </span>
                <span className="margin-span">
                    <label>Final: </label>
                    <input type="date" id = "enddateMov"></input>
                </span>
            </div>
            <div className="btn" onClick={()=>getReport()}>
                Crear reporte
            </div>
        </div>
    );
}
export default ReportMov;