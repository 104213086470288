import AddCircleIcon from '@mui/icons-material/AddCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UpdateIcon from '@mui/icons-material/Update';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import LowPriorityIcon from '@mui/icons-material/LowPriority';


/*<div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("updatebeektemplate")}>
                    <UpdateIcon className = "inline-icon-opt"></UpdateIcon>
                    Actualizar plantilla del Beacon.
                </div>
                <p className = "opt-info">
                    Modifica la plantilla del dispositivo para cambiar su comportamiento.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("clonetemplate")}>
                    <ContentCopyIcon className = "inline-icon-opt"></ContentCopyIcon>
                    Clonar plantilla.
                </div>
                <p className = "opt-info">
                    Crea una copia de una plantilla prestablecida.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("clonetemplate")}>
                    <UploadFileIcon className = "inline-icon-opt"></UploadFileIcon>
                    Modificar plantilla.
                </div>
                <p className = "opt-info">
                    Selecciona una plantilla para modificar sus parametros.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("deletetemplate")}>
                    <DeleteIcon className ="inline-icon-opt"></DeleteIcon>
                    Eliminar plantilla.
                </div>
                <p className = "opt-info">
                    Selecciona una o más plantillas para eliminarlas del sistema.
                </p>
            </div>                   
            */


const ConfBeeksOpt =  props =>
{
    return(
        <div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("changename")}>
                    <DriveFileRenameOutlineIcon className = "inline-icon-opt"></DriveFileRenameOutlineIcon>
                    Cambiar el nombre del Beacon.
                </div>
                <p className = "opt-info">
                    Modifica el nombre del dispositivo seleccionado.
                </p>
            </div>
            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("addbeek")}>
                    <AddCircleIcon className = "inline-icon-opt"></AddCircleIcon>
                    Agregar Beacons a una política.
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para agregarlos a una politica
                    y recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>
            <div className = "opt-container" onClick={()=>props.changeView("deletebeek")}>
                <div className = "opt">
                    <RemoveCircleIcon className = "inline-icon-opt"></RemoveCircleIcon>
                    Eliminar Beacons de una política
                </div>
                <p className = "opt-info">
                    Selecciona distintos dispositivos para eliminarlos de una politica y
                    dejar de recibir notificaciones cuando se genere alguna alerta.
                </p>
            </div>
            

            <div className = "opt-container">
                <div className = "opt" onClick={()=>props.changeView("updatepriority")}>
                    <LowPriorityIcon className = "inline-icon-opt"></LowPriorityIcon>
                    Cambiar la prioridad del Beacon.
                </div>
                <p className = "opt-info">
                    Selecciona un dispositivo para poder cambiar su prioridad y poder visualizarlo antes en "Dashboard".
                </p>
            </div>
        </div>
    );
}
export default ConfBeeksOpt;