import React from "react";

//import "./navigationtab.css";

const NavigationHeader = props =>
{
    return(
        <div className = "nav-header">
            <div className = "nav-header-option" onClick={()=>props.change("violations")}>Violaciones</div> 
            <div className = "nav-header-option" onClick={()=>props.change("general")}>General</div>
            <div className = "nav-header-option" onClick={()=>props.change("statistics")}>Estadísticas</div>
        </div>
        );
}
export default NavigationHeader;