import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TimelineIcon from '@mui/icons-material/Timeline';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import SubTitle from "../../subTitle/SubTitle";

const ReportMenu = props =>
{
    const changePage = (page) =>
    {
        props.show(false,null);
        props.change(page);
    }
    const openModal = (show,content) =>
    {
        props.show(false,null)
        props.modal(show,content)
    }
    const getReport = async(reportType) =>
    {
        const period = document.getElementById("report-period").value;
        if(period!="custom")
        {
            //https://celarsens-storage.s3.us-west-1.amazonaws.com/78687/temperatura-semanal-78687.csv
            const basePath = "https://celarsens-storage.s3.us-west-1.amazonaws.com/";
            //const basePath = "/reports/"
            const bucket = props.projectid;
            const fileType = (reportType=="temp")?"temperatura":"movimiento";
            let filename;
            switch (period) {
                case "week":
                    filename = fileType+"-semanal-"+props.projectid+".csv";
                    break;
                case "month":
                    filename = fileType+"-mensual-"+props.projectid+".csv";
                    break;
                case "month2":
                    filename = fileType+"-bimestral-"+props.projectid+".csv";
                    break;
                case "month3":
                    filename = fileType+"-trimestral-"+props.projectid+".csv";
                    break;
                default:
                    break;
            }
            const url = basePath+bucket+"/"+filename;
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }
        else
        {
            const rtype = (reportType=="temp")?"Reptemp":"Repmov"
            props.modal(true,rtype)
        }
    }

    return(
        <div className="container-options">
            <select className='sidemenu-select' id = "report-period">
                <option value = "week">Semanal</option>
                <option value = "month">Mensual</option>
                <option value = "month2">Bimestral</option>
                <option value = "month3">Trimestral</option>
                <option value = "custom">Custom</option>
            </select>
            <div className="menu-option" onClick={()=>getReport("temp")}>Temperatura</div>
            <div className="menu-option" onClick={()=>getReport("mov")}>Movimiento</div>
        </div>
    );
}
export default ReportMenu;