import React,{useState,useEffect,useContext} from "react";
import ZonesTables from "../../tables/ZonesTable";
import Toast from "../../toast/Toast";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from "@mui/material";
import { CSContext } from "../../../App";

const DeleteZone = props =>
{
    const {apiPath} = useContext(CSContext)
    const [selectedZones,setSelectedZones] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok");
    const [toastContent,setToastContent] = useState("");
    const [zoneTable,setZoneTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);

    const update = rows =>
    {
        var zones = [];
        rows.forEach(element => {
            zones.push(element["Nombre de la zona"]);
        });
        setSelectedZones(zones);
    }
    const eliminate = async () =>
    {
        const payload = {
            projectid:props.projectid,
            zones:selectedZones
        };
        const response = await fetch(apiPath+"deleteZone.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        const action = await response.json();
        if(action.status === "ok")
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Zonas eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
            
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("La zonas no pudieron ser eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
        }
        //console.log(action);
    }
    useEffect(
        async () =>
        {
            const payload = {projectid:props.projectid};
            console.log(payload);
            const response = await fetch(apiPath+"getZonesTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            const result = resultResponse["data"]
            setZoneTable(<ZonesTables projectid={props.projectid} multi={true} update={update} data={result}/>)
        },[]
    )
    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>props.changeView("options")} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                <label className = "center-title">Eliminar zonas</label>
            </div>
            <p className = "opt-info">
                Selecciona una o más zonas para eliminarlas del sistema.
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            {(selectedZones.length==0)?null:<button className="center-btn-zone" onClick ={eliminate}>Eliminar</button>}
            {zoneTable}
        </div>
    );
}
export default DeleteZone;