import React,{useState,useEffect,useContext} from "react";
import BeaconsConnected from '@mui/icons-material/BluetoothConnected';
import ReactTooltip from "react-tooltip";
import { CSContext } from "../../App";
import "../topbar/topbar.css";

const ConectedBeeks = props =>
{
    const {apiPath} = useContext(CSContext)
    const [conectedDevices,setConectedDevices] = useState(null);
    const [totalDevices,setTotalDevices] = useState(null);
    const [status,setStatus] = useState("ok");
    useEffect(
        async() =>
        {
            const payload = {projectid:props.projectid}
            const response = await fetch(apiPath+"getConectedBeeks.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            const devices = await response.json();
            const porcentaje= (devices.activedevices / devices.totaldevices)*100;
            const intPorcentaje = Math.round( porcentaje );
            if(intPorcentaje == 100)
            {
                setStatus("ok");
            }
            if(intPorcentaje < 100)
            {
                setStatus("warning");
            }
            if(intPorcentaje <= 30)
            {
                setStatus("error");
            }

            setConectedDevices(devices.activedevices);
            setTotalDevices(devices.totaldevices)
        },[props.reload]       
    );
    return(
        <span className="conected-container">
            <BeaconsConnected 
                className = {"inline-icon "+status}
                data-tip={"Beacons conectados:"+conectedDevices+"/"+totalDevices}
            >          
            </BeaconsConnected>
            <ReactTooltip/>
        </span>
    );
}
export default ConectedBeeks;