import React,{useState,useEffect,useContext} from "react";
import SubTitle from "../../components/subTitle/SubTitle";
import BeeksTable from "../../components/tables/BeeksTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GraphView from "../../components/graphs/views/GraphView";
import Switch from "../../components/switch/Switch";
import { Skeleton } from "@mui/material";
import { CSContext } from "../../App";

import "./graphs.css";


const Graphs = props =>
{
    const [beekId,setBeekId] = useState("");
    const [beekName,setBeekName] = useState("");
    const [view,setView] = useState((props.device == "phone")?true:false);
    const {apiPath} = useContext(CSContext)

    const select = (nullvar,id,name) =>
    {
        const ignore = nullvar;
        setBeekName(name);
        setBeekId(id);
    }
    const [content,setContent] = useState(<Skeleton variant="rectangular" width={"99%"} height={400} />);
    useEffect(
        //after version 2.0.1 when the subprojects was added the data must be load in this part of the code
        async () =>
        {
            const payload = {projectid:props.projectid,policyid:0};
            const response = await fetch(apiPath+"beeksTable.php",{
                method:"POST",
                body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            console.log(resultResponse)
            var result = resultResponse["data"];
            var rows = [];
            result.forEach(element =>{
                var projectRow = {"beekname":element["beekname"],"beekid":element["beekid"]};
                rows.push(projectRow);
            });
            setContent(<BeeksTable data={rows} select={select} userinfo={props.user}/>)
        },[]
    );
    
    return(
        <div className = "graphs">
            <SubTitle section = {(beekName.length==0)?"Selecciona un dispositivo":beekName}/>
            {(beekName.length==0)?null:<ArrowBackIcon className ="inline-icon-opt back-opt-btn" onClick={()=>setBeekName("")}></ArrowBackIcon>}
            <div className="actions view-action">
                {(beekName.length==0)?null:<Switch device = {props.device} change={setView} text={"Vista completa "}/>}
            </div>
            {(beekName.length==0)?content:<GraphView id={beekId} projectid ={props.projectid} view={view}/>}
        </div>
    );
}
export default Graphs;