import React from "react";

import './modal.css';

const Modal = props =>
{
    if (!props.show)
    {
        return null;
    }
    else
    {
        return(
            <div className = "modal">
                <div className = "modal-content">
                    <div className = "modal-header">
                        <span className = "modal-title">
                            {props.title}
                        </span>
                        <span className = "close-btn" onClick={()=> props.autoclose(false)}>
                            <strong >
                                &times;
                            </strong>
                        </span>
                    </div>
                    <div className = "modal-body">
                        {props.content}
                    </div>
                    <div className = "modal-footer">
                        
                    </div>
                </div>
            </div>
        );
    }
}
export default Modal;