import React from "react";
import SubTitle from "../subTitle/SubTitle";

const GeneralContent = props =>
{
    const beekInfo = props.beekInfo;
    //console.log(beekInfo.name);
    return(
        <div className = "nav-content">
            <SubTitle section={"Información general del Beacon"} size={"small"}/>
            <div>
                <label className = "cs-font-label">Nombre del dispositivo:</label>
                <p className = "cs-font-label-info">{beekInfo.name}</p>
            </div>
            <div>
                <label className = "cs-font-label">ID del dispositivo:</label>
                <p className = "cs-font-label-info">{beekInfo.deviceId}</p>
            </div>
            <SubTitle section ={"Configuraciones generales"} size={"small"} />
            <div>
                <label className = "cs-font-label">Tipo de dispositivo:</label>
                <p className = "cs-font-label-info">{beekInfo.deviceType.name}</p>
            </div>
            <div>
                <label className = "cs-font-label">Nombre de la plantilla seleccionada:</label>
                <p className = "cs-font-label-info">{beekInfo.configuration.name}</p>
            </div>
            <div>
                <label className = "cs-font-label">Descripción de la plantilla seleccionada:</label>
                <p className = "cs-font-label-info">{beekInfo.configuration.description}</p>
            </div>
            <SubTitle section ={"Firmware"} size ={"small"} />
            <div>
                <label className = "cs-font-label">Versión del firmware:</label>
                <p className = "cs-font-label-info">{beekInfo.bleFirmware.version}</p>
            </div>
            <div>
                <label className = "cs-font-label">Tipo de firmware:</label>
                <p className = "cs-font-label-info">{beekInfo.bleFirmware.type}</p>
            </div>
            <div>
                <label className = "cs-font-label">Dirección MAC:</label>
                <p className = "cs-font-label-info">{beekInfo.macAddress}</p>
            </div>
        </div>
    );
}
export default GeneralContent;