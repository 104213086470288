import React, {useState,useEffect,useContext} from "react";
import MultiplePoliciesTable from "../../tables/MultiplePoliciesTable";
import Toast from "../../toast/Toast";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from "@mui/material";
import { CSContext } from "../../../App";

const DeletePolicy = props =>
{
    const {apiPath} = useContext(CSContext)
    const [selectedPolicyId,setSelectedId] = useState([]);
    const [selectedPolicyName,setSelectedName] = useState([]);
    const [selectedPolicies,setSelectedPolicies] = useState([]);
    const [toastClass,setToastClass] = useState("toast-ok ");
    const [toastContent,setToastContent] = useState("");
    const [policyTable, setPolicyTable] = useState(<Skeleton variant="rectangular" width={"99%"} height={250} />);

    const setSelected = (policyId,policyName) =>
    {
        setSelectedId(policyId);
        setSelectedName(policyName);
    }

    const update = rows =>
    {
        var policiesids = [];
        rows.forEach(element => {
            policiesids.push(element["ID de la politica"]);
        });
        setSelectedPolicies(policiesids);
        ////console.log(selectedPolicies);
    }
    
    const eliminate = async () =>
    {
        alert("Espera mientras se eliminan las políticas");
        const payload = {
            projectid:props.projectid,
            deletedItems:selectedPolicies
        };
        const response = await fetch(apiPath+"deletePolicies.php",{
            method:"POST",
            body:JSON.stringify(payload)
        });
        
        const action = await response.json();
        if(action.status === 200)
        {
            setToastClass("toast-ok toast-show");
            setToastContent("Políticas eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options")
            },3000);
        }
        else
        {
            setToastClass("toast-wrong toast-show");
            setToastContent("Políticas NO eliminadas");
            setTimeout(() => {
                setToastClass("toast-ok");
                props.changeView("options");
            },3000);
        }
        //console.log(action);
    }
    useEffect(
        async()=>
        {
            const payload = {projectid:props.projectid};
            const response = await fetch(apiPath+"policiesTable.php",{
              method:"POST",
              body:JSON.stringify(payload)
            });
            var resultResponse = await response.json();
            console.log(resultResponse);
            var result = resultResponse["data"];
            setPolicyTable(<MultiplePoliciesTable device = {props.device} projectid = {props.projectid} update = {update} data={result}/>)
        },[]
    )
    return(
        <div className = "non-margin-view">
            <div>
                <ArrowBackIcon onClick={()=>(selectedPolicyName.length==0)?props.changeView("options"):setSelectedName([])} className = "inline-icon-opt back-opt-btn" ></ArrowBackIcon>
                {(selectedPolicyName.length!=0)?<label className = "center-title">{selectedPolicyName}</label>:<label className = "center-title">Eliminar políticas</label>}    
            </div>
            <p className = "opt-info">
                Selecciona una o más políticas para eliminarlas del sistema.   
            </p>
            <Toast toastClass={toastClass} toastContent={toastContent}/>
            <div>
                {(selectedPolicies.length!=0)?<button onClick={eliminate} className="center-btn">Eliminar políticas</button>:null}
            </div>            
            {policyTable}
        </div>
    );
}
export default DeletePolicy;